<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            icon="el-icon-plus"
            size="small"
            @click.stop="toAdd()"
        >新增
        </el-button>
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.erp_devicetype_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { tree } from '@/api/erp/devicetype'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'erp/devicetype',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        tip: false,
        border: true,
        index: true,
        selection: true,
        column: [
          {
            label: '分类名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入分类名称'
            }]
          },
          {
            label: '标志图片',
            prop: 'icon',
            type: 'img'
          },
          {
            label: '说明',
            prop: 'description',
            rules: [{
              required: false,
              message: '请输入说明'
            }]
          },
          {
            label: '排序',
            prop: 'sort',
            rules: [{
              required: false,
              message: '请输入排序'
            }]
          },
          {
            label: '是否为外接设备',
            prop: 'isAuxiliary',
            dicData: [
              {
                label: '否',
                value: 0
              }, {
                label: '是',
                value: 1
              }
            ]
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: false,
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.erp_devicetype_add,
        viewBtn: this.permission.erp_devicetype_view,
        delBtn: this.permission.erp_devicetype_delete,
        editBtn: this.permission.erp_devicetype_edit
      }
    }
  },
  methods: {
    onLoad (page, params = {}) {
      tree(page.currentPage, page.pageSize, params).then(res => {
        if (res.success) {
          this.data = res.data.records
          this.page.total = res.data.total
        } else {
          this.data = []
          this.page.total = 0
        }
      })
    },
    toAdd () {
      this.$router.push({
        path: '/erp/deviceTypeEdit'
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/erp/deviceTypeEdit',
        query: { id: row.id }
      })
    }
  }
}
</script>
