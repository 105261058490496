<template>
  <div>
    <el-card class="good-card">
      <div>
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            :inline-message="true"
            label-position="right"
            label-width="150px"
            class="small-space form-one">
          <el-form-item label="银行账号：" prop="ownerId">
            <good-select v-model="form.ownerId" url="/crm/bank/getDict?ownerType=2"></good-select>
          </el-form-item>
          <el-form-item label="所属者类型：" prop="ownerType">
            <good-select v-model="form.ownerType" url="/sys/dict/code/owner_type"></good-select>
          </el-form-item>
          <el-form-item label="静态码类型：" prop="type">
            <good-select v-model="form.type" url="/crm/annexBank/getDict"></good-select>
          </el-form-item>
          <el-form-item label="静态码：" prop="url">
            <good-cropper
                v-model="form.url"
                :showFileList="false"
                :bucket="'zhsn-scale'"
                :initData="form.url"
                :uploadClass="'upload-pic'"></good-cropper>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="save(true)" :loading="loading.addAg">保存并提交</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select/index'
import { getDetail, submit } from '@/api/crm/annexMerchantBank'
import GoodCropper from '@/components/good-cropper/index'

export default {
  mixins: [mixin],
  components: { GoodSelect, GoodCropper },
  data () {
    return {
      loading: {
        form: false
      },
      form: {
        ownerId: undefined,
        ownerType: 5,
        type: undefined,
        url: undefined
      },
      rules: {
        ownerId: [{ required: false, trigger: 'blur', message: '请选择银行账号' }],
        type: [{ required: true, trigger: 'blur', message: '请选择静态码类型' }],
        url: [{ required: true, trigger: 'blur', message: '请上传静态码' }]
      }
    }
  },
  created () {
    this.form.ownerId = this.$route.query.ownerId
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_annexMerchantBank_add,
        viewBtn: this.permission.crm_annexMerchantBank_view,
        delBtn: this.permission.crm_annexMerchantBank_delete,
        editBtn: this.permission.crm_annexMerchantBank_edit
      }
    }
  },
  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          submit(this.form).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            } else {
              this.$message({
                type: 'false',
                message: res.msg
              })
            }
            this.loading.add = false
            if (res.code === 200) {
              this.form = {
                status: 1
              }
              this.$router.push({ path: '/crm/annexMerchantBank' })
            }
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/crm/annexMerchantBank' })
    }
  }
}
</script>
