<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/bankAccountAssign',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        align: 'center',
        searchMenuSpan: 5,
        column: [
          {
            label: '分账规则编号',
            prop: 'ruleId',
            width: 200
          },
          {
            label: '分账规则',
            prop: 'ruleName',
            search: true,
            width: 200
          },
          {
            label: '分账类型',
            prop: 'type',
            search: true,
            width: 80,
            type: 'select',
            dicUrl: '/sys/dict/code/account_assign_type'
          },
          {
            label: '分账甲方数值',
            prop: 'value'
          },
          {
            label: '分账乙方数值',
            prop: 'receiveValue'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_bankAccountAssign_add,
        viewBtn: this.permission.crm_bankAccountAssign_view,
        delBtn: this.permission.crm_bankAccountAssign_delete,
        editBtn: this.permission.crm_bankAccountAssign_edit
      }
    }
  },
  methods: {
  }
}
</script>
