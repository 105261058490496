<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.visual_visualcategory_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'visual/visualcategory',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '分类键值',
            prop: 'categoryKey',
            rules: [{
              required: true,
              message: '请输入分类键值'
            }]
          },
          {
            label: '分类名称',
            prop: 'categoryValue',
            rules: [{
              required: true,
              message: '请输入分类名称'
            }]
          },
          {
            label: '是否已删除',
            prop: 'isDeleted',
            rules: [{
              required: true,
              message: '请输入是否已删除'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.visual_visualcategory_add,
        viewBtn: this.permission.visual_visualcategory_view,
        delBtn: this.permission.visual_visualcategory_delete,
        editBtn: this.permission.visual_visualcategory_edit
      }
    }
  },
  methods: {
  }
}
</script>
