<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.crm_bankinfo_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot="payCodeForm" >
        <good-cropper
            v-model="form.payCode"
            :showFileList="false"
            :private="true"
            :bucket="'zhsn-scale-private'"
            :prefix="'payCode'"
            :initData="form.payCode"
            :uploadClass="'upload-pic'">
        </good-cropper>
      </template>
      <template slot="payCode" slot-scope="{row}">
        <el-popover
            v-if="row.payCode"
            placement="right"
            trigger="click">
          <img v-if="row.payCode" :src="row.payCode" alt="" :class="`icon-big`">
          <img
              slot="reference"
              :src="row.payCode"
              :alt="row.payCode"
              :class="`icon`">
        </el-popover>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/bankinfo',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '开户行',
            prop: 'depositor',
            rules: [{
              required: true,
              message: '请输入开户行'
            }]
          },
          {
            label: '账号',
            prop: 'account',
            rules: [{
              required: true,
              message: '请输入账号'
            }]
          },
          {
            label: '支付二维码',
            prop: 'payCode',
            slot: true,
            formslot: true
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: true,
              message: '请输入备注'
            }]
          },
          {
            label: '商户id',
            prop: 'merchantId',
            rules: [{
              required: true,
              message: '请输入商户id'
            }]
          },
          {
            label: '市场id',
            prop: 'marketId',
            rules: [{
              required: true,
              message: '请输入市场id'
            }]
          },
          {
            label: '公司id',
            prop: 'companyId',
            rules: [{
              required: true,
              message: '请输入公司id'
            }]
          },
          {
            label: 'sys_user.level',
            prop: 'level',
            rules: [{
              required: true,
              message: '请输入sys_user.level'
            }]
          },
          {
            label: '0,company_id,market_id,merchant_id',
            prop: 'ancestor',
            rules: [{
              required: true,
              message: '请输入0,company_id,market_id,merchant_id'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_bankinfo_add,
        viewBtn: this.permission.crm_bankinfo_view,
        delBtn: this.permission.crm_bankinfo_delete,
        editBtn: this.permission.crm_bankinfo_edit
      }
    }
  },
  methods: {
  }
}
</script>
