<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            size="small"
            icon="el-icon-add"
            v-if="permission.crm_merchantUser_add"
            @click="toAdd()">新增
        </el-button>
      </template>
      <template slot-scope="{row,index}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
      <template slot="search">
        <el-form label-width="85px" align="left" :inline="true">
          <el-form-item label="所属商户:">
            <good-select v-model="search.merchantId" url="/crm/merchant/dict?marketId="></good-select>
          </el-form-item>
        </el-form>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select/index'

export default {
  mixins: [mixin],
  components: { GoodSelect },
  data () {
    return {
      module: 'crm/merchantUser',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      merchantId: null,
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        selection: true,
        addBtn: false,
        viewBtn: false,
        editBtn: false,
        cancelBtn: false,
        delBtn: false,
        align: 'center',
        column: [
          {
            label: '商户',
            prop: 'merchantId',
            width: 200,
            type: 'select',
            dicUrl: '/crm/merchant/dict'
          },
          {
            label: '用户名',
            prop: 'username',
            width: 150
          },
          {
            label: '姓名',
            prop: 'name'
          },
          {
            label: '联系方式',
            prop: 'phone',
            width: 150
          },
          {
            label: '身份证号',
            prop: 'idCardNo',
            width: 200
          },
          {
            label: '身份证',
            prop: 'idCardAnnex.url',
            bind: 'idCardAnnex.url',
            type: 'img'
          },
          {
            label: '性别',
            prop: 'gender',
            type: 'radio',
            dicUrl: 'sys/dict/code/gender'
          },
          {
            label: '年龄',
            prop: 'age',
            type: 'number'
          },
          {
            label: '健康证号',
            prop: 'healthLicense'
          },
          {
            label: '健康证',
            prop: 'healthLicenseAnnex.url',
            bind: 'healthLicenseAnnex.url',
            type: 'img'
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.merchantId = this.$route.query.merchantId
    this.search.merchantId = this.$route.query.merchantId
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_merchantUser_add,
        viewBtn: this.permission.crm_merchantUser_view,
        delBtn: this.permission.crm_merchantUser_delete,
        editBtn: this.permission.crm_merchantUser_edit
      }
    }
  },
  methods: {
    toAdd () {
      this.$router.push({
        path: '/crm/merchantuseredit'
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/crm/merchantuseredit',
        query: { id: row.id }
      })
    }
  }
}
</script>
