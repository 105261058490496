<template>
  <basic-container>
    <el-form
        :model="form"
        ref="form"
        :inline-message="true"
        label-position="right"
        label-width="100px"
        class="small-space"
        :rules="rules">
      <el-row :gutter="20">
        <enterprise-edit
            ref="EnterpriseEdit"
            v-if="enterpriseVisible"
            pic-title="其他附件"
            pic-label="附件"
            :show-submit="false"
            :enterprise="form.enterprise"
            :need-recognize="!form.id"
            :company-id="form.companyId"
            :market-id="form.marketId"
            @set-enterprise="setEnterprise">
          <el-col :span="8">
            <el-form-item label="经营许可证：" label-width="100px" prop="hygieneLicenseUrl">
              <good-cropper
                  v-model="form.hygieneLicenseUrl"
                  :showFileList="false"
                  :private="true"
                  :bucket="'zhsn-scale-private'"
                  :initData="form.hygieneLicenseUrl"
                  :uploadClass="'upload-pic'"></good-cropper>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="溯源码：" label-width="100px" prop="traceabilityUrl">
              <good-cropper
                  v-model="form.traceability"
                  :showFileList="false"
                  :bucket="'zhsn-scale'"
                  :initData="form.traceability"
                  :uploadClass="'upload-pic'"></good-cropper>
            </el-form-item>
          </el-col>
          <template v-slot:foreignKey>
            <el-col :span="24">
              <el-col :span="8">
                <el-form-item label="所属公司：" prop="companyId">
                  <good-select :disabled="!!companyId" v-model="form.companyId" url="/crm/company/dict"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="所属市场：" label-width="100px" prop="marketId">
                  <good-select :disabled="!!marketId" v-model="form.marketId" url="/crm/market/dict"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="经营范围：" required>
                  <good-select
                      v-if="!typeFormVisible"
                      allow-add
                      :item-add="typeAdd"
                      v-model="form.types"
                      multiple
                      url="/sys/dict/code/major_type"></good-select>
                </el-form-item>
              </el-col>
            </el-col>
          </template>
          <template v-slot:manageArea>
            <el-col :span="8">
              <el-col :span="24">
                <el-form-item label="经营区域：" prop="areaId">
                  <el-cascader v-model="form.areaId" :options="areas" :props="areaProps" :show-all-levels="false"/>
                </el-form-item>
              </el-col>
            </el-col>
          </template>
          <template v-slot:manageAddress>
            <el-col :span="8">
              <el-form-item label="经营地址：" prop="address">
                <el-input v-model="form.address"></el-input>
              </el-form-item>
            </el-col>
          </template>
          <template v-slot:manageStatus>
            <el-col :span="8">
              <el-form-item label="备案状态：" prop="status">
                <good-select v-model="form.status" url="sys/dict/code/apply_status"/>
              </el-form-item>
            </el-col>
          </template>
          <template v-slot:moreProps>
            <el-col :span="24">
              <el-col :span="8">
                <el-form-item label="经营许可证：">
                  <el-input v-model="form.hygieneLicense"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="经营许可起：">
                  <el-date-picker
                      v-model="form.hygieneLicenseValidFrom"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="经营许可止：">
                  <el-date-picker
                      v-model="form.hygieneLicenseValidTo"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="8">
                <el-form-item label="秤数量：" prop="scaleNum">
                  <el-input-number :min="0" v-model="form.scaleNum" :step="1"/>
                </el-form-item>
              </el-col>
            </el-col>
          </template>
        </enterprise-edit>
        <linkman-edit
            ref="LinkmanEdit"
            v-if="legalPersonVisible"
            :show-submit="false"
            v-bind:info="form.enterprise.legalPerson"
            @set-linkman="setLegalPerson"/>
        <el-col :span="24" v-if="form.id">
          <span class="title"><i class="el-icon-s-flag"></i>员工信息</span>
          <el-divider></el-divider>
        </el-col>
        <el-col :span="24" v-if="form.id">
          <template>
            <el-table :data="employeesData" style="width: 100%">
              <el-table-column prop="name" label="姓名" width="180"/>
              <el-table-column prop="mobile" label="手机号码"/>
              <el-table-column prop="idCardNo" label="身份证号"/>
              <el-table-column prop="relation" label="关系"/>
            </el-table>
          </template>
        </el-col>
      </el-row>
      <el-footer align="center" span="24">
        <el-button type="primary" :loading="loading.form" @click="save()">保存</el-button>
        <el-button size="small" @click="handleCancel()">取消</el-button>
      </el-footer>
    </el-form>
    <el-dialog width="70%" :visible.sync="marketVisible">
      <market/>
    </el-dialog>
    <el-dialog :visible.sync="typeFormVisible" width="30%">
      <el-form :model="typeForm">
        <el-form-item label="经营范围:" label-width="80px">
          <el-input v-model="typeForm.dictValue"/>
        </el-form-item>
        <el-form-item/>
        <el-footer align="center">
          <el-button type="default" @click="typeFormVisible=false">取消</el-button>
          <el-button type="primary" @click="typeSubmit()" :loading="typeLoading">提交</el-button>
        </el-footer>
      </el-form>
    </el-dialog>
  </basic-container>
</template>
<script>
import mixin from '@/util/mixin'
import GoodCropper from '@/components/good-cropper'
import GoodSelect from '@/components/good-select'
import EnterpriseEdit from '../base/enterpriseedit'
import LinkmanEdit from '@/views/base/linkmanedit'
import { getMaxKey, add } from '@/api/sys/dict'
import { getTree } from '@/api/sys/area'
import { getByCreditCode } from '@/api/base/enterprise'
import { getDetail, submit } from '@/api/crm/merchant'
import { getDetail as getMarket } from '@/api/crm/market'
import { getOne } from '@/api/sys/role'

export default {
  mixins: [mixin],
  components: { GoodCropper, GoodSelect, EnterpriseEdit, LinkmanEdit },
  data () {
    return {
      loading: {
        form: false
      },
      module: '/crm/merchant',
      companyId: null,
      marketId: null,
      enterpriseVisible: false,
      legalPersonVisible: false,
      boothVisible: false,
      contractVisible: false,
      isLegalPerson: true,
      marketVisible: false,
      roleCode: 'ROLE_MERCHANT_MANAGER',
      role: undefined,
      areas: [],
      areaProps: {
        lazy: true,
        value: 'id',
        label: 'name',
        lazyLoad (node, resolve) {
          const { level } = node
          if (level === 0) {
            setTimeout(() => {
              getTree({ parentId: level }).then(res => {
                if (res.success) {
                  const nodes = res.data
                  // eslint-disable-next-line no-return-assign
                  nodes.forEach(item => item.leaf = level >= 2)
                  resolve(nodes)
                }
              })
            }, 1000)
          } else {
            setTimeout(() => {
              getTree({ parentId: node.data.codeNum }).then(res => {
                if (res.success) {
                  const nodes = res.data
                  // eslint-disable-next-line no-return-assign
                  nodes.forEach(item => item.leaf = level >= 2)
                  resolve(nodes)
                }
              })
            }, 1000)
          }
        }
      },
      form: {
        company: undefined,
        enterprise: {
          legalPerson: {
            healthLicenseAnnex: {}
          }
        },
        scaleNum: 0,
        employees: [],
        annexes: [],
        booths: [],
        contracts: [],
        status: 0
      },
      rules: {
        companyId: [{ required: true, trigger: 'blur', message: '请选择所属公司' }],
        marketId: [{ required: true, trigger: 'blur', message: '请选择市场' }],
        enterpriseId: [{ required: true, trigger: 'blur', message: '请完善基本信息' }]
      },
      typeFormVisible: false,
      typeForm: {
        type: 'major_type',
        dictValue: null
      },
      typeLoading: false,
      annex: {},
      linkmen: {},
      contractForm: {
        ownerType: 'MARKET',
        oppositeType: 'MERCHANT',
        objectType: 'BOOTH',
        annexes: []
      },
      contractsFormVisible: false,
      contractId: undefined,
      contractDeleted: false,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  mounted () {
    // this.$nextTick(() => {
    //   const disabled = this.userInfo.level === 3
    //   this.form.marketId.disabled = disabled
    //   this.$refs.crud.columnInit()
    // })
  },
  async created () {
    this.form.companyId = this.userInfo.companyId
    this.form.marketId = this.userInfo.marketId
    if (!this.form.companyId) {
      this.getMarket()
    }
    this.form.id = this.$route.query.id
    if (this.form.id) {
      await this.loadData()
    } else {
      this.enterpriseVisible = true
      this.legalPersonVisible = true
    }
    this.loadRole({ code: this.roleCode })
  },
  watch: {
    'form.enterprise.creditCode': {
      handler (val) {
        if (!val) {
          return
        }
        this.getByCreditCode(val)
      },
      immediate: false
    },
    'form.enterprise.areaId': {
      handler (val) {
        if (!val) {
          return
        }
        this.form.areaId = val
      }
    },
    'form.enterprise.address': {
      handler (val) {
        if (!val) {
          return
        }
        this.form.address = val
      }
    },
    'form.enterprise.legalPersonName': {
      handler (val) {
        if (!val) {
          return
        }
        if (!this.form.enterprise.legalPerson) {
          this.form.enterprise.legalPerson = {}
        }
        this.form.enterprise.legalPerson.name = val
      },
      deep: true
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_market_add,
        viewBtn: this.permission.crm_market_view,
        delBtn: this.permission.crm_market_delete,
        editBtn: this.permission.crm_market_edit
      }
    },
    employeesData () {
      return this.form.employees || []
    },
    boothsData () {
      return this.form.booths || []
    },
    contractsData () {
      return this.form.contracts || []
    },
    companyDisabled () {
      return !this.permission.crm_company_add
    }
  },
  methods: {
    async loadData () {
      await getDetail(this.form.id).then(res => {
        this.form = res.data
        if (!this.form) {
          this.form = {
            enterprise: {}
          }
        }
        this.form.company = this.form.companyId
        this.form.market = this.form.marketId
        this.enterpriseVisible = true
        this.legalPersonVisible = true
        if (this.form.types) {
          this.form.types = this.form.types.split(',').map(type => Number(type))
        } else {
          this.form.types = []
        }
        if (this.form.stallNo) {
          this.form.stallNo = this.form.stallNo.split(',')
        }
        const contracts = this.form.contracts
        if (contracts && contracts.length) {
          this.contractForm = contracts[0]
        }
        this.reset()
      })
    },
    getByCreditCode (creditCode) {
      if (!creditCode || this.$route.query.id) {
        return
      }
      getByCreditCode(this.module, creditCode).then(res => {
        if (res.success && res.data) {
          this.form = res.data
          this.$message({
            type: 'warning',
            message: '对应的营业执照已创建市场，无需再次创建！'
          })
        }
      })
    },
    getMarket () {
      if (!this.marketId) {
        return
      }
      getMarket(this.marketId).then(res => {
        if (res.success && res.data) {
          this.companyId = res.data.companyId
          this.form.companyId = res.data.companyId
        }
      })
    },
    setEnterprise (enterprise) {
      this.form.enterprise = enterprise
      this.form.enterpriseId = enterprise.id
      this.form.name = enterprise.name
      if (!this.form.areaId) {
        this.form.areaId = enterprise.areaId
      }
      if (!this.form.address) {
        this.form.address = enterprise.address
      }
    },
    setLegalPerson (legalPerson) {
      this.form.enterprise.legalPerson = legalPerson
    },
    save () {
      const that = this
      let enterprise
      let legalPerson
      this.$refs.EnterpriseEdit.handleSubmit().then(() => {
        // 取区域的最后一个元素
        const areaId = this.form.areaId
        if (areaId instanceof Array) {
          this.form.areaId = areaId.pop()
        }
        enterprise = this.form.enterprise
        legalPerson = enterprise.legalPerson
        // 删除企业信息
        delete this.form.enterprise
        return that.submit()
      }).then(() => {
        // 恢复企业信息
        this.form.enterprise = enterprise
        this.form.enterprise.legalPerson = legalPerson
        const name = that.form.enterprise.legalPerson.name
        if (!name && name !== that.form.enterprise.legalPersonName) {
          this.$message({
            type: 'error',
            message: '法人名称必需相同!'
          })
          return false
        }
        return that.$refs.LinkmanEdit.handleSubmit()
      }).then(() => {
        return that.submit()
      }).then(() => {
        that.$router.push('/crm/merchant')
      }).catch(() => {
        that.locateToErr()
      })
    },
    loadRole () {
      getOne({ code: this.roleCode }).then(res => {
        if (res.success) {
          this.role = res.data
        }
      })
    },
    submit () {
      if (this.form.enterprise && this.form.enterprise.legalPerson) {
        const name = this.form.enterprise.legalPerson.name
        if (!name && name !== this.form.enterprise.legalPersonName) {
          this.$message({
            type: 'error',
            message: '法人名称必需相同!'
          })
        }
      }
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loading.add = true
            // 修改主营范围
            const types = this.form.types
            if (types) {
              this.form.types = types.toString()
            }
            submit(this.form).then((res) => {
              this.form.type = types
              if (res.success) {
                this.form.id = res.data
                this.$route.query.id = res.data
                resolve(true)
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
                reject(new Error(res.msg))
              }
              this.loading.add = false
            }).catch(() => {
              reject(new Error())
            })
          } else {
            reject(new Error())
          }
        })
      })
    },
    handleCancel () {
      this.$router.push({ path: '/crm/merchant' })
    },
    marketAdd () {
      this.marketVisible = true
    },
    typeAdd () {
      this.typeFormVisible = true
    },
    typeSubmit () {
      getMaxKey(this.typeForm.type).then(res => {
        if (res.success) {
          if (res.data) {
            this.typeForm.dictKey = res.data + 1
          } else {
            this.typeForm.dictKey = 1
          }
          this.typeForm.sort = this.typeForm.dictKey
          add(this.typeForm).then(res => {
            if (res.success) {
              this.typeFormVisible = false
              this.form.types.push(this.typeForm.dictKey)
            }
          })
        }
      })
    },
    // 滚动条定位到第一个校验失败的div的位置
    locateToErr () {
      setTimeout(() => {
        const errorDiv = document.getElementsByClassName('is-error')
        if (errorDiv.length) {
          errorDiv[0].scrollIntoView()
        }
      }, 0)
    },
    reset () {
      if (!this.form) {
        this.form = {
          enterprise: {}
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  i {
    margin-right: 15px;
  }

  font-size: 24px;
}

::v-deep .edit-box {
  padding: 0 !important;
}
</style>
