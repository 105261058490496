<template>
  <el-form
      :model="form"
      ref="form"
      :rules="rules"
      :inline-message="true"
      label-position="right"
      label-width="100px"
      class="small-space">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="合同名称" prop="contractName">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="合同编号" prop="contractCode">
            <el-input v-model="form.sn"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="合同类型：" prop="contractType">
            <good-select :disabled="disabled" v-model="form.type" url="/sys/dict/code/contract_type"></good-select>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="甲方类型：" prop="ownerId">
            <good-select :disabled="disabled" v-model="form.ownerType" url="/sys/dict/code/owner_type"></good-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="甲方：" prop="ownerId">
            <good-select :disabled="form.ownerType === 4" v-model="form.ownerId" :url="'/crm/' + ownerType.toLowerCase() + '/dict'"></good-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="合同金额：" prop="rent">
            <el-input-number v-model="form.rent" :precision="2" :step="100" :min="0"></el-input-number>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="乙方类型：" prop="oppositeType">
            <good-select :disabled="disabled" v-model="form.oppositeType" url="/sys/dict/code/owner_type"></good-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="乙方：" prop="oppositeId">
            <good-select v-model="form.oppositeId" :url="'/crm/' + oppositeType.toLowerCase() + '/dict'"></good-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="支付方式：" prop="payType">
            <good-select v-model="form.payType" url="/sys/dict/code/pay_type"></good-select>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="标的类型：" prop="ownerId">
            <good-select :disabled="disabled" v-model="form.objectType" url="/sys/dict/code/object_type"></good-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="合同标的：" prop="ownerId">
            <good-select :disabled="disabled && !!form.objectId" v-model="form.objectId" :url="'/crm/company/object/dict/' + objectType"></good-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <slot name="foreignName"/>
        </el-col>
      </el-col>
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="支付周期：" prop="payPeriod">
            <good-select v-model="form.payPeriod" url="/sys/dict/code/pay_period_type"></good-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="合同起始：" prop="validFrom">
            <el-date-picker
                v-model="form.validFrom"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="合同结束：" prop="validTo">
            <el-date-picker
                v-model="form.validTo"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="24">
        <el-form-item label="合同附件：" prop="contractUrl">
          <el-switch
              v-model="annexType"
              active-text="图片"
              inactive-text="PDF"
              active-value="image"
              inactive-value="pdf">
          </el-switch>
          <good-cropper
              v-model="form.contractUrl"
              :showFileList="false"
              :private="true"
              :bucket="'zhsn-scale-private'"
              :prefix="'contract'"
              :initData="form.contractUrl"
              :uploadClass="'upload-pic'"></good-cropper>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="备注信息：" prop="remark">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-footer align="center" v-if="showBtn">
        <el-button type="primary" @click="save" :loading="loading.form">保存合同</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-footer>
    </el-row>
  </el-form>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select'
import GoodCropper from '@/components/good-cropper'
import { getDetail, submit } from '@/api/base/contract'

export default {
  name: 'ContractEdit',
  mixins: [mixin],
  components: { GoodSelect, GoodCropper },
  props: {
    showBtn: {
      default: true,
      type: Boolean
    },
    contract: {
      default: null,
      type: Object
    },
    disabled: {
      default: false,
      type: Boolean
    },
    back: {
      default: true,
      type: Boolean
    },
    ownerType: {
      default: 'MARKET',
      type: String
    },
    oppositeType: {
      default: 'MERCHANT',
      type: String
    },
    objectType: {
      default: 'BOOTH',
      type: String
    }
  },
  data () {
    return {
      loading: {
        form: false
      },
      annexType: 'image',
      form: {
        annexes: []
      },
      companyId: null,
      marketId: null,
      rules: {
        name: [{ required: true, trigger: 'blur', message: '请输入合同名称' }],
        sn: [{ required: true, trigger: 'blur', message: '请输入合同编号' }],
        type: [{ required: true, trigger: 'blur', message: '请设置合同类型' }],
        ownerId: [{ required: true, trigger: 'blur', message: '请选择甲方' }],
        ownerType: [{ required: true, trigger: 'blur', message: '请选择甲方类型' }],
        objectId: [{ required: true, trigger: 'blur', message: '请选择乙方' }],
        objectType: [{ required: true, trigger: 'blur', message: '请选择乙方类型' }]
      },
      objectTypes: {
        BOOTH: 101,
        CHEAP_VEGETABLE: 501
      }
    }
  },
  created () {
    this.form.companyId = this.userInfo.companyId
    this.form.marketId = this.userInfo.marketId
    this.getMarket()
    this.form.id = this.$route.query.id
    if (this.contract) {
      this.form = this.contract
    } else if (this.form.id) {
      this.loadData()
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_contract_add,
        viewBtn: this.permission.crm_contract_view,
        delBtn: this.permission.crm_contract_delete,
        editBtn: this.permission.crm_contract_edit
      }
    }
  },
  methods: {
    getMarket () {
      if (!this.form.marketId) {
        return
      }
      if (this.form.companyId) {
        return
      }
      getDetail(this.form.marketId).then(res => {
        if (res.success && res.data) {
          this.form.companyId = res.data.companyId
        }
      })
    },
    loadData () {
      getDetail(this.form.id).then(res => {
        if (res.success) {
          this.form = res.data
        }
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading.add = true
          submit(this.form).then((res) => {
            if (res.success && res.data) {
              this.form.id = res.data
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            }
            this.loading.add = false
            if (this.back) {
              this.$router.push({ path: '/base/contract' })
            } else {
              this.$emit('submit', this.form)
            }
          }).catch(() => {
          })
        }
      })
    },
    getType (value) {
      for (const item in this.ownerTypes) {
        console.log(item)
        console.log(this.ownerTypes[item])
        if (this.ownerTypes[item] === value) {
          return item
        }
      }
    },
    cancel () {
      if (this.back) {
        this.$router.push({ path: '/base/contract' })
      } else {
        this.$emit('cancel')
      }
    }
  }
}
</script>
