<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'erp/attachment',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        searchMenuSpan: 5,
        align: 'center',
        column: [
          {
            label: '批次',
            prop: 'stockId',
            search: true,
            type: 'select',
            select: true,
            dicUrl: '/erp/stockin/getDict'
          },
          {
            label: '市场',
            prop: 'marketId',
            width: 200,
            search: true,
            type: 'select',
            select: true,
            dicUrl: '/crm/market/dict?type=1'
          },
          {
            label: '设备编号',
            prop: 'code',
            search: true
          },
          {
            label: '设备类型',
            prop: 'type',
            search: true,
            type: 'select',
            select: true,
            dicUrl: '/sys/dict/code/device_type'
          },
          {
            label: '在库状态',
            prop: 'stockStatus',
            search: true,
            type: 'select',
            select: true,
            dicUrl: '/sys/dict/code/stock_status'
          },
          {
            label: '绑定状态',
            prop: 'bindStatus',
            search: true,
            type: 'select',
            select: true,
            dicUrl: '/sys/dict/code/yes_no'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.erp_attachment_add,
        viewBtn: this.permission.erp_attachment_view,
        delBtn: this.permission.erp_attachment_delete,
        editBtn: this.permission.erp_attachment_edit
      }
    }
  },
  methods: {
    toAdd () {
      this.$router.push({ path: '/erp/attachmentEdit' })
    },
    toEdit (row) {
      this.$router.push({
        path: '/erp/attachmentEdit',
        query: { id: row.id }
      })
    }
  }
}
</script>
