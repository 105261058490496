<template>
  <div>
    <el-form
      :model="form"
      ref="linkman"
      :inline-message="true"
      label-position="right"
      label-width="160px"
      class="small-space"
      :rules="rules">
    <el-row :gutter="20">
      <el-col :span="24">
        <span class="title"><i class="el-icon-s-flag"></i>{{ linkType }}信息
          <slot name="linkProp"/>
<!--          <el-checkbox @change="setNeedCheck" v-model="isLegalPerson" label="法人" style="margin-left: 10px"/>-->
        </span>
        <el-divider></el-divider>
      </el-col>
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="身份证正面：" required prop="idCardFace" ref="idCardFace">
            <good-cropper
                v-model="form.idCardFace"
                :showFileList="false"
                :private="true"
                :bucket="'zhsn-scale-private'"
                :prefix="'linkman'"
                :initData="form.idCardFace"
                :uploadClass="'upload-pic'"
                @handleSuccess="handleRecognizeFace"></good-cropper>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="身份证反面：" required prop="idCardBack" ref="idCardBack">
            <good-cropper
                v-model="form.idCardBack"
                :showFileList="false"
                :private="true"
                :bucket="'zhsn-scale-private'"
                :prefix="'linkman'"
                :initData="form.idCardBack"
                :uploadClass="'upload-pic'"
                @handleSuccess="handleRecognizeBack"></good-cropper>
          </el-form-item>
        </el-col>
        <slot name="otherLicense"/>
      </el-col>
      <slot name="foreignKey"/>
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="姓名：" prop="name" ref="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="年龄：">
            <el-input-number :min="1" v-model="form.age" :step="1"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性别：">
            <el-radio-group v-model="form.gender">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="身份证号码：" prop="idCardNo" ref="idCardNo">
            <el-input v-model="form.idCardNo" maxlength="18"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="身份证有效起：" prop="validFrom">
            <el-date-picker
                @input="changeDate()"
                v-model="form.validFrom"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="身份证有效止：" prop="validTo">
            <span v-if="form.validTo === '2999-12-31'">长期</span>
            <el-date-picker
                v-else
                @input="changeDate()"
                v-model="form.validTo"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="手机号：" prop="mobile" ref="mobile">
            <el-input v-model="form.mobile" maxlength="11"></el-input>
          </el-form-item>
        </el-col>
        <slot name="col"/>
      </el-col>
      <slot name="moreProps"/>
      <el-col :span="24">
        <span class="title"><i class="el-icon-s-flag"></i>{{ linkType }}风采</span>
        <el-divider></el-divider>
      </el-col>
      <el-col :span="8">
        <el-form-item :label="linkType + '风采：'" prop="annexUrl">
          <good-cropper
              v-model="annexUrl"
              :showFileList="false"
              :bucket="'zhsn-scale'"
              :initData="annexUrl"
              :uploadClass="'upload-pic'"></good-cropper>
        </el-form-item>
      </el-col>
      <el-col :span="4" v-for="annex in form.annexes" :key="annex.id">
        <good-cropper
            v-model="annex.url"
            :bucket="'zhsn-scale'"
            :initData="annex.url"
            :uploadClass="'upload-pic'"></good-cropper>
      </el-col>
      <el-col :span="24">
        <el-footer align="center">
          <el-button v-if="showSubmit" size="small" type="primary" @click="handleSubmit()">保存{{ linkType }}信息</el-button>
          <slot @click="handleSubmit()" name="submitBtn"/>
        </el-footer>
      </el-col>
    </el-row>
  </el-form>
  </div>
</template>

<script>
import GoodCropper from '@/components/good-cropper'
import { submit, getDetail, recognize } from '@/api/base/linkman'
import { isValidateMobile, cardNo } from '@/util/validate.js'

export default {
  name: 'LinkmanEdit',
  components: { GoodCropper },
  props: {
    linkType: {
      default: '法人',
      type: String
    },
    info: {
      default: null,
      type: Object
    },
    showSubmit: {
      default: true,
      type: Boolean
    },
    needCheck: {
      default: true,
      type: Boolean
    }
  },
  data () {
    const validateMobile = (rule, value, callback) => {
      const data = isValidateMobile(value)
      if (data[0]) {
        return callback(new Error(data[1]))
      } else {
        return callback()
      }
    }
    const validateCard = (rule, value, callback) => {
      const data = cardNo(value)
      if (data[0]) {
        return callback(new Error(data[1]))
      } else {
        return callback()
      }
    }
    return {
      total: null,
      list: null,
      listLoading: true,
      showDialog: false,
      form: {
        id: undefined,
        name: undefined,
        gender: undefined,
        age: undefined,
        mobile: undefined,
        idCardNo: undefined,
        idCardFace: undefined,
        idCardBack: undefined
      },
      annexUrl: undefined,
      rules: {
        name: [{ required: true, trigger: 'blur', message: '请输入姓名' }],
        mobile: [{ required: true, trigger: 'blur', message: '请输入联系方式', validator: validateMobile }],
        idCardNo: [{ required: true, trigger: 'blur', message: '请输入法人身份证号', validator: validateCard }]
      }
    }
  },
  watch: {
    form: {
      handler (newVal, oldVal) {
        if (newVal) {
          this.$emit('set-linkman', newVal)
        }
      },
      deep: true
    },
    info: {
      handler (newVal, oldVal) {
        this.form = newVal
        this.reset()
      },
      deep: true,
      immediate: false
    }
  },
  created () {
    this.onLoad()
  },
  methods: {
    onLoad () {
      if (this.info) {
        this.form = this.info
        this.reset()
      } else if (this.$route.query.id) {
        this.listLoading = true
        const id = this.$route.query.id
        getDetail(id).then(res => {
          this.form = res.data
          this.reset()
          this.showDialog = true
        })
      }
    },
    handleRecognizeFace (imageUrl, bucket) {
      this.handleRecognize(imageUrl, 'face', bucket)
    },
    handleRecognizeBack (imageUrl, bucket) {
      this.handleRecognize(imageUrl, 'back', bucket)
    },
    handleRecognize (imageUrl, side, bucket) {
      if (side === 'face' && this.form.id) {
        return
      }
      recognize(imageUrl, side, bucket).then((res) => {
        if (res.success) {
          if (res.data) {
            if (side === 'face') {
              const idCardBack = this.form.idCardBack
              this.form = res.data
              if (this.needCheck) {
                this.checkName()
              }
              this.form.idCardBack = idCardBack
            } else {
              this.form.idCardBack = res.data.idCardBack
              this.form.validFrom = res.data.validFrom
              this.form.validTo = res.data.validTo
              this.$forceUpdate()
            }
            this.reset()
            this.$emit('reset')
          } else {
            this.$message({
              type: 'error',
              message: '识别结果为空',
              duration: 2000
            })
          }
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
            duration: 2000
          })
        }
      }).catch((e) => {
        this.$message({
          type: 'error',
          message: e.msg
        })
      })
    },
    handleSubmit () {
      return new Promise((resolve, reject) => {
        // 保存
        this.$refs.linkman.validate(valid => {
          if (valid) {
            if (!(this.needCheck && this.checkName())) {
              reject(new Error())
            }
            if (this.form.idCardFace === '') {
              this.$notify({
                title: '失败',
                message: '请上传身份证正面',
                type: 'error',
                duration: 2000
              })
            } else if (this.form.idCardBack === '') {
              this.$notify({
                title: '失败',
                message: '请上传身份证反面',
                type: 'error',
                duration: 2000
              })
            } else {
              submit(this.form).then(res => {
                if (res.success) {
                  this.showDialog = false
                  this.form.id = res.data
                  this.$emit('set-enterprise', this.form)
                  resolve(true)
                } else {
                  this.$notify({
                    title: '操作失败',
                    message: res.msg,
                    type: 'error',
                    duration: 2000
                  })
                }
              })
            }
          } else {
            reject(new Error())
          }
        })
      })
    },
    reset () {
      if (!this.form) {
        this.form = {}
      }
      if (!this.form.validFrom) {
        this.form.validFrom = ''
      }
      if (!this.form.validTo) {
        this.form.validTo = ''
      }
    },
    checkName () {
      if (this.info && this.info.name) {
        const name = this.info.name
        if (this.form.name !== name) {
          this.$message({
            type: 'error',
            message: '法人姓名不符，请确认！！',
            duration: 2000
          })
          return false
        }
      }
      return true
    },
    changeDate () {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  i {
    margin-right: 15px;
  }

  font-size: 24px;
}
</style>
