<template>
  <div>
    <el-card class="good-card">
      <el-form
          :model="form"
          ref="form"
          :inline-message="true"
          label-position="right"
          label-width="150px"
          class="small-space form-one">
        <el-form-item label="父批次号：" prop="type">
          <el-input v-model="form.batchNo" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="选择设备：" prop="purchasePrice">
          <avue-crud
              :option="option"
              :data="data"
              :page="page"
              :search.sync="search"
              ref="crud"
              @current-change="currentChangeDevices"
              @search-change="searchDevice"
              @selection-change="selectDevice"
              @on-load="loadDevices">
            <template slot="menuLeft">
              <div class="start">
                <el-tooltip content="请输入sn，多个sn以~间隔。例如abc~123" placement="top">
                  <el-input
                      placeholder="请输入sn，多个sn以~间隔。例如abc~123"
                      type="textarea"
                      v-model="snText"
                      class="sn-textarea"
                      :rows="4"></el-input>
                </el-tooltip>
                <el-button type="primary" @click.stop="handleCheckSns()">确认SN</el-button>
                <el-button type="warning" icon="el-icon-download" @click.stop="handleExport()">模板下载</el-button>
                <el-upload
                    class="filter-item"
                    :show-file-list="false"
                    :action="handleImport()"
                    :headers="uploadHeaders()"
                    :on-success="uploadSuc">
                  <el-button type="success" icon="el-icon-upload2">导入SN</el-button>
                </el-upload>
              </div>
<!--              <el-upload-->
<!--                  class="filter-item"-->
<!--                  :show-file-list="false"-->
<!--                  :action="handleImport()"-->
<!--                  :headers="uploadHeaders()"-->
<!--                  :on-success="uploadSuc">-->
<!--                <el-button type="warning" icon="upload">导入</el-button>-->
<!--              </el-upload>-->
<!--              <el-button-->
<!--                  type="danger"-->
<!--                  size="small"-->
<!--                  icon="el-icon-delete"-->
<!--                  plain-->
<!--                  @click="toggleSelection2('f92jDFnmOTgmh0ZVzOjAycoWWHl6hHzF,EaE5ZwZ2xvzRtVIbAB7ygJRA5lpVHb6A')">ceshi-->
<!--              </el-button>-->
            </template>
          </avue-crud>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save(true)" :loading="loading.addAg">保存并提交</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import mixin from '@/util/mixin'
import { getDetail, division } from '@/api/erp/devicepurchase'
import { getList } from '@/api/erp/device'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'erp/devicepurchase',
      loading: {
        form: false
      },
      form: {
        type: undefined,
        brand: undefined,
        model: undefined,
        marketId: undefined
      },
      rules: {
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      search: {},
      divisionIds: [],
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
        pageSizes: [20, 30, 50, 100],
        deviceList: []
      },
      option: {
        menu: false,
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        searchMenuSpan: 5,
        align: 'center',
        column: [
          {
            label: '设备类型',
            prop: 'type',
            type: 'select',
            select: true,
            dicUrl: '/sys/dict/code/device_type'
          },
          {
            label: '品牌',
            prop: 'brand',
            type: 'select',
            select: true,
            dicUrl: '/sys/dict/code/scale_type'
          },
          {
            label: '型号',
            prop: 'brand',
            type: 'select',
            select: true,
            dicUrl: '/sys/dict/code/scale_type'
          },
          {
            label: '设备sn',
            prop: 'sn'
          }
        ]
      },
      data: [],
      snText: ''
    }
  },
  created () {
    this.form.id = this.$route.query.id
    // this.search.purchaseId = this.form.id
    // console.log(this.search)
    if (this.form.id) {
      this.loadData(this.page)
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    uploadSuc (res) {
      if (res.success) {
        const snsStr = res.data
        this.toggleSelection(snsStr)
      } else {
        this.$message({ message: res.msg, type: 'error' })
      }
    },
    handleCheckSns () {
      this.toggleSelection(this.snText)
    },
    toggleSelection (val) {
      if (val) {
        const sn = val.split('~')
        const selected = []
        sn.forEach(s => {
          const index = this.data.findIndex(item => item.sn === s)
          selected.push(this.data[index])
        })
        this.$refs.crud.toggleSelection(selected)
      }
    },
    selectDevice (list) {
      this.divisionIds = list
      console.log('selectDevice', this.divisionIds)
    },
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    currentChangeDevices (val) {
      this.page.currentPage = val
      // this.loadDevices(this.page)
    },
    loadDevices (page) {
      if (this.form.id) {
        const deviceSearch = {}
        deviceSearch.purchaseId = this.form.id
        getList(page.currentPage, page.pageSize, deviceSearch).then(res => {
          this.page.total = res.data.total
          this.data = res.data.records
        })
      }
    },
    searchDevice (form, done) {
      this.loadDevices()
      done()
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.parentId = this.form.id
          this.form.id = null
          this.form.level = this.form.level + 1
          this.form.deviceList = this.divisionIds
          if (JSON.parse(localStorage.userInfo).marketId) {
            this.form.marketId = JSON.parse(localStorage.userInfo).marketId
            // this.market = this.form.market
          } else {
            // TODO 人员没看市场处理
            this.form.marketId = 1
          }
          if (JSON.parse(localStorage.userInfo).companyId) {
            this.form.companyId = JSON.parse(localStorage.userInfo).companyId
            // this.market = this.form.market
          } else {
            // TODO 人员没看公司处理
            this.form.companyId = 1
          }
          // this.form.divisionIds = []
          // this.divisionIds.forEach((item) => {
          //   this.form.divisionIds.push(item.id)
          // })
          division(this.form).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            } else {
              this.$message({
                type: 'false',
                message: res.msg
              })
            }
            this.loading.add = false
            if (res.code === 200) {
              this.form = {
                status: 1
              }
              this.$router.push({ path: '/erp/device/purchase' })
            }
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/erp/device/purchase' })
    }
  }
}
</script>

<style>
.form-one {
  width: 100%;
}

.sn-textarea {
  width: 600px;
  margin-right: 20px;
}
</style>
