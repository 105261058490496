<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <div class="start">
          <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              v-if="permission.crm_merchant_add"
              @click.stop="toAdd()"
          >新增
          </el-button>
          <el-button
              type="danger"
              size="small"
              icon="el-icon-delete"
              plain
              v-if="permission.crm_merchant_delete"
              @click="handleDelete">删 除
          </el-button>
          <el-button v-if="permission.crm_company_delete" type="success" @click.stop="handleAnnexTypes()">附件一键导出</el-button>
          <el-button type="primary" @click.stop="handleModel()">模板下载</el-button>
          <el-button type="success" @click.stop="handleExport()">导出</el-button>
          <el-upload
              v-if="permission.crm_merchant_add || permission.crm_merchant_edit"
              action="#"
              :auto-upload="false"
              :show-file-list="false"
              :on-change='handleChange'>
            <el-button size="small" type="primary">导入</el-button>
          </el-upload>
        </div>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-view"
            size="small"
            @click.stop="toContract(row)">合同
        </el-button>
        <el-button
            type="text"
            icon="el-icon-view"
            size="small"
            @click.stop="toScaleAdd(row)">加秤
        </el-button>
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
      <template slot="search">
        <el-form align="left" :inline="true">
          <el-form-item label="关键字:">
            <el-input placeholder="名称、姓名、手机等" v-model="search.query"></el-input>
          </el-form-item>
          <el-form-item v-if="permissionList['crm_market_view']" label="所属市场:">
            <good-select v-model="search.marketId" url="/crm/market/dict"></good-select>
          </el-form-item>
          <el-form-item label="经营范围:">
            <good-select v-model="search.types" url="/sys/dict/code/major_type"></good-select>
          </el-form-item>
        </el-form>
      </template>
    </avue-crud>
    <el-dialog :visible.sync="annexTypesVisible" width="30%">
      <el-form align="center" :inline="true">
        <el-form-item label="附件类型">
          <good-select v-model="annexTypes" url="/sys/dict/code/merchant_annex_type"></good-select>
        </el-form-item>
      </el-form>
      <el-footer align="center">
        <el-button type="primary" @click="handleAnnexExport()">下载</el-button>
      </el-footer>
    </el-dialog>
    <el-dialog :visible.sync="scaleVisible" width="20%">
      <el-form ref="scaleForm" :model="scaleForm" :rules="scaleRules">
        <el-form-item label="数量:" label-width="80px">
          <el-input-number :min="1" v-model="scaleForm.scaleNum" :step="1"/>
        </el-form-item>
        <el-form-item/>
        <el-footer align="center">
          <el-button type="default" @click="scaleVisible=false">取消</el-button>
          <el-button type="primary" @click="addScale" :loading="loading.form">提交</el-button>
        </el-footer>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="contractsVisible" width="80%">
      <contract :contracts="contracts" />
    </el-dialog>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import FileSaver from 'file-saver'
import { formatDate } from '@/util/date'
import { exportExcel, importExcel, modelExcel, annexExport, getList } from '@/api/common'
import GoodSelect from '@/components/good-select/index'
import { bindColumns } from '@/api/base/enterprise'
import { getList as listContracts } from '@/api/base/contract'
import { changeScaleNum } from '@/api/crm/merchant'
import Contract from '@/views/base/contract'

export default {
  mixins: [mixin],
  components: { Contract, GoodSelect },
  data () {
    return {
      module: 'crm/merchant',
      form: {},
      search: {},
      annexTypes: [],
      annexTypesVisible: false,
      companyId: null,
      marketId: null,
      scaleVisible: false,
      scaleForm: {
        scalaNum: 1
      },
      scaleRules: {
        id: [{ required: true, trigger: 'blur', message: '请选择商户' }],
        scaleNum: [{ required: true, trigger: 'blur', message: '请设置需要添加的秤数量' }]
      },
      loading: {
        form: false
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      contractsVisible: false,
      contracts: {},
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        align: 'center',
        column: bindColumns([
          {
            label: '所属市场',
            prop: 'marketId',
            width: 200,
            type: 'select',
            dicUrl: '/crm/market/dict'
          },
          {
            label: '简称',
            prop: 'name'
          },
          {
            label: '经营范围',
            prop: 'types',
            type: 'select',
            multiple: true,
            dataType: 'string',
            dicUrl: '/sys/dict/code/major_type',
            width: 100
          },
          {
            label: '秤数量',
            prop: 'scaleNum',
            type: 'number',
            width: 60
          }
        ], [
          {
            label: '食品经营许可证',
            prop: 'hygieneLicenseAnnex.Url',
            bind: 'hygieneLicenseAnnex.Url',
            type: 'img',
            width: 150
          },
          {
            label: '溯源码',
            prop: 'traceabilityAnnex.Url',
            bind: 'traceabilityAnnex.Url',
            type: 'img',
            width: 150
          }
        ])
      },
      data: []
    }
  },
  created () {
    if (JSON.parse(localStorage.userInfo).companyId) {
      this.companyId = JSON.parse(localStorage.userInfo).companyId
    }
    if (JSON.parse(localStorage.userInfo).marketId) {
      this.marketId = JSON.parse(localStorage.userInfo).marketId
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_merchant_add,
        viewBtn: this.permission.crm_merchant_view,
        delBtn: this.permission.crm_merchant_delete,
        editBtn: this.permission.crm_merchant_edit
      }
    }
  },
  methods: {
    onLoad (page) {
      this.search.companyId = this.companyId
      this.search.marketId = this.marketId
      getList(this.module, page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    toAdd () {
      this.$router.push({
        path: '/crm/merchantEdit'
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/crm/merchantEdit',
        query: { id: row.id }
      })
    },
    toContract (row) {
      listContracts(1, 10, { merchantId: row.id }).then(res => {
        if (res.success && res.data) {
          this.contracts = res.data.records
          this.contractsVisible = true
        }
      })
    },
    toScaleAdd (row) {
      this.scaleVisible = true
      this.scaleForm.id = row.id
      this.scaleForm.merchantName = row.enterprise.fullName
    },
    addScale () {
      this.$refs.scaleForm.validate((valid) => {
        if (valid) {
          this.loading.add = true
          console.log(this.scaleForm)
          changeScaleNum(this.scaleForm).then((res) => {
            if (res.success) {
              this.form.id = res.data
              this.$message({
                type: 'success',
                message: '为商户' + this.scaleForm.merchantName + '加秤成功'
              })
            } else {
              this.$message({
                type: 'false',
                message: res.msg
              })
              return false
            }
            this.scaleVisible = false
          }).catch(() => {
          })
        }
      })
    },
    handleAnnexExport () {
      this.annexTypesVisible = false
      annexExport(this.module, { types: this.annexTypes, ids: this.ids }).then(res => {
        FileSaver.saveAs(res, '商户附件.zip')
        this.$message({
          type: 'success',
          message: '下载成功!'
        })
      })
    },
    toMerchantBank (row) {
      this.$router.push({
        path: '/crm/merchantBank',
        query: { ownerId: row.id }
      })
    },
    toCode (row) {
      this.$router.push({
        path: '/crm/bankAccountParam',
        query: { ownerId: row.id }
      })
    },
    toAccount (row) {
      this.$router.push({
        path: '/crm/merchantUser',
        query: { merchantId: row.id }
      })
    },
    handleAnnexTypes () {
      this.annexTypesVisible = true
      console.log(this.annexTypesVisible)
    },
    handleModel () {
      modelExcel('crm/merchant', this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '商户模板.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    },
    handleChange (file) {
      importExcel('crm/merchant', file).then(res => {
        if (res.size) {
          FileSaver.saveAs(res, '导入失败数据.xlsx')
        } else {
          this.$message({
            type: 'success',
            message: '导入完成!'
          })
          this.onLoad(this.page)
        }
      })
    },
    handleExport () {
      exportExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '商户信息' + formatDate(new Date(), 'yyyyMMddhhmmss') + '.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    }
  }
}
</script>
