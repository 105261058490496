<template>
  <basic-container>
    <el-form
        :model="form"
        ref="form"
        :inline-message="true"
        label-position="right"
        label-width="100px"
        class="small-space"
        :rules="rules">
      <el-row :gutter="20">
        <enterprise-edit
            ref="EnterpriseEdit"
            v-if="enterpriseVisible"
            pic-title="市场风采"
            :show-submit="false"
            :enterprise="form.enterprise"
            :need-recognize="!form.id"
            :company-id="form.company"
            @set-enterprise="setEnterprise">
          <template v-slot:foreignKey>
            <el-col :span="24">
              <el-col :span="8">
                <el-form-item label="所属公司：" label-width="100px" prop="company">
                  <good-select :disabled="companyDisabled" v-model="form.company" url="/crm/company/dict"/>
                </el-form-item>
              </el-col>
            </el-col>
          </template>
          <template v-slot:manageArea>
            <el-col :span="8">
              <el-col :span="24">
                <el-form-item label="经营区域：" prop="areaId">
                  <el-cascader v-model="form.areaId" :options="areas" :props="areaProps" :show-all-levels="false"/>
                </el-form-item>
              </el-col>
            </el-col>
          </template>
          <template v-slot:manageAddress>
            <el-col :span="8">
              <el-form-item label="经营地址：" prop="address">
                <el-input v-model="form.address"></el-input>
              </el-form-item>
            </el-col>
          </template>
          <template v-slot:manageStatus>
            <el-col :span="8">
              <el-form-item label="备案状态：" prop="status">
                <good-select v-model="form.status" url="sys/dict/code/apply_status"/>
              </el-form-item>
            </el-col>
          </template>
        </enterprise-edit>
        <linkman-edit
            ref="LinkmanEdit"
            v-if="legalPersonVisible"
            :show-submit="false"
            v-bind:info="form.enterprise.legalPerson"
            @set-linkman="setLegalPerson"/>
        <el-footer align="center" span="24">
          <el-button type="primary" :loading="loading.form" @click="save()">保存</el-button>
          <el-button size="small" @click="handleCancel()">取消</el-button>
        </el-footer>
      </el-row>
    </el-form>
  </basic-container>
</template>
<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select'
import EnterpriseEdit from '../base/enterpriseedit'
import LinkmanEdit from '@/views/base/linkmanedit'
import { getTree } from '@/api/sys/area'
import { getByCreditCode } from '@/api/base/enterprise'
import { getDetail, submit } from '@/api/crm/market'
import { getOne } from '@/api/sys/role'

export default {
  mixins: [mixin],
  components: { GoodSelect, EnterpriseEdit, LinkmanEdit },
  data () {
    return {
      loading: {
        form: false
      },
      module: '/crm/market',
      enterpriseVisible: false,
      legalPersonVisible: false,
      roleCode: 'ROLE_MARKET_MANAGER',
      role: undefined,
      areas: [],
      areaProps: {
        lazy: true,
        value: 'id',
        label: 'name',
        lazyLoad (node, resolve) {
          const { level } = node
          if (level === 0) {
            setTimeout(() => {
              getTree({ parentId: level }).then(res => {
                if (res.success) {
                  const nodes = res.data
                  // eslint-disable-next-line no-return-assign
                  nodes.forEach(item => item.leaf = level >= 2)
                  resolve(nodes)
                }
              })
            }, 1000)
          } else {
            setTimeout(() => {
              getTree({ parentId: node.data.codeNum }).then(res => {
                if (res.success) {
                  const nodes = res.data
                  // eslint-disable-next-line no-return-assign
                  nodes.forEach(item => item.leaf = level >= 2)
                  resolve(nodes)
                }
              })
            }, 1000)
          }
        }
      },
      form: {
        company: undefined,
        enterprise: {
          legalPerson: {}
        },
        status: 0
      },
      rules: {
        enterpriseId: [{ required: true, trigger: 'blur', message: '请完善基本信息' }]
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  async created () {
    this.form.company = this.userInfo.companyId
    this.form.id = this.$route.query.id
    if (this.form.id) {
      await this.loadData()
    } else {
      this.enterpriseVisible = true
      this.legalPersonVisible = true
    }
    this.loadRole({ code: this.roleCode })
  },
  watch: {
    'form.enterprise.creditCode': {
      handler (val) {
        if (!val) {
          return
        }
        this.getByCreditCode(val)
      },
      immediate: false
    },
    'form.enterprise.areaId': {
      handler (val) {
        if (!val) {
          return
        }
        this.form.areaId = val
      }
    },
    'form.enterprise.address': {
      handler (val) {
        if (!val) {
          return
        }
        this.form.address = val
      }
    },
    'form.enterprise.legalPersonName': {
      handler (val) {
        if (!val) {
          return
        }
        if (!this.form.enterprise.legalPerson) {
          this.form.enterprise.legalPerson = {}
        }
        this.form.enterprise.legalPerson.name = val
      },
      deep: true
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_market_add,
        viewBtn: this.permission.crm_market_view,
        delBtn: this.permission.crm_market_delete,
        editBtn: this.permission.crm_market_edit
      }
    },
    companyDisabled () {
      return !this.permission.crm_company_add
    }
  },
  methods: {
    async loadData () {
      await getDetail(this.form.id).then(res => {
        this.form = res.data
        if (!this.form) {
          this.form = {
            enterprise: {}
          }
        }
        this.form.company = this.form.companyId
        this.enterpriseVisible = true
        this.legalPersonVisible = true
      })
    },
    getByCreditCode (creditCode) {
      if (!creditCode || this.$route.query.id) {
        return
      }
      getByCreditCode(this.module, creditCode).then(res => {
        if (res.success && res.data) {
          this.form = res.data
          this.$message({
            type: 'warning',
            message: '对应的营业执照已创建市场，无需再次创建！'
          })
        }
      })
    },
    setEnterprise (enterprise) {
      this.form.enterprise = enterprise
      this.form.enterpriseId = enterprise.id
      this.form.name = enterprise.name
      if (!this.form.areaId) {
        this.form.areaId = enterprise.areaId
      }
      if (!this.form.address) {
        this.form.address = enterprise.address
      }
    },
    setLegalPerson (legalPerson) {
      this.form.enterprise.legalPerson = legalPerson
    },
    save () {
      const that = this
      let enterprise
      let legalPerson
      this.$refs.EnterpriseEdit.handleSubmit().then(() => {
        // 取区域的最后一个元素
        const areaId = this.form.areaId
        if (areaId instanceof Array) {
          this.form.areaId = areaId.pop()
        }
        enterprise = this.form.enterprise
        legalPerson = enterprise.legalPerson
        // 删除企业信息
        delete this.form.enterprise
        return that.submit()
      }).then(() => {
        // 恢复企业信息
        this.form.enterprise = enterprise
        this.form.enterprise.legalPerson = legalPerson
        const name = that.form.enterprise.legalPerson.name
        if (!name && name !== that.form.enterprise.legalPersonName) {
          this.$message({
            type: 'error',
            message: '法人名称必需相同!'
          })
          return false
        }
        return that.$refs.LinkmanEdit.handleSubmit()
      }).then(() => {
        return that.submit()
      }).then(() => {
        that.$router.push('/crm/market')
      }).catch(() => {
        that.locateToErr()
      })
    },
    loadRole () {
      getOne({ code: this.roleCode }).then(res => {
        if (res.success) {
          this.role = res.data
        }
      })
    },
    submit () {
      if (this.form.enterprise && this.form.enterprise.legalPerson) {
        const name = this.form.enterprise.legalPerson.name
        if (!name && name !== this.form.enterprise.legalPersonName) {
          this.$message({
            type: 'error',
            message: '法人名称必需相同!'
          })
        }
      }
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loading.add = true
            submit(this.form).then((res) => {
              if (res.success) {
                this.form.id = res.data
                this.$route.query.id = res.data
                resolve(true)
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
                reject(new Error(res.msg))
              }
              this.loading.add = false
            }).catch(() => {
              reject(new Error())
            })
          } else {
            reject(new Error())
          }
        })
      })
    },
    handleCancel () {
      this.$router.push({ path: '/crm/market' })
    },
    // 滚动条定位到第一个校验失败的div的位置
    locateToErr () {
      setTimeout(() => {
        const errorDiv = document.getElementsByClassName('is-error')
        if (errorDiv.length) {
          errorDiv[0].scrollIntoView()
        }
      }, 0)
    },
    reset () {
      if (!this.form) {
        this.form = {
          enterprise: {}
        }
      }
    }
  }
}
</script>
