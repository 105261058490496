<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :search.sync="search"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        :before-open="beforeOpen"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.erp_devicepurchase_delete"
            plain
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toDivision(row)">拆单
        </el-button>
        <el-button
            type="text"
            icon="el-icon-download"
            size="small"
            @click.stop="toExport(row)">导出
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { tree } from '@/api/erp/devicepurchase'
import { getDetail as getModel } from '@/api/erp/devicemodel'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'erp/devicepurchase',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        tree: true,
        index: false,
        viewBtn: false,
        addBtn: true,
        editBtn: false,
        delBtn: false,
        selection: false,
        align: 'center',
        column: [
          {
            label: '批次号',
            prop: 'batchNo',
            search: true,
            align: 'left',
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: true,
              message: '请输入菜单名称'
            }]
          },
          {
            label: '市场',
            prop: 'marketId',
            type: 'select',
            select: true,
            dicUrl: '/crm/market/dict'
          },
          {
            label: '日期',
            prop: 'batchDate',
            width: 100,
            type: 'date',
            valueFormat: 'yyyy-MM-dd'
          },
          {
            label: '型号',
            prop: 'model',
            type: 'select',
            search: true,
            select: true,
            change: (e) => {
              getModel(e.value).then((res) => {
                if (res.success && res.data) {
                  this.form.type = res.data.typeId
                  this.form.brand = res.data.brandId
                }
              })
            },
            dicUrl: '/erp/devicemodel/getDict'
          },
          {
            label: '数量',
            prop: 'amount',
            type: 'number',
            hide: true
          },
          {
            label: '设备类型',
            prop: 'type',
            type: 'select',
            search: true,
            select: true,
            dicUrl: '/erp/devicetype/getDict'
          },
          {
            label: '品牌',
            prop: 'brand',
            type: 'select',
            search: true,
            select: true,
            dicUrl: '/erp/devicebrand/getDict'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.erp_devicepurchase_add,
        viewBtn: this.permission.erp_devicepurchase_view,
        delBtn: this.permission.erp_devicepurchase_delete,
        editBtn: this.permission.erp_devicepurchase_edit
      }
    }
  },
  methods: {
    toExport (row) {
      this.search.id = row.id
      this.handleExport('订单' + row.batchNo)
    },
    toDivision (row) {
      this.$router.push({
        path: '/erp/device/purchaseEdit',
        query: { id: row.id }
      })
    },
    onLoad () {
      tree(this.search).then(res => {
        this.data = res.data
      })
    }
  }
}
</script>
