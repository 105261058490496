<template>
  <div>
    <el-card class="good-card">
      <div>
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            :inline-message="true"
            label-position="right"
            label-width="150px"
            class="small-space form-one">
          <el-form-item label="分类：" prop="typeId">
            <good-select v-model="form.typeId" url="/erp/devicetype/getDict" :props="props"></good-select>
          </el-form-item>
          <el-form-item label="名称：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-divider v-if="showDivider" @click="showDivider=!showDivider">更多<i class="el-icon-arrow-down"></i>
          </el-divider>
          <div v-if="!showDivider">
            <el-form-item label="上级品牌：" prop="parentId">
              <good-select v-model="form.parentId" url="/erp/devicebrand/getDict"></good-select>
            </el-form-item>
            <el-form-item label="描述：" prop="description">
              <el-input v-model="form.description"></el-input>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item label="排序：" prop="sort">
              <el-input v-model="form.sort"></el-input>
            </el-form-item>
            <el-form-item label="图片：" prop="icon">
              <good-cropper
                  v-model="form.icon"
                  :showFileList="false"
                  :bucket="'zhsn-scale'"
                  :initData="form.icon"
                  :uploadClass="'upload-pic'"></good-cropper>
            </el-form-item>
          </div>
          <el-divider v-if="!showDivider" @click="showDivider=!showDivider">收起<i class="el-icon-arrow-up"></i>
          </el-divider>
          <el-form-item>
            <el-button type="primary" @click="save()">保存并提交</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import GoodSelect from '@/components/good-select/index'
import GoodCropper from '@/components/good-cropper/index'
import { submit, getDetail } from '@/api/erp/devicebrand'

export default {
  components: { GoodSelect, GoodCropper },
  data () {
    return {
      total: null,
      list: null,
      showDialog: false,
      props: {
        value: 'id',
        label: 'name',
        childList: 'children'
      },
      form: {
        id: undefined,
        parentId: undefined,
        typeId: undefined,
        name: undefined,
        icon: undefined,
        description: undefined,
        sort: undefined,
        remark: undefined
      },
      rules: {
        typeId: [{ required: true, trigger: 'blur', message: '请选择设备分类' }],
        name: [{ required: true, trigger: 'blur', message: '请输入品牌名称' }]
      },
      showDivider: true
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },

  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          submit(this.form).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.$emit('brand-cancel')
            } else {
              this.$message({
                type: 'false',
                message: res.msg
              })
            }
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$emit('brand-cancel')
    }
  }

}
</script>
