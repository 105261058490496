<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.base_annex_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'base/annex',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '所属者类型',
            prop: 'ownerType',
            dicUrl: 'owner_type',
            placeholder: '请设置附件所属者类型',
            rules: [{
              required: true,
              message: '请设置附件所属者类型'
            }]
          },
          {
            label: '所属者',
            prop: 'ownerId',
            rules: [{
              required: true,
              message: '请输入所属者'
            }]
          },
          {
            label: '附件类型',
            prop: 'type',
            dicUrl: 'annex_type',
            placeholder: '请设置附件类型',
            rules: [{
              required: true,
              message: '请设置附件类型'
            }]
          },
          {
            label: '附件链接地址',
            prop: 'url',
            rules: [{
              required: true,
              message: '请输入附件链接地址'
            }]
          },
          {
            label: '有效期起',
            prop: 'validFrom',
            rules: [{
              required: true,
              message: '请输入有效期起'
            }]
          },
          {
            label: '有效期止',
            prop: 'validTo',
            rules: [{
              required: true,
              message: '请输入有效期止'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.base_annex_add,
        viewBtn: this.permission.base_annex_view,
        delBtn: this.permission.base_annex_delete,
        editBtn: this.permission.base_annex_edit
      }
    }
  },
  methods: {}
}
</script>
