<template>
  <basic-container>
    <el-collapse-transition>
      <el-form :inline="true" class="search-form">
        <el-form-item>
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="类型"
              v-model="page.type"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item search" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-collapse-transition>
    <el-row class="padding-row">
      <el-button type="primary" icon="el-icon-add" @click="handleCreate">新增</el-button>
    </el-row>
    <el-table
        class="good-table"
        :data="list"
        v-loading.body="listLoading"
        element-loading-text="拼命加载中"
        border
        fit
        highlight-current-row>
          <el-table-column label="编号" prop="id">
            <template slot-scope="scope">
              <span>{{scope.row.id}}</span>
            </template>
          </el-table-column>
          <el-table-column label="父级编号" prop="parentId">
            <template slot-scope="scope">
              <span>{{scope.row.parentId}}</span>
            </template>
          </el-table-column>
          <el-table-column label="所有父级编号" prop="parentIds">
            <template slot-scope="scope">
              <span>{{scope.row.parentIds}}</span>
            </template>
          </el-table-column>
          <el-table-column label="区域数字编码" prop="codeNum">
            <template slot-scope="scope">
              <span>{{scope.row.codeNum}}</span>
            </template>
          </el-table-column>
          <el-table-column label="区域字母编码" prop="codeEn">
            <template slot-scope="scope">
              <span>{{scope.row.codeEn}}</span>
            </template>
          </el-table-column>
          <el-table-column label="名称" prop="name">
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="区域级别" prop="level">
            <template slot-scope="scope">
              <span>{{scope.row.level}}</span>
            </template>
          </el-table-column>
          <el-table-column label="排序" prop="sort">
            <template slot-scope="scope">
              <span>{{scope.row.sort}}</span>
            </template>
          </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="handleUpdate(scope.row.id)">编辑</el-button>
          <el-button
v-if="scope.row.status!='deleted'"
size="small"
type="danger"
                     @click="handleDelete(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="!listLoading" class="end pagination-container">
      <el-pagination
:current-page.sync="page.currentPage"
background
                     @current-change="handleCurrentChange"
                     @size-change="handleSizeChange"
                     :page-sizes="[10,20,30, 50]"
                     :page-size="page.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
:total="total">
      </el-pagination>
    </div>
    <el-dialog title="区域表" :visible.sync="showDialog">
      <el-row :gutter="20">
        <el-form
:model="form"
ref="form"
:rules="rules"
label-position="left"
                 label-width="120px"
class="small-space"
style='padding:20px 60px;'>
              <el-col :span="12">
                <el-form-item label="编号：" prop="id">
                  <el-input v-model="form.id"  disabled ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="父级编号：" prop="parentId">
                  <el-input v-model="form.parentId" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所有父级编号：" prop="parentIds">
                  <el-input v-model="form.parentIds" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="区域数字编码：" prop="codeNum">
                  <el-input v-model="form.codeNum" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="区域字母编码：" prop="codeEn">
                  <el-input v-model="form.codeEn" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="名称：" prop="name">
                  <el-input v-model="form.name" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="区域级别：" prop="level">
                  <el-input v-model="form.level" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="排序：" prop="sort">
                  <el-input v-model="form.sort" ></el-input>
                </el-form-item>
              </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import { getList, remove, submit, getDetail } from '@/api/sys/area'

export default {
  data () {
    return {
      total: null,
      list: null,
      listLoading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      showDialog: false,
      form: {
        id: undefined,
        parentId: undefined,
        parentIds: undefined,
        codeNum: undefined,
        codeEn: undefined,
        name: undefined,
        level: undefined,
        sort: undefined
      },
      rules: {
        parentId: [{ required: true, trigger: 'blur', message: '请输入 父级编号' }],
        parentIds: [{ required: true, trigger: 'blur', message: '请输入 所有父级编号' }],
        codeNum: [{ required: true, trigger: 'blur', message: '请输入 区域数字编码' }],
        codeEn: [{ required: true, trigger: 'blur', message: '请输入 区域字母编码' }],
        name: [{ required: true, trigger: 'blur', message: '请输入 名称' }],
        level: [{ required: true, trigger: 'blur', message: '请输入 区域级别' }],
        sort: [{ required: true, trigger: 'blur', message: '请输入 排序' }]
      }
    }
  },
  created () {
    this.onLoad(this.page)
  },

  methods: {
    onLoad (page, params = {}) {
      this.listLoading = true
      getList(page.currentPage, page.pageSize, params).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
      })
    },
    handleFilter () {
      this.onLoad(this.page)
    },
    handleCreate () {
      this.showDialog = true
    },
    handleUpdate (id) {
      getDetail(id).then(res => {
        this.form = res.data
        this.showDialog = true
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '提示', {}).then(() => {
        remove(row.id).then(res => {
          if (res.success) {
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.onLoad(this.page)
          } else {
            this.$notify({
              title: '删除失败',
              message: res.data.message,
              type: 'error',
              duration: 2000
            })
          }
        })
      })
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保存
      this.$refs.form.validate(valid => {
        if (valid) {
          submit(this.form).then(res => {
            if (res.success) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.showDialog = false
              this.onLoad(this.page)
            } else {
              this.$notify({
                title: '操作失败',
                message: res.data.message,
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      })
    }
  }

}
</script>
