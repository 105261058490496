<template>
  <basic-container>
    <avue-crud
        :defaults.sync="defaults"
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.crm_merchantbooth_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template>
        <booth/>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getList } from '@/api/common'
import Booth from '../base/boothEdit'

export default {
  name: 'MerchantBooth',
  mixins: [mixin],
  components: { Booth },
  props: {
    merchantId: {
      default: '',
      type: String
    },
    booths: {
      default: null,
      type: Array
    },
    linkmen: {
      default: null,
      type: Array
    }
  },
  data () {
    return {
      module: 'crm/merchantbooth',
      form: {},
      defaults: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        align: 'center',
        dialogWidth: '70%',
        labelWidth: 120,
        column: [
          {
            label: '摊位号',
            prop: 'sn',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '建筑',
            prop: 'buildingId',
            bind: 'booth.buildingId',
            hide: true,
            addDisplay: true,
            editDisplay: true,
            type: 'select',
            dicUrl: 'base/building/dict',
            placeholder: '请选择建筑',
            rules: [{
              required: true,
              message: '请选择建筑'
            }]
          },
          {
            label: '楼层',
            prop: 'floorId',
            bind: 'booth.floorId',
            hide: true,
            addDisplay: true,
            editDisplay: true,
            type: 'select',
            placeholder: '请选择楼层',
            rules: [{
              required: true,
              message: '请选择楼层'
            }]
          },
          {
            label: '区域',
            prop: 'regionId',
            bind: 'booth.regionId',
            hide: true,
            addDisplay: true,
            editDisplay: true,
            type: 'select',
            placeholder: '请选择区域',
            rules: [{
              required: true,
              message: '请选择区域'
            }]
          },
          {
            label: '摊位',
            prop: 'boothId',
            hide: true,
            addDisplay: true,
            editDisplay: true,
            type: 'select',
            message: '请选择摊位',
            rules: [{
              required: true,
              message: '请选择摊位'
            }]
          },
          {
            label: '租赁合同',
            bind: 'contract.name',
            rules: [{
              required: true,
              message: '请设置租赁合同'
            }]
          },
          {
            label: '联系人',
            bind: 'linkman.name',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '联系人类型',
            prop: 'linkmanType',
            type: 'select',
            hide: true,
            addDisplay: true,
            editDisplay: true,
            dicData: [{ value: 1, label: '户主' }, { value: 2, label: '员工' }],
            rules: [{
              required: true,
              message: '请设置联系人类型'
            }]
          },
          {
            label: '联系人',
            prop: 'linkmanId',
            hide: true,
            addDisplay: true,
            editDisplay: true,
            type: 'select',
            message: '请设置摊位联系人'
          },
          {
            label: '主营业务',
            prop: 'major',
            type: 'select',
            dicUrl: '/sys/dict/code/major_type',
            rules: [{
              required: true,
              message: '请输入主营业务，参见字典表major_type'
            }]
          },
          {
            label: '入驻日期',
            prop: 'settleDate',
            type: 'date',
            rules: [{
              required: true,
              message: '请输入入驻日期'
            }]
          },
          {
            label: '租金',
            prop: 'rent',
            type: 'number',
            rules: [{
              required: true,
              message: '请输入租赁年限'
            }]
          },
          {
            label: '租赁年限',
            prop: 'leaseTerm',
            type: 'number',
            rules: [{
              required: true,
              message: '请设置租赁年限'
            }]
          }
        ]
      },
      data: []
    }
  },
  watch: {
    'form.linkmanType' (linkmanType) {
      console.log(linkmanType)
      if (linkmanType === 1) {
        this.defaults.linkmanId.dicUrl = 'crm/merchantuser/dict'
      } else {
        this.defaults.linkmanId.dicUrl = 'crm/merchantemployee/dict'
      }
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_merchantbooth_add,
        viewBtn: this.permission.crm_merchantbooth_view,
        delBtn: this.permission.crm_merchantbooth_delete,
        editBtn: this.permission.crm_merchantbooth_edit
      }
    }
  },
  methods: {
    onLoad () {
      if (this.booths) {
        this.data = this.booths
      } else {
        this.search.merchantId = this.merchantId
        getList(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
          const data = res.data
          this.page.total = data.total
          this.data = data.records
        })
      }
    }
  }
}
</script>
