<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad"
        :row-style="rowStyle"
      >
      <template slot="status" slot-scope="scope" >
        <el-tag v-if="scope.row.status" type="success">上架</el-tag>
        <el-tag v-else type="info">下架 </el-tag>
      </template>
      <template slot="menuLeft">
        <div class="start">
          <el-select
              class="search-input"
              v-model="merchantId"
              filterable
              remote
              reserve-keyword
              placeholder="商户全称（支持拼音首字母）"
              :remote-method="filterMerchant"
              @change="refreshChange"
              :loading="loading">
            <el-option
                v-for="item in merchantList"
                :key="item.id"
                :label="item.fullName"
                :value="item.id">
            </el-option>
          </el-select>
          <el-button
              type="success"
              size="small"
              v-if="permission.mall_goods_edit"
              @click="handleStatus(1)"> 上架
          </el-button>
          <el-button
              type="warning"
              size="small"
              v-if="permission.mall_goods_edit"
              @click="handleStatus(0)"> 下架
          </el-button>
        </div>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getByName } from '@/api/crm/merchant'
import { statusPage, status } from '@/api/mall/goods'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/goods',
      merchantList: [],
      form: {},
      search: {},
      merchantId: '',
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      loading: false,
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        align: 'center',
        column: [
          {
            label: '商品图片',
            prop: 'pic',
            type: 'img'
          },
          {
            label: '商品学名',
            prop: 'tradeName'
          },
          {
            label: '商品名称',
            prop: 'name'
          },
          {
            label: '商品单位',
            prop: 'unitId',
            type: 'select',
            dicUrl: '/mall/goodsunit/dict'
          },
          {
            label: '分类',
            prop: 'typeId',
            type: 'select',
            dicUrl: '/mall/goodstype/dict'
          },
          {
            label: '销售方式',
            prop: 'sellType',
            type: 'select',
            dicUrl: '/sys/dict/code/sell_type'
          },
          {
            label: '零售价',
            prop: 'retailPrice'
          },
          {
            label: '上下架',
            prop: 'status',
            slot: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_goods_add,
        viewBtn: this.permission.mall_goods_view,
        delBtn: this.permission.mall_goods_delete,
        editBtn: this.permission.mall_goods_edit
      }
    }
  },
  methods: {
    handleStatus (data) {
      const type = data ? '上架' : '下架'
      if (!this.merchantId) {
        this.$message.warning('请选择商户')
        return
      }
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据' + type + '?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return status(this.ids, data)
        })
        .then(() => {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    rowStyle ({ row }) {
      if (row.existsFlag) {
        return {
          backgroundColor: '#eee'
        }
      }
    },
    onLoad (page) {
      if (!this.merchantId) {
        return
      }
      this.search.merchantId = this.merchantId
      statusPage(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    filterMerchant (query) {
      if (query !== '') {
        this.loading = true
        getByName(query).then(res => {
          this.loading = false
          this.merchantList = res.data
        })
      } else {
        this.merchantList = []
      }
    },
    toAdd () {
      this.$router.push({ path: '/mall/goodsedit' })
    },
    toEdit (row) {
      this.$router.push({
        path: '/mall/goodsedit',
        query: { id: row.id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.search-input {
  margin-right: 15px;
  width: 250px !important;
}
</style>
