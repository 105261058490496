<template>
  <basic-container>
    <el-collapse-transition>
      <el-form :inline="true" class="search-form">
        <el-form-item>
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="类型"
              v-model="page.type"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item search" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-collapse-transition>
    <el-row class="padding-row">
      <el-button type="primary" icon="el-icon-add" @click="handleCreate">新增</el-button>
    </el-row>
    <el-table
        class="good-table"
        :data="list"
        v-loading.body="listLoading"
        element-loading-text="拼命加载中"
        border
        fit
        highlight-current-row>
          <el-table-column label="主键" prop="id">
            <template slot-scope="scope">
              <span>{{scope.row.id}}</span>
            </template>
          </el-table-column>
          <el-table-column label="unionid" prop="unionId">
            <template slot-scope="scope">
              <span>{{scope.row.unionId}}</span>
            </template>
          </el-table-column>
          <el-table-column label="公众号appid" prop="appid">
            <template slot-scope="scope">
              <span>{{scope.row.appid}}</span>
            </template>
          </el-table-column>
          <el-table-column label="openid 对当前公众号唯一" prop="openId">
            <template slot-scope="scope">
              <span>{{scope.row.openId}}</span>
            </template>
          </el-table-column>
          <el-table-column label="订阅状态，0未关注，1已关注" prop="subscribe">
            <template slot-scope="scope">
              <span>{{scope.row.subscribe}}</span>
            </template>
          </el-table-column>
          <el-table-column label="订阅时间" prop="subscribeTime">
            <template slot-scope="scope">
              <span>{{scope.row.subscribeTime}}</span>
            </template>
          </el-table-column>
          <el-table-column label="关注场景" prop="subscribeScene">
            <template slot-scope="scope">
              <span>{{scope.row.subscribeScene}}</span>
            </template>
          </el-table-column>
          <el-table-column label="二维码扫码场景" prop="qrScene">
            <template slot-scope="scope">
              <span>{{scope.row.qrScene}}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="remark">
            <template slot-scope="scope">
              <span>{{scope.row.remark}}</span>
            </template>
          </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="handleUpdate(scope.row.id)">编辑</el-button>
          <el-button
v-if="scope.row.status!='deleted'"
size="small"
type="danger"
                     @click="handleDelete(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="!listLoading" class="end pagination-container">
      <el-pagination
:current-page.sync="page.currentPage"
background
                     @current-change="handleCurrentChange"
                     @size-change="handleSizeChange"
                     :page-sizes="[10,20,30, 50]"
                     :page-size="page.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
:total="total">
      </el-pagination>
    </div>
    <el-dialog title="用户微信信息" :visible.sync="showDialog">
      <el-row :gutter="20">
        <el-form
:model="form"
ref="form"
:rules="rules"
label-position="left"
                 label-width="120px"
class="small-space"
style='padding:20px 60px;'>
              <el-col :span="12">
                <el-form-item label="主键：" prop="id">
                  <el-input v-model="form.id"  disabled ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="unionid：" prop="unionId">
                  <el-input v-model="form.unionId" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="公众号appid：" prop="appid">
                  <el-input v-model="form.appid" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="openid 对当前公众号唯一：" prop="openId">
                  <el-input v-model="form.openId" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="订阅状态，0未关注，1已关注：" prop="subscribe">
                  <el-input v-model="form.subscribe" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="订阅时间：" prop="subscribeTime">
                  <el-input v-model="form.subscribeTime" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="关注场景：" prop="subscribeScene">
                  <el-input v-model="form.subscribeScene" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="二维码扫码场景：" prop="qrScene">
                  <el-input v-model="form.qrScene" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="备注：" prop="remark">
                  <el-input v-model="form.remark" ></el-input>
                </el-form-item>
              </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import { getList, remove, submit, getDetail } from '@/api/mp/mpuserinfo'

export default {
  data () {
    return {
      total: null,
      list: null,
      listLoading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      showDialog: false,
      form: {
        id: undefined,
        unionId: undefined,
        appid: undefined,
        openId: undefined,
        subscribe: undefined,
        subscribeTime: undefined,
        subscribeScene: undefined,
        qrScene: undefined,
        remark: undefined
      },
      rules: {
        unionId: [{ required: true, trigger: 'blur', message: '请输入 unionid' }],
        appid: [{ required: true, trigger: 'blur', message: '请输入 公众号appid' }],
        openId: [{ required: true, trigger: 'blur', message: '请输入 openid 对当前公众号唯一' }],
        subscribe: [{ required: true, trigger: 'blur', message: '请输入 订阅状态，0未关注，1已关注' }],
        subscribeTime: [{ required: true, trigger: 'blur', message: '请输入 订阅时间' }],
        subscribeScene: [{ required: true, trigger: 'blur', message: '请输入 关注场景' }],
        qrScene: [{ required: true, trigger: 'blur', message: '请输入 二维码扫码场景' }],
        remark: [{ required: true, trigger: 'blur', message: '请输入 备注' }]
      }
    }
  },
  created () {
    this.onLoad(this.page)
  },

  methods: {
    onLoad (page, params = {}) {
      this.listLoading = true
      getList(page.currentPage, page.pageSize, params).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
      })
    },
    handleFilter () {
      this.onLoad(this.page)
    },
    handleCreate () {
      this.showDialog = true
    },
    handleUpdate (id) {
      getDetail(id).then(res => {
        this.form = res.data
        this.showDialog = true
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '提示', {}).then(() => {
        remove(row.id).then(res => {
          if (res.success) {
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.onLoad(this.page)
          } else {
            this.$notify({
              title: '删除失败',
              message: res.data.message,
              type: 'error',
              duration: 2000
            })
          }
        })
      })
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保存
      this.$refs.form.validate(valid => {
        if (valid) {
          submit(this.form).then(res => {
            if (res.success) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.showDialog = false
              this.onLoad(this.page)
            } else {
              this.$notify({
                title: '操作失败',
                message: res.data.message,
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      })
    }
  }

}
</script>
