<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <div class="start">
          <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              v-if="permission.crm_market_add"
              @click.stop="toAdd()"
          >新增
          </el-button>
          <el-button
              type="danger"
              size="small"
              icon="el-icon-delete"
              plain
              v-if="permission.crm_market_delete"
              @click="handleDelete">删 除
          </el-button>
<!--          <el-button type="success" @click.stop="handleAnnexTypes()">附件一键导出</el-button>-->
          <el-button type="primary" @click.stop="handleModel()">模板下载</el-button>
          <el-button type="success" @click.stop="handleExport()">导出</el-button>
          <el-upload
              class="filter-item"
              :show-file-list="false"
              :action="handleImport()"
              :headers="uploadHeaders()"
              :on-success="uploadSuc">
            <el-button type="warning">导入</el-button>
          </el-upload>
        </div>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            v-if="permission.crm_market_edit"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            v-if="permission.crm_market_delete"
            @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
      <template slot="search">
        <el-form align="left" :inline="true">
          <el-form-item label="关键字:">
            <el-input placeholder="名称、姓名、手机等" v-model="search.query"></el-input>
          </el-form-item>
        </el-form>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import FileSaver from 'file-saver'
import { formatDate } from '@/util/date'
import { exportExcel, modelExcel, annexExport } from '@/api/common'
import { bindColumns } from '@/api/base/enterprise'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/market',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        align: 'center',
        column: bindColumns([
          {
            label: '所属公司',
            prop: 'companyId',
            width: 200,
            type: 'select',
            dicUrl: '/crm/company/dict'
          },
          {
            label: '简称',
            prop: 'name'
          }], [{
          label: '经营地址',
          prop: 'address',
          width: 200
        },
        {
          label: '类型',
          prop: 'category',
          type: 'select',
          dicUrl: '/sys/dict/code/market_type'
        },
        {
          label: '备案时间',
          prop: 'applyTime',
          type: 'date',
          width: 150
        },
        {
          label: '状态',
          prop: 'status',
          type: 'radio',
          dicUrl: 'sys/dict/code/apply_status'
        }
        ])
      },
      data: []
    }
  },
  created () {
    this.search.companyId = this.$route.query.companyId
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_market_add,
        viewBtn: this.permission.crm_market_view,
        delBtn: this.permission.crm_market_delete,
        editBtn: this.permission.crm_market_edit
      }
    }
  },

  methods: {
    toAdd () {
      this.$router.push({
        path: '/crm/marketEdit',
        query: { companyId: this.form.companyId }
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/crm/marketEdit',
        query: { id: row.id }
      })
    },
    handleModel () {
      modelExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '市场信息模板.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    },
    handleExport () {
      exportExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '市场信息' + formatDate(new Date(), 'yyyyMMddhhmmss') + '.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    },
    handleAnnexTypes () {
      if (this.search.marketPictype == null) {
        this.$message({
          type: 'false',
          message: '附件类型为空,请选择附件类型!'
        })
      } else {
        annexExport(this.module, this.page.currentPage, this.page.pageSize, this.search).then((res) => {
          if (this.search.marketPictype === 1) {
            FileSaver.saveAs(res, '中汇数农-智慧农贸管理平台-市场信息-身份证正面.zip')
          } else if (this.search.marketPictype === 2) {
            FileSaver.saveAs(res, '中汇数农-智慧农贸管理平台-市场信息-身份证反面.zip')
          } else if (this.search.marketPictype === 3) {
            FileSaver.saveAs(res, '中汇数农-智慧农贸管理平台-市场信息-营业执照.zip')
          }

          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
      }
    },
    toMerchant (row) {
      this.$router.push({
        path: '/crm/merchant',
        query: { marketId: row.id }
      })
    },
    toMarketPerson (row) {
      this.$router.push({
        path: '/crm/marketPerson',
        query: { marketId: row.id }
      })
    },
    toBank (row) {
      this.$router.push({
        path: '/crm/marketBank',
        query: { ownerId: row.id }
      })
    }
  }
}
</script>
