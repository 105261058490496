<template>
  <basic-container>
    <el-form
        :model="form"
        ref="form"
        :rules="rules"
        :inline-message="true"
        label-position="right"
        label-width="150px"
        class="small-space form-one">
      <el-form-item label="分类：" prop="typeId">
        <good-select type="tree" allow-add :item-add="typeAdd" v-model="form.typeId" url="/erp/devicetype/getDict"></good-select>
      </el-form-item>
      <el-form-item label="品牌：" prop="brandId">
        <good-select allow-add :item-add="brandAdd" v-model="form.brandId" url="/erp/devicebrand/getDict"></good-select>
      </el-form-item>
      <el-form-item label="上级型号：" prop="parentId">
        <good-select v-model="form.parentId" url="/erp/devicemodel/getDict"></good-select>
      </el-form-item>
      <el-form-item label="名称：" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="描述：" prop="description">
        <el-input v-model="form.description"></el-input>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input v-model="form.remark"></el-input>
      </el-form-item>
      <el-form-item label="排序：" prop="sort">
        <el-input v-model="form.sort"></el-input>
      </el-form-item>
      <el-form-item label="型号图片：" prop="icon">
        <good-cropper
            v-model="form.icon"
            :showFileList="false"
            :bucket="'zhsn-scale'"
            :initData="form.icon"
            :uploadClass="'upload-pic'"></good-cropper>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save(true)">保存并提交</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
    <el-dialog width="70%" :visible.sync="typeVisible">
      <type/>
    </el-dialog>
    <el-dialog width="70%" :visible.sync="brandVisible">
      <brand/>
    </el-dialog>
  </basic-container>
</template>

<script>
import GoodSelect from '@/components/good-select/index'
import GoodCropper from '@/components/good-cropper/index'
import { submit, getDetail } from '@/api/erp/devicemodel'
import Type from '@/views/erp/deviceTypeEdit'
import Brand from '@/views/erp/deviceBrandEdit'
import { submit as typeSubmit } from '@/api/erp/devicetype'
import { submit as brandSubmit } from '@/api/erp/devicebrand'

export default {
  components: { GoodSelect, GoodCropper, Type, Brand },
  data () {
    return {
      total: null,
      list: null,
      listLoading: true,
      typeVisible: false,
      brandVisible: false,
      form: {
        id: undefined,
        parentId: undefined,
        typeId: undefined,
        name: undefined,
        icon: undefined,
        description: undefined,
        sort: undefined,
        remark: undefined
      },
      typeForm: {},
      brandForm: {},
      rules: {
        name: [{ required: true, trigger: 'blur', message: '请输入型号名称' }],
        typeId: [{ required: true, trigger: 'blur', message: '请选择设备分类' }],
        brandId: [{ required: true, trigger: 'blur', message: '请选择品牌分类' }]
      }
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },

  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          submit(this.form).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            } else {
              this.$message({
                type: 'false',
                message: res.msg
              })
            }
            if (res.code === 200) {
              this.$router.push({ path: '/erp/devicemodel' })
            }
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/erp/devicemodel' })
    },
    typeAdd () {
      this.typeVisible = true
    },
    brandAdd () {
      this.brandVisible = true
    },
    typeSubmit () {
      typeSubmit(this.typeForm).then(res => {
        if (res.success) {
          this.typeVisible = false
          this.form.types.push(this.typeForm.dictKey)
        }
      })
    },
    brandSubmit () {
      brandSubmit(this.brandForm).then(res => {
        if (res.success) {
          this.brandVisible = false
          this.form.types.push(this.typeForm.dictKey)
        }
      })
    }
  }

}
</script>
