<template>
  <el-container>
    <el-aside width="60%" class="goods-name">
      <avue-crud
          :option="option"
          :data="data"
          :search.sync="search"
          ref="crud"
          :page="page"
          @row-del="rowDel"
          v-model="form"
          :permission="permissionList"
          @row-update="rowUpdate"
          @row-save="rowSave"
          @size-change="sizeChange"
          @current-change="currentChange"
          :before-open="beforeOpen"
          @search-change="searchChange"
          @search-reset="searchReset"
          @refresh-change="refreshChange"
          @selection-change="selectionChange"
          @row-click="handleRowClick"
          @on-load="onLoad">
        <template slot="menuLeft">
          <el-button
              type="danger"
              size="small"
              icon="el-icon-delete"
              v-if="permission.mall_goodsname_delete"
              @click="handleDelete">删 除
          </el-button>
          <el-button
              type="success"
              size="small"
              v-if="permission.mall_goodsname_edit"
              @click="handleType">批量更改分类
          </el-button>
        </template>
        <template slot-scope="{row,index}" slot="menu">
          <el-button
              size="small"
              type="text"
              v-show="showBtn(row)"
              v-if="!row.$cellEdit"
              @click.stop="$refs.crud.rowCell(row,index)">编辑
          </el-button>
          <el-button
              size="small"
              type="text"
              v-show="showBtn(row)"
              @click.stop="$refs.crud.rowDel(row,index)">删除
          </el-button>
        </template>
      </avue-crud>
    </el-aside>
    <el-main>
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="名称：">
          <el-input v-model="choose.name" disabled style="width: 200px;margin-right: 15px;"></el-input>
        </el-form-item>
        <el-form-item label="图片：" prop="img">
          <good-cropper
              v-model="choose.img"
              :initData="choose.img"
              :disabled="!choose.enabled || !showBtn(choose)"
              :bucket="'zhsn-scale'"
              :showFileList="true"
              @updateList="updateImg"
              :uploadClass="'upload-pic'"></good-cropper>
        </el-form-item>
      </el-form>
    </el-main>
    <el-dialog
        title="提示"
        :visible.sync="typeVisible"
        width="500px">
      <el-form>
        <el-form-item label="选择分类：">
          <good-select v-model="typeId" url="/mall/goodstype/dict"></good-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="typeVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleUpdateTypeIds()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import mixin from '@/util/mixin'
import me from '@/util/me'
import { submit, updateTypeIds } from '@/api/mall/goodsname'
import GoodCropper from '@/components/good-cropper/index'
import GoodSelect from '@/components/good-select/index'

export default {
  mixins: [mixin, me],
  components: { GoodCropper, GoodSelect },
  data () {
    const validateName = (rule, value, callback) => {
      if (!/[a-zA-Z0-9_\u4e00-\u9fa5]+$/.test(value)) {
        callback(new Error('只能包含汉字字母数字'))
      } else {
        callback()
      }
    }
    return {
      module: 'mall/goodsname',
      name: '',
      typeId: '',
      option: {
        tip: false,
        border: true,
        index: true,
        addBtn: true,
        viewBtn: false,
        editBtn: false,
        delBtn: false,
        cellBtn: true,
        selection: true,
        searchMenuSpan: 8,
        selectable: (row) => {
          return this.showBtn(row)
        },
        column: [
          {
            label: '名称',
            prop: 'name',
            search: true,
            rules: [{ validator: validateName, trigger: 'blur' }],
            searchSpan: 8,
            cell: true
          },
          {
            label: '别名',
            prop: 'alias',
            cell: true
          },
          {
            label: '分类',
            prop: 'typeId',
            type: 'select',
            search: true,
            dicUrl: '/mall/goodstype/dict',
            cell: true
          },
          {
            label: '图数',
            prop: 'imgCount',
            width: 50,
            align: 'center',
            addDisplay: false,
            editDisplay: false
          }
        ]
      },
      list: [],
      choose: {
        name: '',
        img: '',
        enabled: false
      },
      typeVisible: false,
      update: false
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_goodsname_add,
        viewBtn: this.permission.mall_goodsname_view,
        delBtn: this.permission.mall_goodsname_delete,
        editBtn: this.permission.mall_goodsname_edit
      }
    }
  },
  watch: {
    'name' () {
      if (this.name) {
        this.list = this.data.filter(item => item.name.indexOf(this.name) > -1)
      } else {
        this.list = this.data
      }
    }
  },
  methods: {
    handleUpdateTypeIds () {
      const data = {
        ids: this.ids,
        typeId: this.typeId
      }
      updateTypeIds(data).then((res) => {
        if (res.success) {
          this.typeId = null
          this.onLoad(this.page)
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        this.typeVisible = false
      })
    },
    handleType () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return false
      }
      this.typeVisible = true
    },
    updateImg (val) {
      const params = {
        id: this.choose.id,
        img: val || ''
      }
      submit(params).then(() => {
        this.onLoad(this.page)
      })
    },
    handleRowClick (val) {
      console.log(this.showBtn(val))
      if (val) {
        this.choose = val
        this.choose.enabled = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  height: calc(100% - 10px);
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
  padding: 14px;
  background-color: #fff;
}

.goods-name {
  padding-right: 15px;
}

.search {
  margin-bottom: 15px;

  .search-name {
    margin-left: 15px;
  }
}
</style>
