export default {
  methods: {
    showBtn (row) {
      if (this.userInfo.level === 1) {
        return true
      }
      if (this.userInfo.level === 2) {
        return row.companyId === this.userInfo.companyId
      }
      if (this.userInfo.level === 3) {
        return row.marketId === this.userInfo.marketId
      }
      if (this.userInfo.level === 4 || this.userInfo.level === 5) {
        return row.merchantId === this.userInfo.merchantId
      }
    }
  }
}
