<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <div class="start">
          <el-button
              type="success"
              size="small"
              v-if="permission.mall_goods_add"
              @click="handleClone"> 导入
          </el-button>
        </div>
      </template>
      <template slot="merchantIdSearch">
        <el-select
            class="search-input"
            v-model="merchantId"
            filterable
            remote
            reserve-keyword
            placeholder="商户全称（支持拼音首字母）"
            :remote-method="filterMerchant"
            @change="refreshChange"
            :loading="loading">
          <el-option
              v-for="item in merchantList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </template>

      <template slot="existsFlag" slot-scope="scope" >
        <el-tag v-if="scope.row.existsFlag" type="success">已导入</el-tag>
        <el-tag v-else type="info">未导入 </el-tag>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getByName } from '@/api/crm/merchant'
import { clonePage, clone } from '@/api/mall/goods'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/goods',
      merchantList: [],
      form: {},
      search: {},
      merchantId: '',
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      loading: false,
      selectionList: [],
      option: {
        menu: false,
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        searchMenuSpan: 6,
        align: 'center',
        column: [
          {
            label: '选择商户',
            prop: 'merchantId',
            hide: true,
            searchSlot: true,
            search: true
          },
          {
            label: '已导入',
            prop: 'existsFlag',
            type: 'select',
            search: true,
            dicUrl: '/sys/dict/code/yes_no',
            slot: true
          },
          {
            label: '商品图片',
            prop: 'pic',
            type: 'img'
          },
          {
            label: '商品学名',
            prop: 'tradeName'
          },
          {
            label: '商品名称',
            prop: 'name'
          },
          {
            label: '商品单位',
            prop: 'unitId',
            type: 'select',
            dicUrl: '/mall/goodsunit/dict'
          },
          {
            label: '分类',
            prop: 'typeId',
            type: 'select',
            search: true,
            dicUrl: '/mall/goodstype/dict'
          },
          {
            label: '销售方式',
            prop: 'sellType',
            type: 'select',
            dicUrl: '/sys/dict/code/sell_type'
          },
          {
            label: '零售价',
            prop: 'retailPrice'
          },
          {
            label: '上下架',
            prop: 'status',
            type: 'select',
            dicUrl: '/sys/dict/code/goods_status'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_goods_add,
        viewBtn: this.permission.mall_goods_view,
        delBtn: this.permission.mall_goods_delete,
        editBtn: this.permission.mall_goods_edit
      }
    }
  },
  methods: {
    handleClone () {
      if (!this.merchantId) {
        this.$message.warning('请选择商户')
        return
      }
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据导入?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return clone(this.ids, this.merchantId)
        })
        .then(() => {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },
    onLoad (page) {
      if (!this.merchantId) {
        return
      }
      this.search.merchantId = this.merchantId
      clonePage(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    changeFlag () {
      if (!this.merchantId) {
        return
      }
      this.$refs.crud.refreshChange()
    },
    filterMerchant (query) {
      if (query !== '') {
        this.loading = true
        getByName(query).then(res => {
          this.loading = false
          this.merchantList = res.data
        })
      } else {
        this.merchantList = []
      }
    },
    toAdd () {
      this.$router.push({ path: '/mall/goodsedit' })
    },
    toEdit (row) {
      this.$router.push({
        path: '/mall/goodsedit',
        query: { id: row.id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.search-input {
  margin-right: 15px;
  width: 250px !important;
}
</style>
