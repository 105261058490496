<template>
  <basic-container>
    <el-collapse-transition>
      <el-form :inline="true" class="search-form">
        <el-form-item>
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="类型"
              v-model="page.type"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item search" type="primary" icon="el-icon-search" @click="handleFilter">搜索
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-transition>
    <el-row class="padding-row">
      <el-button type="primary" icon="el-icon-add" @click="handleCreate">新增</el-button>
    </el-row>
    <el-table
        class="good-table"
        :data="list"
        v-loading.body="listLoading"
        element-loading-text="拼命加载中"
        border
        fit
        highlight-current-row>
      <el-table-column label="主键" prop="id">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属者编号" prop="ownerId">
        <template slot-scope="scope">
          <span>{{ scope.row.ownerId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属者类型，参见字典表owner_type，1：联系人，2：企业，3：管理公司，4：市场，5：商户，6：摊位，7：合同" prop="ownerType">
        <template slot-scope="scope">
          <span>{{ scope.row.ownerType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="附件类型，参见字典表annex_type" prop="type">
        <template slot-scope="scope">
          <span>{{ scope.row.type }}</span>
        </template>
      </el-table-column>
      <el-table-column label="附件链接地址" prop="url">
        <template slot-scope="scope">
          <span>{{ scope.row.url }}</span>
        </template>
      </el-table-column>
      <el-table-column label="有效期起" prop="validFrom">
        <template slot-scope="scope">
          <span>{{ scope.row.validFrom }}</span>
        </template>
      </el-table-column>
      <el-table-column label="有效期止" prop="validTo">
        <template slot-scope="scope">
          <span>{{ scope.row.validTo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="删除标记" prop="isDeleted">
        <template slot-scope="scope">
          <span>{{ scope.row.isDeleted }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="handleUpdate(scope.row.id)">编辑</el-button>
          <el-button
v-if="scope.row.status!='deleted'"
size="small"
type="danger"
                     @click="handleDelete(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="!listLoading" class="end pagination-container">
      <el-pagination
:current-page.sync="page.currentPage"
background
                     @current-change="handleCurrentChange"
                     @size-change="handleSizeChange"
                     :page-sizes="[10,20,30, 50]"
                     :page-size="page.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
:total="total">
      </el-pagination>
    </div>
    <el-dialog title="附件信息" :visible.sync="showDialog">
      <el-row :gutter="20">
        <el-form
:model="form"
ref="form"
:rules="rules"
label-position="left"
                 label-width="120px"
class="small-space"
style='padding:20px 60px;'>
          <el-col :span="12">
            <el-form-item label="主键：" prop="id">
              <el-input v-model="form.id" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属者编号：" prop="ownerId">
              <el-input v-model="form.ownerId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属者类型，参见字典表owner_type，1：联系人，2：企业，3：管理公司，4：市场，5：商户，6：摊位，7：合同：" prop="ownerType">
              <el-input v-model="form.ownerType"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="附件类型，参见字典表annex_type：" prop="type">
              <el-input v-model="form.type"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="附件链接地址：" prop="url">
              <el-input v-model="form.url"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期起：" prop="validFrom">
              <el-input v-model="form.validFrom"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期止：" prop="validTo">
              <el-input v-model="form.validTo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="删除标记：" prop="isDeleted">
              <el-input v-model="form.isDeleted"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import { getList, remove, submit, getDetail } from '@/api/base/annex'

export default {
  data () {
    return {
      total: null,
      list: null,
      listLoading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      showDialog: false,
      form: {
        id: undefined,
        ownerId: undefined,
        ownerType: undefined,
        type: undefined,
        url: undefined,
        validFrom: undefined,
        validTo: undefined,
        isDeleted: undefined
      },
      rules: {
        ownerId: [{ required: true, trigger: 'blur', message: '请输入 所属者编号' }],
        ownerType: [{
          required: true,
          trigger: 'blur',
          message: '请输入 所属者类型，参见字典表owner_type，1：联系人，2：企业，3：管理公司，4：市场，5：商户，6：摊位，7：合同'
        }],
        type: [{ required: true, trigger: 'blur', message: '请输入 附件类型，参见字典表annex_type' }],
        url: [{ required: true, trigger: 'blur', message: '请输入 附件链接地址' }],
        validFrom: [{ required: true, trigger: 'blur', message: '请输入 有效期起' }],
        validTo: [{ required: true, trigger: 'blur', message: '请输入 有效期止' }],
        isDeleted: [{ required: true, trigger: 'blur', message: '请输入 删除标记' }]
      }
    }
  },
  created () {
    this.onLoad(this.page)
  },

  methods: {
    onLoad (page, params = {}) {
      this.listLoading = true
      getList(page.currentPage, page.pageSize, params).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
      })
    },
    handleFilter () {
      this.onLoad(this.page)
    },
    handleCreate () {
      this.showDialog = true
    },
    handleUpdate (id) {
      getDetail(id).then(res => {
        this.form = res.data
        this.showDialog = true
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '提示', {}).then(() => {
        remove(row.id).then(res => {
          if (res.success) {
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.onLoad(this.page)
          } else {
            this.$notify({
              title: '删除失败',
              message: res.data.message,
              type: 'error',
              duration: 2000
            })
          }
        })
      })
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保存
      this.$refs.form.validate(valid => {
        if (valid) {
          submit(this.form).then(res => {
            if (res.success) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.showDialog = false
              this.onLoad(this.page)
            } else {
              this.$notify({
                title: '操作失败',
                message: res.data.message,
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      })
    }
  }

}
</script>
