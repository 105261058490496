<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            icon="el-icon-plus"
            size="small"
            @click.stop="toAdd()"
        >新增
        </el-button>
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.crm_marketperson_delete"
                @click="handleDelete">删 除
        </el-button>
        <el-button type="success" @click.stop="handleBankExport()">导出</el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
      <template slot="search">
        <el-form label-width="85px" align="left" :inline="true">
          <el-form-item label="所属市场:">
            <good-select v-model="search.marketId" url="/crm/market/dict?type=1"></good-select>
          </el-form-item>
        </el-form>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import FileSaver from 'file-saver'
import { formatDate } from '@/util/date'
import { exportExcel } from '@/api/common'
import GoodSelect from '@/components/good-select/index'

export default {
  mixins: [mixin],
  components: { GoodSelect },
  data () {
    return {
      module: 'crm/marketperson',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      marketId: null,
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        searchMenuSpan: 5,
        align: 'center',
        column: [
          {
            label: '所属市场',
            prop: 'marketId',
            width: 200,
            type: 'select',
            dicUrl: '/crm/market/dict?type=1'
          },
          {
            label: '人员姓名',
            prop: 'name',
            search: true,
            width: 200
          },
          {
            label: '联系电话',
            prop: 'phone',
            search: true,
            width: 95
          },
          {
            label: '岗位',
            prop: 'postType',
            search: true,
            width: 95,
            type: 'select',
            dicUrl: '/sys/role/dict'
          },
          {
            label: '管理人员',
            prop: 'picUrl',
            type: 'img',
            width: 100
          },
          {
            label: '创建时间',
            prop: 'createTime',
            width: 150,
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            rules: [{
              required: true,
              message: '请输入修改时间'
            }]
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.marketId = this.$route.query.marketId
    this.search.marketId = this.$route.query.marketId
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_marketperson_add,
        viewBtn: this.permission.crm_marketperson_view,
        delBtn: this.permission.crm_marketperson_delete,
        editBtn: this.permission.crm_marketperson_edit
      }
    }
  },
  methods: {
    toAdd () {
      this.$router.push({
        path: '/crm/marketpersonEdit',
        query: { marketId: this.marketId }
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/crm/marketpersonEdit',
        query: { id: row.id }
      })
    },
    handleBankExport () {
      exportExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '市场人员信息' + formatDate(new Date(), 'yyyyMMddhhmmss') + '.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    }
  }
}
</script>
