<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            icon="el-icon-plus"
            size="small"
            @click.stop="toAdd()"
        >新增
        </el-button>
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.crm_annexMarketBank_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
      <template slot="search">
        <el-form label-width="85px" align="left" :inline="true">
          <el-form-item label="所属银行:">
            <good-select v-model="search.ownerId" url="/crm/bank/getDict?ownerType=1"></good-select>
          </el-form-item>
        </el-form>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select/index'

export default {
  mixins: [mixin],
  components: { GoodSelect },
  data () {
    return {
      module: 'crm/annexMarketBank',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      ownerId: null,
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        align: 'center',
        searchMenuSpan: 5,
        column: [
          {
            label: '所属银行',
            prop: 'ownerId',
            dicUrl: '/crm/bank/getDict?ownerType=1'
          },
          {
            label: '所属者类型',
            prop: 'ownerType',
            type: 'select',
            dicUrl: '/sys/dict/code/owner_type'
          },
          {
            label: '静态码类型',
            prop: 'type',
            type: 'select',
            dicUrl: '/crm/annexBank/getDict'
          },
          {
            label: '静态码',
            prop: 'url',
            type: 'img'
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.ownerId = this.$route.query.ownerId
    this.search.ownerId = this.$route.query.ownerId
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_annexMarketBank_add,
        viewBtn: this.permission.crm_annexMarketBank_view,
        delBtn: this.permission.crm_annexMarketBank_delete,
        editBtn: this.permission.crm_annexMarketBank_edit
      }
    }
  },
  methods: {
    toAdd () {
      this.$router.push({
        path: '/crm/annexMarketBankEdit',
        query: { ownerId: this.ownerId }
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/crm/annexMarketBankEdit',
        query: { id: row.id }
      })
    }
  }
}
</script>
