<template>
  <basic-container>
    <el-collapse-transition>
      <el-form :inline="true" class="search-form">
        <el-form-item>
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="类型"
              v-model="page.type"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item search" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-collapse-transition>
    <el-row class="padding-row">
      <el-button type="primary" icon="el-icon-add" @click="handleCreate">新增</el-button>
    </el-row>
    <el-table
        class="good-table"
        :data="list"
        v-loading.body="listLoading"
        element-loading-text="拼命加载中"
        border
        fit
        highlight-current-row>
          <el-table-column label="主键" prop="id">
            <template slot-scope="scope">
              <span>{{scope.row.id}}</span>
            </template>
          </el-table-column>
          <el-table-column label="上级id" prop="parentId">
            <template slot-scope="scope">
              <span>{{scope.row.parentId}}</span>
            </template>
          </el-table-column>
          <el-table-column label="批次号" prop="batchNo">
            <template slot-scope="scope">
              <span>{{scope.row.batchNo}}</span>
            </template>
          </el-table-column>
          <el-table-column label="批次日期" prop="batchDate">
            <template slot-scope="scope">
              <span>{{scope.row.batchDate}}</span>
            </template>
          </el-table-column>
          <el-table-column label="层级 0-根批次" prop="level">
            <template slot-scope="scope">
              <span>{{scope.row.level}}</span>
            </template>
          </el-table-column>
          <el-table-column label="设备类型：电子秤、电表、摄像头、小票打印机等" prop="type">
            <template slot-scope="scope">
              <span>{{scope.row.type}}</span>
            </template>
          </el-table-column>
          <el-table-column label="设备厂商或品牌（顶尖、智崎等，请参照数据字典scale_type）" prop="brand">
            <template slot-scope="scope">
              <span>{{scope.row.brand}}</span>
            </template>
          </el-table-column>
          <el-table-column label="型号" prop="model">
            <template slot-scope="scope">
              <span>{{scope.row.model}}</span>
            </template>
          </el-table-column>
          <el-table-column label="數量" prop="amount">
            <template slot-scope="scope">
              <span>{{scope.row.amount}}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注信息" prop="remark">
            <template slot-scope="scope">
              <span>{{scope.row.remark}}</span>
            </template>
          </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="handleUpdate(scope.row.id)">编辑</el-button>
          <el-button
v-if="scope.row.status!='deleted'"
size="small"
type="danger"
                     @click="handleDelete(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="!listLoading" class="end pagination-container">
      <el-pagination
:current-page.sync="page.currentPage"
background
                     @current-change="handleCurrentChange"
                     @size-change="handleSizeChange"
                     :page-sizes="[10,20,30, 50]"
                     :page-size="page.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
:total="total">
      </el-pagination>
    </div>
    <el-dialog title="采购订单" :visible.sync="showDialog">
      <el-row :gutter="20">
        <el-form
:model="form"
ref="form"
:rules="rules"
label-position="left"
                 label-width="120px"
class="small-space"
style='padding:20px 60px;'>
              <el-col :span="12">
                <el-form-item label="主键：" prop="id">
                  <el-input v-model="form.id"  disabled ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="上级id：" prop="parentId">
                  <el-input v-model="form.parentId" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="批次号：" prop="batchNo">
                  <el-input v-model="form.batchNo" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="批次日期：" prop="batchDate">
                  <el-input v-model="form.batchDate" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="层级 0-根批次：" prop="level">
                  <el-input v-model="form.level" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备类型：电子秤、电表、摄像头、小票打印机等：" prop="type">
                  <el-input v-model="form.type" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="设备厂商或品牌（顶尖、智崎等，请参照数据字典scale_type）：" prop="brand">
                  <el-input v-model="form.brand" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="型号：" prop="model">
                  <el-input v-model="form.model" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="數量：" prop="amount">
                  <el-input v-model="form.amount" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="备注信息：" prop="remark">
                  <el-input v-model="form.remark" ></el-input>
                </el-form-item>
              </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import { getList, remove, submit, getDetail } from '@/api/erp/devicepurchase'

export default {
  data () {
    return {
      total: null,
      list: null,
      listLoading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      showDialog: false,
      form: {
        id: undefined,
        parentId: undefined,
        batchNo: undefined,
        batchDate: undefined,
        level: undefined,
        type: undefined,
        brand: undefined,
        model: undefined,
        amount: undefined,
        remark: undefined
      },
      rules: {
        parentId: [{ required: true, trigger: 'blur', message: '请输入 上级id' }],
        batchNo: [{ required: true, trigger: 'blur', message: '请输入 批次号' }],
        batchDate: [{ required: true, trigger: 'blur', message: '请输入 批次日期' }],
        level: [{ required: true, trigger: 'blur', message: '请输入 层级 0-根批次' }],
        type: [{ required: true, trigger: 'blur', message: '请输入 设备类型：电子秤、电表、摄像头、小票打印机等' }],
        brand: [{ required: true, trigger: 'blur', message: '请输入 设备厂商或品牌（顶尖、智崎等，请参照数据字典scale_type）' }],
        model: [{ required: true, trigger: 'blur', message: '请输入 型号' }],
        amount: [{ required: true, trigger: 'blur', message: '请输入 數量' }],
        remark: [{ required: true, trigger: 'blur', message: '请输入 备注信息' }]
      }
    }
  },
  created () {
    this.onLoad(this.page)
  },

  methods: {
    onLoad (page, params = {}) {
      this.listLoading = true
      getList(page.currentPage, page.pageSize, params).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
      })
    },
    handleFilter () {
      this.onLoad(this.page)
    },
    handleCreate () {
      this.showDialog = true
    },
    handleUpdate (id) {
      getDetail(id).then(res => {
        this.form = res.data
        this.showDialog = true
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '提示', {}).then(() => {
        remove(row.id).then(res => {
          if (res.success) {
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.onLoad(this.page)
          } else {
            this.$notify({
              title: '删除失败',
              message: res.data.message,
              type: 'error',
              duration: 2000
            })
          }
        })
      })
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保存
      this.$refs.form.validate(valid => {
        if (valid) {
          submit(this.form).then(res => {
            if (res.success) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.showDialog = false
              this.onLoad(this.page)
            } else {
              this.$notify({
                title: '操作失败',
                message: res.data.message,
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      })
    }
  }

}
</script>
