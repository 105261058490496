<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="primary"
                icon="el-icon-plus"
                size="small"
                @click.stop="toAdd()"
        >新增
        </el-button>
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.mp_template_edit"
                @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
                type="text"
                icon="el-icon-del"
                size="small"
                @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mp/template',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '公众号ID',
            prop: 'appid',
            rules: [{
              required: true,
              message: '请输入公众号ID'
            }]
          },
          {
            label: '模板代码',
            prop: 'templateId',
            rules: [{
              required: true,
              message: '请输入模板代码'
            }]
          },
          {
            label: '模板标题',
            prop: 'title',
            rules: [{
              required: true,
              message: '请输入模板标题'
            }]
          },
          {
            label: '模板所属行业的一级行业',
            prop: 'primaryIndustry',
            rules: [{
              required: true,
              message: '请输入模板所属行业的一级行业'
            }]
          },
          {
            label: '模板所属行业的二级行业',
            prop: 'deputyIndustry',
            rules: [{
              required: true,
              message: '请输入模板所属行业的二级行业'
            }]
          },
          {
            label: '模板内容',
            prop: 'content',
            rules: [{
              required: true,
              message: '请输入模板内容'
            }]
          },
          {
            label: '内容示例',
            prop: 'example',
            rules: [{
              required: true,
              message: '请输入内容示例'
            }]
          },
          {
            label: '跳转小程序ID',
            prop: 'minaAppid',
            rules: [{
              required: true,
              message: '请输入跳转小程序ID'
            }]
          },
          {
            label: '跳转小程序页面',
            prop: 'pagePath',
            rules: [{
              required: true,
              message: '请输入跳转小程序页面'
            }]
          },
          {
            label: '跳转url',
            prop: 'url',
            rules: [{
              required: true,
              message: '请输入跳转url'
            }]
          },
          {
            label: '模板标签',
            prop: 'tag',
            rules: [{
              required: true,
              message: '请输入模板标签'
            }]
          },
          {
            label: '模板颜色',
            prop: 'color',
            rules: [{
              required: true,
              message: '请输入模板颜色'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mp_template_add,
        viewBtn: this.permission.mp_template_view,
        delBtn: this.permission.mp_template_delete,
        editBtn: this.permission.mp_template_edit
      }
    }
  },
  methods: {
    toAdd () {
      this.$router.push({
        path: '/mp/templateEdit'
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/mp/templateEdit',
        query: { id: row.id }
      })
    }
  }
}
</script>
