<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.crm_bizuser_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/bizuser',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '公司id',
            prop: 'companyId',
            rules: [{
              required: true,
              message: '请输入公司id'
            }]
          },
          {
            label: '市场id',
            prop: 'marketId',
            rules: [{
              required: true,
              message: '请输入市场id'
            }]
          },
          {
            label: '商户id',
            prop: 'merchantId',
            rules: [{
              required: true,
              message: '请输入商户id'
            }]
          },
          {
            label: 'device.id',
            prop: 'deviceId',
            rules: [{
              required: true,
              message: '请输入device.id'
            }]
          },
          {
            label: '1平 2公司3市场4商户',
            prop: 'level',
            rules: [{
              required: true,
              message: '请输入1平 2公司3市场4商户'
            }]
          },
          {
            label: '联系人编号',
            prop: 'linkmanId',
            rules: [{
              required: true,
              message: '请输入联系人编号'
            }]
          },
          {
            label: '用户级别，role_id',
            prop: 'roleId',
            rules: [{
              required: true,
              message: '请输入用户级别，role_id'
            }]
          },
          {
            label: 'sys_user.id',
            prop: 'userId',
            rules: [{
              required: true,
              message: '请输入sys_user.id'
            }]
          },
          {
            label: 'sys_user.mobile',
            prop: 'phone',
            rules: [{
              required: true,
              message: '请输入sys_user.mobile'
            }]
          },
          {
            label: '上次选中',
            prop: 'active',
            rules: [{
              required: true,
              message: '请输入上次选中'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_bizuser_add,
        viewBtn: this.permission.crm_bizuser_view,
        delBtn: this.permission.crm_bizuser_delete,
        editBtn: this.permission.crm_bizuser_edit
      }
    }
  },
  methods: {
  }
}
</script>
