<template>
  <div>
    <el-card class="good-card">
      <div>
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            :inline-message="true"
            label-position="right"
            label-width="150px"
            class="small-space">
          <el-row :gutter="20">
            <el-col :span="24">
              <span class="title"><i class="el-icon-s-home"></i>建筑信息</span>
              <el-divider></el-divider>
            </el-col>
            <el-col :span="8">
              <el-form-item label="楼宇：" prop="buildingId" style="padding-right: 40px">
                <good-select
                    ref="building"
                    allow-add
                    :item-add="buildingAdd"
                    v-model="form.buildingId"
                    url="/base/building/dict"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="楼层：" prop="floorId" style="padding-right: 40px">
                <good-select
                    ref="floor"
                    allow-add
                    :item-add="floorAdd"
                    v-model="form.floorId"
                    url="/base/floor/dict"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="区域：" prop="regionId" style="padding-right: 40px">
                <good-select
                    ref="region"
                    allow-add
                    :item-add="regionAdd"
                    v-model="form.regionId"
                    url="/base/region/dict"/>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <span class="title"><i class="el-icon-s-shop"></i>摊位信息</span>
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="8">
                <el-form-item label="所属公司：" prop="companyId">
                  <good-select :disabled="!!companyId" v-model="form.companyId" url="/crm/company/dict"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="所属市场：" prop="marketId">
                  <good-select :disabled="!!marketId" v-model="form.marketId" url="/crm/market/dict" />
                </el-form-item>
              </el-col>
            </el-col>
            <el-col :span="8">
              <el-form-item label="编号：" prop="sn">
                <el-input v-model="form.sn"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="名称：" prop="name">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="状态：" prop="buildingStatus">
                <good-select v-model="form.buildingStatus" url="/sys/dict/code/building_status"></good-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="照片：">
                <good-cropper
                    v-model="form.pics"
                    :showFileList="false"
                    :bucket="'zhsn-scale'"
                    :initData="form.pics"
                    :uploadClass="'upload-pic'"></good-cropper>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item align="center">
            <el-button type="primary" @click="save(true)" :loading="loading.addAg">保存</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>
        </el-form>
        <el-dialog :visible.sync="buildingFormVisible" width="30%">
          <el-form :model="buildingForm">
            <el-form-item label="楼宇:" label-width="80px">
              <el-input v-model="buildingForm.name"/>
            </el-form-item>
            <el-form-item/>
            <el-footer align="center">
              <el-button type="default" @click="buildingFormVisible=false">取消</el-button>
              <el-button type="primary" @click="addBuilding" :loading="loading.form">提交</el-button>
            </el-footer>
          </el-form>
        </el-dialog>
        <el-dialog :visible.sync="floorFormVisible" width="30%">
          <el-form :model="floorForm">
            <el-form-item label="楼层:" label-width="80px">
              <el-input v-model="floorForm.name"/>
            </el-form-item>
            <el-form-item/>
            <el-footer align="center">
              <el-button type="default" @click="floorFormVisible=false">取消</el-button>
              <el-button type="primary" @click="addFloor()" :loading="loading.form">提交</el-button>
            </el-footer>
          </el-form>
        </el-dialog>
        <el-dialog :visible.sync="regionFormVisible" width="30%">
          <el-form :model="regionForm">
            <el-form-item label="区域:" label-width="80px">
              <el-input v-model="regionForm.name"/>
            </el-form-item>
            <el-form-item/>
            <el-footer align="center">
              <el-button type="default" @click="regionFormVisible=false">取消</el-button>
              <el-button type="primary" @click="addRegion()" :loading="loading.form">提交</el-button>
            </el-footer>
          </el-form>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select'
import GoodCropper from '@/components/good-cropper/index'
import { getDetail, submit, submitBuilding, submitFloor, submitRegion } from '@/api/base/booth'
import { getDetail as getMarket } from '@/api/crm/market'

export default {
  mixins: [mixin],
  components: { GoodSelect, GoodCropper },
  data () {
    return {
      loading: {
        form: false,
        subForm: false
      },
      companyId: null,
      marketId: null,
      buildingFormVisible: false,
      floorFormVisible: false,
      regionFormVisible: false,
      form: {
        buildingId: null,
        floorId: null,
        regionId: null,
        name: null,
        serialNumber: null,
        buildingStatus: 1,
        leaseStatus: 0,
        pics: null
      },
      buildingForm: {},
      floorForm: {},
      regionForm: {},
      rules: {
        companyId: [{ required: true, trigger: 'blur', message: '请选择所属公司' }],
        marketId: [{ required: true, trigger: 'blur', message: '请选择所属商户' }],
        sn: [{ required: true, trigger: 'blur', message: '请输入摊位编号' }]
      }
    }
  },
  created () {
    if (JSON.parse(localStorage.userInfo).companyId) {
      this.companyId = JSON.parse(localStorage.userInfo).companyId
      this.form.companyId = this.companyId
    }
    if (JSON.parse(localStorage.userInfo).marketId) {
      this.marketId = JSON.parse(localStorage.userInfo).marketId
      this.form.marketId = this.marketId
      if (!this.companyId) {
        this.getMarket()
      }
    }
    this.form.regionId = this.$route.query.regionId
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.base_booth_add,
        viewBtn: this.permission.base_booth_view,
        delBtn: this.permission.base_booth_delete,
        editBtn: this.permission.base_booth_edit
      }
    }
  },
  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    getMarket () {
      if (!this.marketId) {
        return
      }
      getMarket(this.marketId).then(res => {
        if (res.success && res.data) {
          this.companyId = res.data.companyId
          this.form.companyId = res.data.companyId
        }
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          submit(this.form).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            } else {
              this.$message({
                type: 'false',
                message: res.msg
              })
            }
            this.loading.add = false
            if (res.code === 200) {
              this.form = {
                status: 1
              }
              this.$router.push({ path: '/base/booth' })
            }
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/base/booth' })
    },
    buildingAdd () {
      this.buildingFormVisible = true
    },
    addBuilding () {
      submitBuilding(this.buildingForm).then((res) => {
        if (res.success && res.data) {
          this.$refs.building.refresh()
          this.form.buildingId = res.data
        }
        this.buildingFormVisible = false
      })
    },
    floorAdd () {
      this.floorFormVisible = true
    },
    addFloor () {
      submitFloor(this.floorForm).then((res) => {
        if (res.success && res.data) {
          this.$refs.floor.refresh()
          this.form.floorId = res.data
        }
        this.floorFormVisible = false
      })
    },
    regionAdd () {
      this.regionFormVisible = true
    },
    addRegion () {
      submitRegion(this.regionForm).then((res) => {
        if (res.success && res.data) {
          this.$refs.region.refresh()
          this.form.regionId = res.data
        }
        this.regionFormVisible = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  i {
    margin-right: 15px;
  }

  font-size: 24px;
}
</style>
