<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            icon="el-icon-plus"
            size="small"
            @click.stop="toAdd()"
        >新增
        </el-button>
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.crm_operator_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            @click.stop="$refs.crud.rowDel(row)">删除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/operator',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '企业id',
            prop: 'enterpriseId',
            rules: [{
              required: true,
              message: '请输入企业id'
            }]
          },
          {
            label: '所属市场id',
            prop: 'estateId',
            rules: [{
              required: true,
              message: '请输入所属市场id'
            }]
          },
          {
            label: '卫生许可证编号',
            prop: 'hygieneLicense',
            rules: [{
              required: true,
              message: '请输入卫生许可证编号'
            }]
          },
          {
            label: '所属区域',
            prop: 'areaId',
            rules: [{
              required: true,
              message: '请输入所属区域'
            }]
          },
          {
            label: '备案时间',
            prop: 'applyTime',
            rules: [{
              required: true,
              message: '请输入备案时间'
            }]
          },
          {
            label: '状态，0：草稿，1：待审核，2：审核通过，3：审核拒绝',
            prop: 'status',
            rules: [{
              required: true,
              message: '请输入状态，0：草稿，1：待审核，2：审核通过，3：审核拒绝'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_operator_add,
        viewBtn: this.permission.crm_operator_view,
        delBtn: this.permission.crm_operator_delete,
        editBtn: this.permission.crm_operator_edit
      }
    }
  },
  methods: {
    toAdd () {
      this.$router.push({ path: '/crm/operatorEdit' })
    },
    toEdit (row) {
      this.$router.push({
        path: '/mall/operatorEdit',
        query: { id: row.id }
      })
    }
  }
}
</script>
