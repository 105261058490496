<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.mall_sku_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/sku',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '商品',
            prop: 'goodsId',
            rules: [{
              required: true,
              message: '请输入商品'
            }]
          },
          {
            label: 'sku编码',
            prop: 'skuCode',
            rules: [{
              required: true,
              message: '请输入sku编码'
            }]
          },
          {
            label: '进货价',
            prop: 'purchasePrice',
            rules: [{
              required: true,
              message: '请输入进货价'
            }]
          },
          {
            label: '零售价',
            prop: 'retailPrice',
            rules: [{
              required: true,
              message: '请输入零售价'
            }]
          },
          {
            label: '最低零售价',
            prop: 'minRetailPrice',
            rules: [{
              required: true,
              message: '请输入最低零售价'
            }]
          },
          {
            label: '展示图片',
            prop: 'pic',
            rules: [{
              required: true,
              message: '请输入展示图片'
            }]
          },
          {
            label: '销量',
            prop: 'sale',
            rules: [{
              required: true,
              message: '请输入销量'
            }]
          },
          {
            label: '库存',
            prop: 'stock',
            rules: [{
              required: true,
              message: '请输入库存'
            }]
          },
          {
            label: '预警库存',
            prop: 'lowStock',
            rules: [{
              required: true,
              message: '请输入预警库存'
            }]
          },
          {
            label: '锁定库存',
            prop: 'lockStock',
            rules: [{
              required: true,
              message: '请输入锁定库存'
            }]
          },
          {
            label: '销售属性List',
            prop: 'spData',
            rules: [{
              required: true,
              message: '请输入销售属性List'
            }]
          },
          {
            label: '销售属性map',
            prop: 'spMap',
            rules: [{
              required: true,
              message: '请输入销售属性map'
            }]
          },
          {
            label: '规格统称',
            prop: 'sp',
            rules: [{
              required: true,
              message: '请输入规格统称'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_sku_add,
        viewBtn: this.permission.mall_sku_view,
        delBtn: this.permission.mall_sku_delete,
        editBtn: this.permission.mall_sku_edit
      }
    }
  },
  methods: {
  }
}
</script>
