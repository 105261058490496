<template>
  <div>
    <el-card class="good-card">
      <div>
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            :inline-message="true"
            label-position="right"
            label-width="150px"
            class="small-space form-one">
          <el-form-item label="所属市场：" prop="marketId">
            <good-select v-model="form.marketId" url="/crm/market/dict?type=1"></good-select>
          </el-form-item>
          <el-form-item label="人员姓名：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="管理人员岗位：" prop="postType">
            <good-select v-model="form.postType" url="/sys/role/getManagerDict"></good-select>
          </el-form-item>
          <el-form-item label="管理人证件照：" prop="picUrl">
            <good-cropper
                v-model="form.picUrl"
                :showFileList="false"
                :private="true"
                :bucket="'zhsn-scale-private'"
                :prefix="'market'"
                :initData="form.picUrl"
                :uploadClass="'upload-pic'"></good-cropper>
          </el-form-item>
          <el-form-item label="年龄：" prop="age">
            <el-input v-model="form.age"></el-input>
          </el-form-item>
          <el-form-item label="性别：" prop="sex">
            <good-select v-model="form.sex" url="/sys/dict/code/gender"></good-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="save(true)" :loading="loading.addAg">保存并提交</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select/index'
import GoodCropper from '@/components/good-cropper/index'
import { getDetail, submit } from '@/api/crm/marketperson'

export default {
  mixins: [mixin],
  components: { GoodSelect, GoodCropper },
  data () {
    return {
      loading: {
        form: false
      },
      form: {
        marketId: undefined,
        name: undefined,
        phone: undefined,
        postType: undefined,
        picUrl: undefined,
        age: undefined,
        sex: undefined,
        status: undefined
      },
      rules: {
        marketId: [{ required: true, trigger: 'blur', message: '请选择市场' }],
        name: [{ required: true, trigger: 'blur', message: '请输入管理人员姓名' }],
        phone: [{ required: true, trigger: 'blur', message: '请输入管理人员电话' }],
        postType: [{ required: true, trigger: 'blur', message: '请选择岗位' }],
        picUrl: [{ required: false, trigger: 'blur', message: '请上传管理人员照片' }],
        age: [{ required: true, trigger: 'blur', message: '请填写年龄' }],
        sex: [{ required: true, trigger: 'blur', message: '请选择性别' }]
      }
    }
  },
  created () {
    this.form.marketId = this.$route.query.marketId
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_marketperson_add,
        viewBtn: this.permission.crm_marketperson_view,
        delBtn: this.permission.crm_marketperson_delete,
        editBtn: this.permission.crm_marketperson_edit
      }
    }
  },
  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          submit(this.form).then(() => {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.form = {
              status: 1
            }
            this.loading.add = false
            this.$router.push({ path: '/crm/marketperson' })
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/crm/marketperson' })
    }
  }
}
</script>
