<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <div class="start">
          <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              v-if="permission.base_linkman_add"
              @click.stop="toAdd()"
          >新增
          </el-button>
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.base_linkman_delete"
            @click="handleDelete">删 除
        </el-button>
        </div>
      </template>
      <template slot="search">
        <el-form align="left" :inline="true">
          <el-form-item label="关键字:">
            <el-input placeholder="姓名、联系方式等" v-model="search.query"></el-input>
          </el-form-item>
        </el-form>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getList } from '@/api/common'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'base/linkman',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        editBtn: false,
        addBtn: false,
        selection: true,
        align: 'center',
        column: [
          {
            label: '姓名',
            prop: 'name'
          },
          {
            label: '联系方式',
            prop: 'mobile',
            width: 150
          },
          {
            label: '身份证号',
            prop: 'idCardNo',
            width: 200
          },
          {
            label: '性别',
            prop: 'gender',
            type: 'radio',
            dicUrl: 'sys/dict/code/gender'
          },
          {
            label: '年龄',
            prop: 'age',
            type: 'number'
          },
          {
            label: '所属企业',
            prop: 'relations',
            width: 200
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.base_linkman_add,
        viewBtn: this.permission.base_linkman_view,
        delBtn: this.permission.base_linkman_delete,
        editBtn: this.permission.base_linkman_edit
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.onLoad(this.page)
    }
  },
  created () {
    if (this.$route.query.ownerType) {
      const ownerType = this.$route.query.ownerType
      let level
      let label
      if (ownerType === 'COMPANY') {
        level = 2
        label = '公司'
      } else if (ownerType === 'MARKET') {
        level = 3
        label = '市场'
      } else if (ownerType === 'MERCHANT') {
        level = 4
        label = '商户'
      } else if (ownerType === 'DEVICE') {
        level = 5
        label = '设备'
      }
      this.search.level = level
      const columns = this.option.column
      const column = columns[columns.length - 1]
      column.label = label
      this.onLoad(this.page)
    }
  },
  methods: {
    onLoad (page) {
      if (this.$route.query.ownerType) {
        this.search.ownerType = this.$route.query.ownerType
      }
      getList(this.module, page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    toAdd () {
      this.$router.push({
        path: '/base/linkmanedit'
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/base/linkmanedit',
        query: { id: row.id }
      })
    }
  }
}
</script>
