<template>
  <div>
    <el-card class="good-card">
      <div>
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            :inline-message="true"
            label-position="right"
            label-width="150px"
            class="small-space form-one">
          <el-form-item label="分类名称：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-divider v-if="showDivider" @click="showDivider=!showDivider">更多<i class="el-icon-arrow-down"></i>
          </el-divider>
          <div v-if="!showDivider">
            <el-form-item label="父分类：" prop="parentId">
              <good-select-tree v-model="form.parentId" url="/erp/devicetype/tree" :props="typeProps"></good-select-tree>
            </el-form-item>
            <el-form-item label="外接设备：" prop="isAuxiliary">
              <el-radio-group v-model="form.isAuxiliary">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="类型描述：" prop="description">
              <el-input v-model="form.description"></el-input>
            </el-form-item>
            <el-form-item label="设备类型图片：" prop="icon">
              <good-cropper
                  v-model="form.icon"
                  :showFileList="false"
                  :bucket="'zhsn-scale'"
                  :initData="form.icon"
                  :uploadClass="'upload-pic'"></good-cropper>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
              <el-input v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item label="排序：" prop="sort">
              <el-input v-model="form.sort"></el-input>
            </el-form-item>
          </div>
          <el-divider v-if="!showDivider" @click="showDivider=!showDivider">收起<i class="el-icon-arrow-up"></i>
          </el-divider>
          <el-form-item>
            <el-button type="primary" @click="save()">保存并提交</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import GoodSelectTree from '@/components/good-select-tree/index'
import GoodCropper from '@/components/good-cropper/index'
import { submit, getDetail } from '@/api/erp/devicetype'

export default {
  components: { GoodCropper, GoodSelectTree },
  props: {
    handleSubmit: {
      type: Function
    }
  },
  data () {
    return {
      listLoading: true,
      typeProps: {
        id: 'id',
        label: 'name',
        childList: 'children'
      },
      showDialog: false,
      form: {
        id: undefined,
        parentId: undefined,
        name: undefined,
        icon: undefined,
        description: undefined,
        sort: undefined,
        isAuxiliary: 0,
        remark: undefined
      },
      rules: {
        name: [{ required: true, trigger: 'blur', message: '请输入分类名称' }],
        isAuxiliary: [{ required: true, trigger: 'blur', message: '请选择是否为外接设备' }]
      },
      showDivider: true
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },

  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          submit(this.form).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.$emit('type-cancel')
            } else {
              this.$message({
                type: 'false',
                message: res.msg
              })
            }
            if (res.code === 200) {
              this.form = {
                isAuxiliary: 0
              }
            }
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$emit('type-cancel')
    }
  }

}
</script>
