<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <div class="start">
          <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              @click.stop="toAdd()"
          >新增
          </el-button>
          <el-button
              type="danger"
              size="small"
              icon="el-icon-delete"
              plain
              v-if="permission.mall_goods_delete"
              @click="handleDelete">删 除
          </el-button>
          <el-button type="primary" @click.stop="handleModel('商品导入模板')">模板下载</el-button>
          <el-upload
              class="filter-item"
              :show-file-list="false"
              :action="handleImport()"
              :headers="uploadHeaders()"
              :on-success="uploadSuc">
            <el-button type="warning">导入</el-button>
          </el-upload>
        </div>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            @click.stop="$refs.crud.rowDel(row)">删除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/goods',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        searchIndex: 1,
        searchIcon: true,
        searchShowBtn: false,
        align: 'center',
        column: [
          {
            label: '商品图片',
            prop: 'pic',
            type: 'img'
          },
          {
            label: '商品学名',
            prop: 'tradeName',
            search: true
          },
          {
            label: '商品名称',
            prop: 'name'
          },
          {
            label: '商品单位',
            prop: 'unitId',
            type: 'select',
            dicUrl: '/mall/goodsunit/dict'
          },
          {
            label: '分类',
            prop: 'typeId',
            type: 'select',
            search: true,
            dicUrl: '/mall/goodstype/dict'
          },
          {
            label: '销售方式',
            prop: 'sellType',
            type: 'select',
            search: true,
            dicUrl: '/sys/dict/code/sell_type'
          },
          {
            label: '零售价',
            prop: 'retailPrice'
          },
          {
            label: '上下架',
            prop: 'status',
            type: 'select',
            search: true,
            dicUrl: '/sys/dict/code/goods_status'
          },
          {
            label: '热门',
            prop: 'hotFlag',
            type: 'select',
            search: true,
            dicUrl: '/sys/dict/code/hot_flag'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_goods_add,
        viewBtn: this.permission.mall_goods_view,
        delBtn: this.permission.mall_goods_delete,
        editBtn: this.permission.mall_goods_edit
      }
    }
  },
  methods: {
    toAdd () {
      this.$router.push({ path: '/mall/goodsedit' })
    },
    toEdit (row) {
      this.$router.push({
        path: '/mall/goodsedit',
        query: { id: row.id }
      })
    }
  }
}
</script>
