<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <div class="start">
          <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              @click.stop="toAdd()"
          >新增
          </el-button>
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.erp_stockout_delete"
                @click="handleDelete">删 除
        </el-button>
        </div>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'erp/stockout',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        align: 'center',
        column: [
          {
            label: '市场',
            prop: 'marketId',
            width: 200,
            type: 'select',
            select: true,
            search: true,
            dicUrl: '/crm/market/dict?type=1'
          },
          {
            label: '入库批次',
            prop: 'batch',
            width: 200,
            type: 'select',
            dicUrl: '/erp/stockin/getDict'
          },
          {
            label: '设备类型',
            prop: 'type',
            type: 'select',
            select: true,
            search: true,
            dicUrl: '/sys/dict/code/device_type'
          },
          {
            label: '品牌',
            prop: 'brand',
            type: 'select',
            select: true,
            search: true,
            dicUrl: '/sys/dict/code/scale_type'
          },
          {
            label: '型号',
            prop: 'brand',
            type: 'select',
            search: true,
            select: true,
            dicUrl: '/sys/dict/code/scale_type'
          },
          {
            label: '入库数量',
            prop: 'stock'
          },
          {
            label: '出库时间',
            prop: 'buyTime'
          },
          {
            label: '设备图片',
            prop: 'pic',
            type: 'img'
          },
          {
            label: '出库批次信息',
            prop: 'remark'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.erp_stockout_add,
        viewBtn: this.permission.erp_stockout_view,
        delBtn: this.permission.erp_stockout_delete,
        editBtn: this.permission.erp_stockout_edit
      }
    }
  },
  methods: {
    toAdd () {
      this.$router.push({ path: '/erp/stockoutEdit' })
    },
    toEdit (row) {
      this.$router.push({
        path: '/erp/stockoutEdit',
        query: { id: row.id }
      })
    }
  }
}
</script>
