<template>
  <div>
    <el-card class="good-card">
      <div slot="header">
        <span>基础信息</span>
      </div>
      <div>
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            :inline-message="true"
            label-position="right"
            label-width="150px"
            class="small-space form-one">
          <el-form-item label="销售方式：" prop="sellType">
            <good-radio v-model="form.sellType" url="/sys/dict/code/sell_type"></good-radio>
          </el-form-item>
          <el-form-item label="商品学名：" prop="tradeName">
            <el-select
                v-model="form.tradeName"
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="filterName"
                :loading="loadingNames">
              <el-option
                  v-for="item in names"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="展示名：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="规格说明：" prop="hotkeys">
            <el-input v-model="form.spManual"></el-input>
          </el-form-item>
          <el-form-item label="商品分类：" prop="typeId">
            <good-select v-model="form.typeId" url="/mall/goodstype/dict" popperClass="good-popper"></good-select>
          </el-form-item>
          <el-form-item label="单位：" prop="unitId">
            <good-select :disabled="unitDisable" v-model="form.unitId" url="/mall/goodsunit/dict"></good-select>
          </el-form-item>
          <el-form-item label="商品图片：" prop="pic">
            <div class="start top">
              <good-cropper
                  v-model="form.pic"
                  :showFileList="false"
                  :bucket="'zhsn-scale'"
                  :initData="form.pic"
                  :uploadClass="'upload-pic'"></good-cropper>
              <el-button type="primary" class="choose-img" v-if="form.tradeName" @click="showImg()">图库中选择</el-button>
            </div>
          </el-form-item>
          <el-form-item label="时价商品：" prop="currentPrice">
            <el-radio-group v-model="form.currentPrice">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="热门商品：" prop="hotFlag">
            <el-radio-group v-model="form.hotFlag">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="状态：" prop="status">
            <el-radio-group v-model="form.status">
              <el-radio :label="1">上架</el-radio>
              <el-radio :label="0">下架</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="进货价：" prop="purchasePrice">
            <el-input-number
                class="input-number"
                v-model="form.purchasePrice"
                :precision="2"
                :step="0.1"></el-input-number>
          </el-form-item>
          <el-form-item label="零售价：" prop="retailPrice">
            <el-input-number
                class="input-number"
                v-model="form.retailPrice"
                :precision="2"
                :step="0.1"></el-input-number>
          </el-form-item>
          <el-form-item label="最低零售价：" prop="minRetailPrice">
            <el-input-number
                class="input-number"
                v-model="form.minRetailPrice"
                :precision="2"
                :step="0.1"></el-input-number>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="save(false)" :loading="loading.add">保存</el-button>
            <el-button type="primary" @click="save(true)" :loading="loading.addAg">保存并新增</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-dialog
        title="选择图片"
        width="965px"
        :visible.sync="imgVisible">
      <div class="start wrap">
        <div v-for="img in imgList" :key="img" class="img-box" @click="chooseImg(img)"><img :src="img" alt=""></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select/index'
import GoodRadio from '@/components/good-radio/index'
import { getDetail, submit } from '@/api/mall/goods'
import { getImg, getNames } from '@/api/mall/goodsname'
import GoodCropper from '@/components/good-cropper/index'

export default {
  mixins: [mixin],
  components: { GoodSelect, GoodRadio, GoodCropper },
  data () {
    return {
      module: 'mall/goods',
      loading: {
        add: false,
        addAg: false
      },
      loadingNames: false,
      names: [],
      imgList: [],
      imgVisible: false,
      form: {
        sellType: 1,
        name: undefined,
        spManual: undefined,
        barCode: undefined,
        tradeName: undefined,
        pic: undefined,
        hotkeys: undefined,
        typeId: undefined,
        unitId: '1',
        brandId: undefined,
        remarks: undefined,
        purchasePrice: undefined,
        retailPrice: 0,
        minRetailPrice: undefined,
        currentPrice: 1,
        hotFlag: 0,
        status: 1
      },
      rules: {
        sellType: [{ required: true, trigger: 'blur', message: '请选择销售方式' }],
        typeId: [{ required: true, trigger: 'blur', message: '请选择商品分类' }],
        unitId: [{ required: true, trigger: 'blur', message: '请选择商品单位' }],
        name: [{ required: true, trigger: 'blur', message: '请输入商品名称' }],
        tradeName: [{ required: true, trigger: 'blur', message: '请输入商品学名' }],
        // pic: [{ required: true, trigger: 'blur', message: '请上传商品图片' }],
        retailPrice: [{ required: true, trigger: 'blur', message: '请输入零售价' }]
      },
      unitDisable: true
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  watch: {
    'form.sellType' (val) {
      if (val === 1) {
        this.form.unitId = '1'
        this.unitDisable = true
      } else {
        this.form.unitId = '0'
        this.unitDisable = false
      }
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_goods_add,
        viewBtn: this.permission.mall_goods_view,
        delBtn: this.permission.mall_goods_delete,
        editBtn: this.permission.mall_goods_edit
      }
    }
  },
  methods: {
    showImg () {
      // 获取图片列表
      if (!this.form.tradeName) {
        this.$message({
          type: 'error',
          message: '请选择商品学名!'
        })
        return
      }
      getImg(this.form.tradeName).then(res => {
        if (res.success) {
          this.imgList = res.data.split(',')
          this.imgVisible = true
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    chooseImg (pic) {
      this.form.pic = pic
      this.imgVisible = false
    },
    filterName (query) {
      if (query !== '') {
        this.loadingNames = true
        getNames(query).then(res => {
          this.loadingNames = false
          this.names = res.data
        })
      } else {
        this.names = []
      }
    },
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    save (ag) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (ag) {
            this.loading.addAg = true
          } else {
            this.loading.add = true
          }

          submit(this.form).then((res) => {
            if (!res.success) {
              this.$message({
                type: 'error',
                message: res.msg
              })
              return
            }
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.form = {
              sellType: 1,
              typeId: '0',
              unitId: '1',
              currentPrice: 1,
              status: 1
            }
            if (ag) {
              this.loading.addAg = false
            } else {
              this.loading.add = false
              this.$router.push({ path: '/mall/goods' })
            }
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/mall/goods' })
    }
  }
}
</script>

<style lang="scss">
.choose-img {
  margin-left: 15px;
}

.img-box {
  width: 200px;
  height: 200px;
  margin: 15px;
  border: 2px solid #eee;

  img {
    height: 100%;
  }
}
</style>
