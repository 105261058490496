<template>
  <div>
    <el-upload
        action="#"
        :multiple="multiple"
        :class="uploadClass"
        :path="path"
        :headers="headers"
        :data="data"
        :name="name"
        :drag="drag"
        :accept="accept"
        :disabled="disabled"
        :limit="limit"
        :with-credentials="withCredentials"
        :show-file-list="showFileList"
        :model="value"
        :list-type="isPdf?'text':'picture-card'"
        :private="false"
        :auto-upload="false"
        :on-change='handleChange'
        :on-success='handleSuccess'
        :file-list="uploadList"
        :on-preview="handlePictureCardPreview"
        :on-exceed="handleExceed"
        :on-remove="handleRemove">
      <img v-if="imageUrl" :src="imageUrl" class="img">
      <span v-else-if="isPdf">{{ pdfName }}</span>
      <i v-else class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="imgVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog title="图片剪裁" :visible.sync="dialogVisible" append-to-body>
      <div class="cropper-content">
        <div class="cropper">
          <vueCropper
              ref="cropper"
              :img="img"
              :outputSize="cropperOption.size"
              :outputType="cropperOption.outputType"
              :info="true"
              :full="cropperOption.full"
              :canMove="cropperOption.canMove"
              :canMoveBox="cropperOption.canMoveBox"
              :original="cropperOption.original"
              :autoCrop="cropperOption.autoCrop"
              :fixed="cropperOption.fixed"
              :fixedNumber="fixedNumber"
              :centerBox="cropperOption.centerBox"
              :infoTrue="cropperOption.infoTrue"
              :fixedBox="cropperOption.fixedBox"
              :autoCropWidth="autoCropWidth"
              :autoCropHeight="autoCropHeight"
          ></vueCropper>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="finish">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="pdfVisible" class="dialog-pdf">
      <div class="onlineHelp cg-box">
        <div class="tools">
          <div class="page">第 {{ pageNum }} /{{ pageTotalNum }}页</div>
          <el-input v-model.number="goPageNum" style="width: 70px;margin-right: 8px"/>
          <el-button type="success" @click.stop="goPage"> 前往</el-button>
          <el-button type="primary" @click.stop="prePage"> 上一页</el-button>
          <el-button type="primary" @click.stop="nextPage"> 下一页</el-button>
          <el-button type="primary" @click.stop="clock"> 顺时针</el-button>
          <el-button type="primary" @click.stop="counterClock"> 逆时针</el-button>
        </div>
        <div class="pdf-box">
          <pdf
              ref="pdf"
              :src="pdfUrl"
              :page="pageNum"
              :rotate="pageRotate"
              @progress="loadedRatio = $event"
              @page-loaded="pageLoaded($event)"
              @num-pages="pageTotalNum=$event"
              @error="pdfError($event)"
              @link-clicked="page = $event">
          </pdf>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="pdfVisible = false">取 消</el-button>
        <el-button type="primary" @click="pdfFinish">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { VueCropper } from 'vue-cropper'
import { getLinkPrivate, policy } from '@/api/oss'
import { formatDate } from '@/util/date'
import axios from 'axios'
import pdf from 'vue-pdf'

export default {
  components: {
    VueCropper,
    pdf
  },
  name: 'Cropper',
  props: {
    initData: {
      type: String,
      default: ''
    },
    bucket: {
      type: String,
      default: 'file'
    },
    prefix: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    uploadClass: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    drag: {
      type: Boolean,
      default: false
    },
    showFileList: {
      type: Boolean,
      default: false
    },
    autoUpload: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 10
    },
    accept: {
      type: String,
      default: ''
    },
    listType: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'file'
    },
    data: {
      type: Object,
      default: () => {
      }
    },
    headers: {
      type: Object,
      default: () => {
      }
    },
    withCredentials: {
      type: Boolean,
      default: false
    },
    path: {
      type: String,
      default: () => {
        return '/' + formatDate(new Date(), 'yyyyMMdd')
      }
    },
    fixedNumber: {
      type: Array,
      default: () => [5, 5]
    },
    private: {
      type: Boolean,
      default: false
    },
    cropperOption: {
      type: Object,
      default: () => {
        return {
          img: '', // 裁剪图片的地址
          info: true, // 裁剪框的大小信息
          outputSize: 1, // 裁剪生成图片的质量
          outputType: 'png', // 裁剪生成图片的格式
          canScale: false, // 图片是否允许滚轮缩放
          autoCrop: true, // 是否默认生成截图框
          fixedBox: false, // 固定截图框大小 不允许改变
          fixed: false, // 是否开启截图框宽高固定比例
          full: true, // 是否输出原图比例的截图
          canMoveBox: false, // 截图框能否拖动
          original: false, // 上传图片按照原始比例渲染
          centerBox: true, // 截图框是否被限制在图片里面
          infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
          size: 1,
          canMove: true,
          high: false,
          cropData: {},
          enlarge: 1,
          mode: 'contain',
          maxImgSize: 2000,
          limit: 10
        }
      }
    }
  },
  data () {
    return {
      img: '',
      fileInfo: undefined,
      fileIndex: undefined,
      autoCropWidth: 200,
      autoCropHeight: 200,
      imageUrl: '',
      nativeList: [],
      uploadList: [],
      dialogImageUrl: '',
      imgVisible: false,
      dialogVisible: false,
      isPdf: false,
      pdfVisible: false,
      pdfUrl: undefined,
      pdfName: '上传PDF',
      pageNum: 1,
      pageTotalNum: 1,
      // 加载进度
      loadedRatio: 0,
      curPageNum: 0,
      goPageNum: 1,
      pageRotate: 0
    }
  },
  created () {
    this.loadInitDate()
  },
  watch: {
    nativeList: {
      handler: function () {
        if (!this.showFileList) {
          if (this.isPdf) {
            this.pdfUrl = this.getValues()
          } else {
            this.imageUrl = this.getValues()
          }
        }
        this.$emit('input', this.getValues())
      },
      deep: true
    },
    initData () {
      this.loadInitDate()
    }
  },
  methods: {
    // 上一页函数，
    prePage () {
      let page = this.pageNum
      page = page > 1 ? page - 1 : this.pageTotalNum
      this.pageNum = page
    },
    // 下一页函数
    nextPage () {
      let page = this.pageNum
      page = page < this.pageTotalNum ? page + 1 : 1
      this.pageNum = page
    },
    // 前往页数
    goPage () {
      if (!this.goPageNum || /\D/.test(this.goPageNum) || this.goPageNum < 1 || this.goPageNum > this.pageTotalNum) {
        this.$message.warning('输入页码有误')
        return
      }
      this.pageNum = this.goPageNum
    },
    // 页面顺时针翻转90度。
    clock () {
      this.pageRotate += 90
    },
    // 页面逆时针翻转90度。
    counterClock () {
      this.pageRotate -= 90
    },
    // 页面加载回调函数，其中e为当前页数
    pageLoaded (e) {
      this.curPageNum = e
    },
    // 其他的一些回调函数。
    pdfError (error) {
      console.error('error', error)
    },
    loadInitDate () {
      if (this.initData) {
        const nativeList = []
        if (this.showFileList) {
          const relativePathList = this.initData.split(',')
          relativePathList.forEach(item => {
            nativeList.push({
              url: item,
              suc: true
            })
          })
          this.nativeList = nativeList
        } else {
          this.imageUrl = this.value
        }
      } else {
        this.nativeList = []
      }
    },
    getValues () {
      this.uploadList = this.nativeList.filter(file => file.suc === true)
      if (!this.showFileList && this.uploadList && this.uploadList.length > 0) {
        return this.uploadList[this.uploadList.length - 1].url
      }
      const list = []
      this.uploadList.forEach(file => {
        list.push(file.url)
      })
      let val = ''
      if (list.length > 0) {
        val = list.join(',')
      }
      return val
    },
    handleRemove (file, fileList) {
      this.nativeList = fileList
      this.$emit('updateList', this.getValues())
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.imgVisible = true
    },
    handleExceed (files, fileList) {
      this.nativeList = fileList
      this.$emit('handleExceed', files, fileList)
    },
    handleSuccess (res, files, fileList) {
      this.$emit('handleSuccess', res, files, fileList)
    },
    handleChange (file, fileList) {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1, file.name.length)
      if (ext === 'pdf') {
        this.isPdf = true
        this.pdfVisible = true
      }
      if (this.isPdf) {
        this.pdfUrl = this.getPdfUrl(file)
        this.fileInfo = file
      } else {
        this.nativeList = fileList
        const reader = new FileReader()
        reader.readAsDataURL(file.raw)
        reader.onload = () => {
          const img = new Image()
          img.src = reader.result
          img.onload = () => {
            this.autoCropWidth = img.width
            this.autoCropHeight = img.height
          }
        }
        if (this.multiple) {
          fileList.forEach(item => {
            const isLt5M = item.size / 1024 / 1024 < 5
            if (isLt5M) {
              this.$message.error('上传文件大小不能超过 5MB!')
              return false
            }
          })
        } else {
          const isLt5M = file.size / 1024 / 1024 < 5
          if (!isLt5M) {
            this.$message.error('上传文件大小不能超过 5MB!')
            return false
          }
        }
        this.fileInfo = file
        this.fileIndex = fileList.length - 1
        // 上传成功后将图片地址赋值给裁剪框显示图片
        this.$nextTick(() => {
          this.dialogVisible = true
        })
      }
      this.img = file.url
    },
    // 处理pdf
    getPdfUrl (file) {
      this.pdfName = file.name
      // 将文件转化成url
      const url = URL.createObjectURL(file.raw)
      // 加载pdf
      const loadingTask = pdf.createLoadingTask(url)
      loadingTask.promise.then((pdf) => {
        this.numPages = pdf.numPages
      }).catch((err) => {
        console.error('pdf 加载失败', err)
      })
      this.fileInfo = file
      this.fileIndex = 0
      return url
    },
    finish () {
      const that = this
      const name = this.fileInfo.name
      const ext = name.substr(name.lastIndexOf('.'))
      const fileName = this.path + '/' + this.fileInfo.uid + ext
      this.$refs.cropper.getCropBlob((data) => {
        policy(this.bucket, this.prefix).then(response => {
          const formData = new FormData()
          formData.append('policy', response.data.policy)
          formData.append('signature', response.data.signature)
          formData.append('ossaccessKeyId', response.data.accessKeyId)
          formData.append('key', response.data.dir + '/' + this.fileInfo.uid + ext)
          formData.append('dir', response.data.dir)
          formData.append('host', response.data.host)
          formData.append('file', data)
          formData.append('success_action_status', 200)
          formData.append('Filename', fileName)
          axios({
            url: response.data.host,
            method: 'post',
            header: {
              'Content-Type': 'multipart/form-data'
            },
            data: formData
          }).then(async res => {
            if (res.status === 200) {
              that.dialogVisible = false
              let imageUrl = response.data.host + '/' + response.data.dir + '/' + this.fileInfo.uid + ext
              if (that.private) {
                const res = await getLinkPrivate(imageUrl, this.bucket)
                if (res.success) {
                  imageUrl = res.data
                  this.$emit('handleSuccess', imageUrl, this.bucket)
                } else {
                  this.$message.error(res.data.msg)
                }
              }
              that.nativeList.push({})
              that.nativeList[that.fileIndex].url = imageUrl
              that.nativeList[that.fileIndex].suc = true
              that.nativeList[that.fileIndex].status = 'success'
              this.$emit('updateList', this.getValues())
            }
          }).catch(error => {
            console.log('error', error)
          })
        })
      })
    },
    pdfFinish () {
      const that = this
      const fileName = this.path + '/' + this.fileInfo.uid + '.pdf'
      policy(this.bucket, this.prefix).then(response => {
        const formData = new FormData()
        formData.append('policy', response.data.policy)
        formData.append('signature', response.data.signature)
        formData.append('ossaccessKeyId', response.data.accessKeyId)
        formData.append('key', response.data.dir + '/' + this.fileInfo.uid + '.pdf')
        formData.append('dir', response.data.dir)
        formData.append('host', response.data.host)
        formData.append('file', this.fileInfo.raw)
        formData.append('success_action_status', 200)
        formData.append('Filename', fileName)
        axios({
          url: response.data.host,
          method: 'post',
          header: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData
        }).then(async res => {
          if (res.status === 200) {
            that.pdfVisible = false
            let pdfUrl = response.data.host + '/' + response.data.dir + '/' + this.fileInfo.uid + '.pdf'
            if (that.private) {
              const res = await getLinkPrivate(pdfUrl, this.bucket)
              if (res.success) {
                pdfUrl = res.data
                this.$emit('handleSuccess', pdfUrl, this.bucket)
              } else {
                this.$message.error(res.data.msg)
              }
            }
            that.nativeList.push({})
            that.nativeList[that.fileIndex].url = pdfUrl
            that.nativeList[that.fileIndex].suc = true
            that.nativeList[that.fileIndex].status = 'success'
            this.$emit('updateList', this.getValues())
          }
        }).catch(error => {
          console.log('error', error)
        })
      })
    }
  }
}
</script>

<style lang="scss">
.cropper-content .cropper {
  width: auto;
  height: 500px;
  text-align: center;
}

.dialog-pdf {
  height: 100%;

  .onlineHelp {
    position: relative;
    display: flex;
    justify-content: center;

    .tools {
      position: absolute;
      top: 16px;
      z-index: 999;

      .page {
        display: inline-block;
        margin-right: 10px;
      }
    }

    .pdf-box {
      margin-top: 60px;
      height: 100%;
      overflow: auto;
      width: 100%;

      span canvas {
        width: 90%;
        height: 90%;
      }
    }
  }
}
</style>
