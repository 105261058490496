<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :data="data"
        :option="option"
        :search.sync="search"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="success" @click.stop="handleExport()">导出</el-button>
      </template>
      <template slot="dateRangeSearch">
        <el-date-picker
            v-model="search.dateRange"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getList, exportExcel } from '@/api/mall/classifyingredient'
import { formatDate } from '@/util/date'
import FileSaver from 'file-saver'

export default {
  mixins: [mixin],
  data () {
    return {
      search: {
        dateRange: []
      },
      option: {
        tip: false,
        border: true,
        index: true,
        addBtn: false,
        searchMenuSpan: 8,
        menu: false,
        column: [
          {
            label: '商户名称',
            prop: 'merchantName'
          },
          {
            label: '商品学名',
            prop: 'name'
          },
          {
            label: '采集数量',
            prop: 'cnt'
          },
          {
            label: '日期',
            prop: 'date'
          },
          {
            label: '时间范围',
            prop: 'dateRange',
            searchSlot: true,
            search: true,
            hide: true
          }
        ]
      },
      data: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_classifyingredient_add,
        viewBtn: this.permission.mall_classifyingredient_view,
        delBtn: this.permission.mall_classifyingredient_delete,
        editBtn: this.permission.mall_classifyingredient_edit
      }
    }
  },
  methods: {
    onLoad () {
      if (this.search.dateRange.length !== 2) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        this.search.dateRange = [start, end]
      }
      this.search.start = formatDate(this.search.dateRange[0], 'yyyy-MM-dd')
      this.search.end = formatDate(this.search.dateRange[1], 'yyyy-MM-dd')
      getList(this.search).then(res => {
        this.data = res.data
      })
    },
    handleExport (name) {
      let fileName = '数据导出' + formatDate(new Date(), 'yyyyMMddhhmmss') + '.xlsx'
      if (name) {
        fileName = name + '.xlsx'
      }
      exportExcel(this.search).then(res => {
        FileSaver.saveAs(res, fileName)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }
      )
    }
  }
}
</script>
