<template>
  <basic-container>
    <el-form :inline="true" style="padding: 10px;">
      <el-form-item label="模板:" label-width="90px">
        <good-select v-model="templateId" :url="templateDictUrl" @input="changeTemplate"></good-select>
      </el-form-item>
      <el-form-item label="适用对象:" label-width="90px">
        <el-radio-group v-model="type">
          <el-radio :label="0">所有人</el-radio>
          <el-radio :label="1">运营人员</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onConfirm">确认</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="20">
      <el-col :span="14">
        <el-row>
          <el-col :span="14">
            <avue-form ref="valueForm" v-model="valueForm" :option="valueOption">
            </avue-form>
          </el-col>
          <el-col :span="10">
            <avue-form ref="colorForm" v-model="colorForm" :option="colorOption">
            </avue-form>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="10">
        <div class="model">
          <div class="model-content column end">
            <div class="content-msg column">
              <div class="title">{{ template.title }}</div>
              <el-row v-for="(key, index) in template.form" :key="index" class="content start">
                <el-col :span="10" v-if="view[key] && view[key].k" class="k">{{ view[key] && view[key].k }}</el-col>
                <el-col :span="14" :style="'color:' + colorForm[key]" v-if="view[key] && view[key].v" class="v">
                  {{ valueForm[key] || view[key] && view[key].v }}
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </basic-container>
</template>
<script>

import GoodSelect from '@/components/good-select/index'
import { templateId } from '@/api/mp/template'
import { submit } from '@/api/mp/msg'
import { appid } from '@/config'
import { colorRGB2Hex } from '@/util/index'

export default {
  components: { GoodSelect },
  data () {
    return {
      templateDictUrl: '/mp/template/dict/' + appid,
      templateId: '',
      template: {},
      view: {},
      type: 0,
      valueForm: {},
      valueOption: {
        submitBtn: false,
        emptyBtn: false,
        column: []
      },
      colorForm: {},
      colorOption: {
        submitBtn: false,
        emptyBtn: false,
        column: []
      }
    }
  },
  methods: {
    onConfirm () {
      const that = this
      const keys = Object.keys(this.colorForm)
      const formData = []
      keys.forEach(key => {
        formData.push({
          name: key,
          value: this.valueForm[key],
          color: colorRGB2Hex(that.colorForm[key])
        })
      })
      const sendData = {}
      this.$refs.valueForm.validate((valid) => {
        if (valid) {
          sendData.data = JSON.stringify(formData)
          sendData.templateId = this.templateId
          sendData.type = this.type
          sendData.appid = appid
          submit(sendData).then(() => {
            that.$refs.valueForm.hide()
            this.$message({
              type: 'success',
              message: '提交成功!'
            })
          })
        }
      })
    },
    submit (form, done) {
      setTimeout(() => {
        console.log(form)
        done()
      }, 1000)
    },
    async changeTemplate (val) {
      // 初始化数据
      this.valueForm = {}
      this.colorForm = {}
      this.view = {}
      this.valueOption.column = []
      this.colorOption.column = []
      const res = await templateId(val, appid)
      if (res.success) {
        // const valueList = res.data.content.split('\n')
        const valueList = res.data.content.split(' ')
        valueList.forEach((item, index) => {
          if (!item) {
            valueList.splice(index, 1)
          }
        })

        this.template = res.data
        const field = res.data.form
        const valueColumn = []
        const colorColumn = []

        if (res.data.color) {
          this.colorForm = JSON.parse(res.data.color)
        }

        for (let i = 0; i < field.length; i++) {
          const f = field[i]
          valueColumn.push({
            label: f,
            prop: f,
            span: 24,
            rules: [{
              required: true,
              message: '请输入' + f
            }]
          })
          colorColumn.push({
            label: '颜色',
            prop: f,
            type: 'color',
            colorFormat: 'hex',
            span: 24
          })
          if (!this.colorForm[f]) {
            this.colorForm[f] = 'rgba(0, 0, 0, 1)'
          }
          if (!this.view[f]) {
            this.view[f] = {}
          }
          if (valueList[i].indexOf('：') !== -1) {
            this.view[f].k = valueList[i].split('：')[0]
            this.view[f].v = valueList[i].split('：')[1]
          } else {
            this.view[f].v = valueList[i]
          }
          this.view[f].color = this.colorForm[f]
        }
        this.valueOption.column = valueColumn
        this.colorOption.column = colorColumn
        // 展示content
      }
    }
  }
}
</script>

<style lang="scss">

.model {
  background-image: url(~@/assets/img/phone.png);
  height: 670px;
  font-size: 16px;
  background-color: #fff;
  width: 330px;
  position: fixed;
  z-index: 10;
  margin: 0;
  top: calc(50vh - 480px + 1.8rem);
  box-sizing: border-box;
  overflow-y: auto;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 30px;
  padding: 48px 16px 38px 16px;
}

.model-content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #f7f8fa;

}

.content-msg {
  margin: 30px 10px;
  background-color: #fff;
  padding: 10px;
  width: 95%;
  border-radius: 5px;

  .title {
    margin-bottom: 15px;
  }

  .content {
    font-size: 15px;

    :first-child {
      color: #828181;
    }
  }

}
</style>

