import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/mall/goods/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
export const clonePage = (current, size, params) => {
  return request({
    url: '/mall/goods/page/clone',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const statusPage = (current, size, params) => {
  return request({
    url: '/mall/goods/page/status',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/mall/goods/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/mall/goods/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/mall/goods/submit',
    method: 'post',
    data: row
  })
}

export const clone = (ids, merchantId) => {
  return request({
    url: '/mall/goods/clone',
    method: 'post',
    params: {
      ids,
      merchantId
    }
  })
}

export const status = (ids, status) => {
  return request({
    url: '/mall/goods/status',
    method: 'post',
    params: {
      ids,
      status
    }
  })
}

