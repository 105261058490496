<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.crm_merchantCraft_delete"
                @click="handleDelete">删 除
        </el-button>
        <el-button type="success" @click.stop="handleMerchantExport()">导出</el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-view"
            size="small"
            @click.stop="toContract(row)">合同
        </el-button>
        <el-button
            type="text"
            icon="el-icon-view"
            size="small"
            @click.stop="toMerchantBank(row)">开票
        </el-button>
        <el-button
            type="text"
            icon="el-icon-view"
            size="small"
            @click.stop="toCode(row)">动态码
        </el-button>
        <el-button
            type="text"
            icon="el-icon-view"
            size="small"
            @click.stop="toAccount(row)">账号
        </el-button>
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { formatDate } from '@/util/date'
import { exportExcel } from '@/api/common'
import FileSaver from 'file-saver'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/merchantCraft',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        searchMenuSpan: 5,
        align: 'center',
        column: [
          {
            label: '所属市场',
            prop: 'marketId',
            search: true,
            width: 200,
            type: 'select',
            dicUrl: '/crm/market/dict?type=1'
          },
          {
            label: '商户全称',
            prop: 'fullName',
            search: true,
            width: 200
          },
          {
            label: '商户分类',
            prop: 'type',
            search: true,
            type: 'select',
            dicUrl: '/sys/dict/code/major_type',
            width: 100
          },
          {
            label: '账号',
            prop: 'phone',
            search: true,
            width: 120
          },
          {
            label: '商户人员',
            prop: 'name',
            search: true,
            width: 80
          },
          {
            label: '身份证号',
            prop: 'idCardNo',
            search: true,
            width: 140
          },
          {
            label: '身份证起始日期',
            prop: 'cardStartDate',
            width: 120
          },
          {
            label: '身份证结束日期',
            prop: 'cardEndDate',
            width: 120
          },
          {
            label: '摊位号',
            prop: 'stallNo',
            search: true,
            type: 'select',
            dicUrl: '/base/booth/getSerialNumDict'
          },
          {
            label: '社会信用统一代码',
            prop: 'businessLicense',
            width: 150
          },
          {
            label: '营业执照',
            prop: 'registrationUrl',
            type: 'img'
          },
          {
            label: '食品经营许可证',
            prop: 'hygieneLicenseUrl',
            type: 'img',
            width: 150
          },
          {
            label: '溯源码',
            prop: 'traceabilityUrl',
            type: 'img',
            width: 150
          },
          {
            label: '秤数量',
            prop: 'scaleNum',
            width: 150
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_merchantCraft_add,
        viewBtn: this.permission.crm_merchantCraft_view,
        delBtn: this.permission.crm_merchantCraft_delete,
        editBtn: this.permission.crm_merchantCraft_edit
      }
    }
  },
  methods: {
    toAdd () {
      this.$router.push({ path: '/crm/merchantEdit' })
    },
    toEdit (row) {
      this.$router.push({
        path: '/crm/merchantEdit',
        query: { id: row.id }
      })
    },
    toContract (row) {
      this.$router.push({
        path: '/crm/contract',
        query: { merchantId: row.id }
      })
    },
    toMerchantBank (row) {
      this.$router.push({
        path: '/crm/merchantBank',
        query: { ownerId: row.id }
      })
    },
    toCode (row) {
      this.$router.push({
        path: '/crm/bankAccountParam',
        query: { ownerId: row.id }
      })
    },
    toAccount (row) {
      this.$router.push({
        path: '/crm/merchantAccount',
        query: { merchantId: row.id }
      })
    },
    handleMerchantExport () {
      exportExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '商户信息' + formatDate(new Date(), 'yyyyMMddhhmmss') + '.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    }
  }
}
</script>
