import { request } from '@/util/http'

export const getList = (params) => {
  return request({
    url: '/mall/classifyingredient/list',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/mall/classifyingredient/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/mall/classifyingredient/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/mall/classifyingredient/submit',
    method: 'post',
    data: row
  })
}

export const exportExcel = (params) => {
  return request({
    url: '/mall/classifyingredient/export',
    method: 'get',
    responseType: 'blob',
    params: {
      ...params
    }
  })
}
