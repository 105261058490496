<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.mall_goodsbrand_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row,index}" slot="menu">
        <el-button
            size="small"
            type="text"
            v-show="showBtn(row)"
            v-if="!row.$cellEdit"
            @click.stop="$refs.crud.rowCell(row,index)">编辑
        </el-button>
        <el-button
            size="small"
            type="text"
            v-show="showBtn(row)"
            @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import me from '@/util/me'
export default {
  mixins: [mixin, me],
  data () {
    return {
      module: 'mall/goodsbrand',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        selection: true,
        addBtn: true,
        viewBtn: false,
        editBtn: false,
        delBtn: false,
        cellBtn: true,
        searchMenuSpan: 5,
        selectable: (row) => {
          return this.showBtn(row)
        },
        column: [
          {
            label: '名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入名称'
            }],
            cell: true
          },
          {
            label: '排序',
            prop: 'sort',
            type: 'number',
            rules: [{
              required: true,
              message: '请输入'
            }],
            cell: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_goodsbrand_add,
        viewBtn: this.permission.mall_goodsbrand_view,
        delBtn: this.permission.mall_goodsbrand_delete,
        editBtn: this.permission.mall_goodsbrand_edit
      }
    }
  },
  methods: {
  }
}
</script>
