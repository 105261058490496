import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/base/contract/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/base/contract/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const getOne = (param) => {
  return request({
    url: '/base/contract/one',
    method: 'get',
    params: param
  })
}

export const remove = (ids) => {
  return request({
    url: '/base/contracts/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/base/contract/submit',
    method: 'post',
    data: row
  })
}

export const zipDownload = (ids) => {
  return request({
    url: '/base/contracts/zipDownload',
    method: 'get',
    responseType: 'blob',
    params: {
      ids
    }
  })
}
