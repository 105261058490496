<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.visual_visual_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'visual/visual',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '大屏标题',
            prop: 'title',
            rules: [{
              required: true,
              message: '请输入大屏标题'
            }]
          },
          {
            label: '大屏背景',
            prop: 'backgroundUrl',
            rules: [{
              required: true,
              message: '请输入大屏背景'
            }]
          },
          {
            label: '大屏类型',
            prop: 'category',
            rules: [{
              required: true,
              message: '请输入大屏类型'
            }]
          },
          {
            label: '发布密码',
            prop: 'password',
            rules: [{
              required: true,
              message: '请输入发布密码'
            }]
          },
          {
            label: '创建人',
            prop: 'createUser',
            rules: [{
              required: true,
              message: '请输入创建人'
            }]
          },
          {
            label: '创建部门',
            prop: 'createDept',
            rules: [{
              required: true,
              message: '请输入创建部门'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          },
          {
            label: '修改人',
            prop: 'updateUser',
            rules: [{
              required: true,
              message: '请输入修改人'
            }]
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            rules: [{
              required: true,
              message: '请输入修改时间'
            }]
          },
          {
            label: '状态',
            prop: 'status',
            rules: [{
              required: true,
              message: '请输入状态'
            }]
          },
          {
            label: '是否已删除',
            prop: 'isDeleted',
            rules: [{
              required: true,
              message: '请输入是否已删除'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.visual_visual_add,
        viewBtn: this.permission.visual_visual_view,
        delBtn: this.permission.visual_visual_delete,
        editBtn: this.permission.visual_visual_edit
      }
    }
  },
  methods: {
  }
}
</script>
