<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.erp_devicepurchase_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'erp/devicepurchase',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '上级id',
            prop: 'parentId',
            rules: [{
              required: true,
              message: '请输入上级id'
            }]
          },
          {
            label: '批次号',
            prop: 'batchNo',
            rules: [{
              required: true,
              message: '请输入批次号'
            }]
          },
          {
            label: '批次日期',
            prop: 'batchDate',
            rules: [{
              required: true,
              message: '请输入批次日期'
            }]
          },
          {
            label: '层级 0-根批次',
            prop: 'level',
            rules: [{
              required: true,
              message: '请输入层级 0-根批次'
            }]
          },
          {
            label: '设备类型：电子秤、电表、摄像头、小票打印机等',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入设备类型：电子秤、电表、摄像头、小票打印机等'
            }]
          },
          {
            label: '设备厂商或品牌（顶尖、智崎等，请参照数据字典scale_type）',
            prop: 'brand',
            rules: [{
              required: true,
              message: '请输入设备厂商或品牌（顶尖、智崎等，请参照数据字典scale_type）'
            }]
          },
          {
            label: '型号',
            prop: 'model',
            rules: [{
              required: true,
              message: '请输入型号'
            }]
          },
          {
            label: '數量',
            prop: 'amount',
            rules: [{
              required: true,
              message: '请输入數量'
            }]
          },
          {
            label: '备注信息',
            prop: 'remark',
            rules: [{
              required: true,
              message: '请输入备注信息'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.erp_devicepurchase_add,
        viewBtn: this.permission.erp_devicepurchase_view,
        delBtn: this.permission.erp_devicepurchase_delete,
        editBtn: this.permission.erp_devicepurchase_edit
      }
    }
  },
  methods: {
  }
}
</script>
