<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        :page="page"
        :before-open="beforeOpen"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @refresh-change="refreshChange"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <div class="start">
          <el-button
              type="primary"
              size="small"
              icon="el-icon-add"
              v-if="permission.sys_dict_add"
              @click="addRow">新增
          </el-button>
          <el-button
              type="danger"
              size="small"
              icon="el-icon-delete"
              v-if="permission.sys_dict_delete"
              @click="handleDelete">删 除
          </el-button>
          <el-button type="success"   @click.stop="handleExport()">导出</el-button>
          <el-upload
              class="filter-item"
              :show-file-list="false"
              :action="handleImport()"
              :headers="uploadHeaders()"
              :on-success="uploadSuc">
            <el-button type="warning" icon="upload">导入</el-button>
          </el-upload>
        </div>
      </template>

    </avue-crud>
  </basic-container>
</template>

<script>
import { mapGetters } from 'vuex'
import mixin from '@/util/mixin'
import { deepClone } from '@/util/index'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'sys/dict',
      option: {
        tip: false,
        border: true,
        index: true,
        selection: true,
        addBtn: false,
        viewBtn: false,
        cellBtn: true,
        searchMenuSpan: 5,
        column: [
          {
            label: '字典分类',
            prop: 'type',
            search: true,
            cell: true,
            rules: [{
              required: true,
              message: '请输入字典编号'
            }]
          },
          {
            label: '字典键值',
            prop: 'dictKey',
            type: 'number',
            cell: true,
            rules: [{
              required: true,
              message: '请输入字典键值'
            }]
          },
          {
            label: '字典名称',
            prop: 'dictValue',
            search: true,
            cell: true,
            rules: [{
              required: true,
              message: '请输入字典名称'
            }]
          },
          {
            label: '字典排序',
            prop: 'sort',
            type: 'number',
            cell: true,
            rules: [{
              required: true,
              message: '请输入字典排序'
            }]
          }
        ]
      }

    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_dict_add, false),
        viewBtn: this.vaildData(this.permission.sys_dict_view, false),
        delBtn: this.vaildData(this.permission.sys_dict_delete, false),
        editBtn: this.vaildData(this.permission.sys_dict_edit, false)
      }
    }
  },
  methods: {
    // cell方式增加数据
    addRow () {
      let _search = {}
      if (this.search.type && this.data.length > 0) {
        _search = deepClone(this.data[this.data.length - 1])
        _search.sort = _search.sort + 1
        _search.dictKey = _search.dictKey + 1
        _search.dictValue = null
        _search.id = null
      }
      _search.$cellEdit = true
      this.data.splice(0, 0, _search)
    }
  }
}
</script>

<style>
</style>
