import { request } from '@/util/http'

export const tree = (current, size, params) => {
  return request({
    url: '/erp/devicemodel/tree',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getList = (current, size, params) => {
  return request({
    url: '/erp/devicemodel/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/erp/devicemodel/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/erp/devicemodel/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/erp/devicemodel/submit',
    method: 'post',
    data: row
  })
}

