<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="default"
            size="small"
            @click="cancel">返回
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getList } from '@/api/mall/orderitem'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/orderitem',
      form: {},
      search: {
        orderId: null
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        selection: false,
        editBtn: false,
        delBtn: false,
        viewBtn: false,
        addBtn: false,
        menu: false,
        labelWidth: 200,
        column: [
          {
            label: '商品主图',
            prop: 'goodsPic',
            type: 'img'
          },
          {
            label: '商品名称',
            prop: 'goodsName'
          },
          {
            label: '商品分类',
            prop: 'goodsTypeId',
            type: 'select',
            dicUrl: '/mall/goodstype/dict'
          },
          {
            label: '订单编号',
            prop: 'orderSn'
          },
          {
            label: '数量',
            prop: 'quantity'
          },
          {
            label: '重量',
            prop: 'weight'
          },
          {
            label: '价格',
            prop: 'price'
          },
          {
            label: '销售类型',
            prop: 'goodsSellType'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_orderitem_add,
        viewBtn: this.permission.mall_orderitem_view,
        delBtn: this.permission.mall_orderitem_delete,
        editBtn: this.permission.mall_orderitem_edit
      }
    }
  },
  created () {
    this.search.orderId = this.$route.query.orderId
  },
  methods: {
    onLoad (page) {
      if (!this.search.orderId) {
        return
      }
      getList(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    cancel () {
      this.$router.push({ path: '/mall/order' })
    }

  }
}
</script>
