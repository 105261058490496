<template>
  <basic-container>
    <el-collapse-transition>
      <el-form :inline="true" class="search-form">
        <el-form-item>
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="类型"
              v-model="page.type"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item search" type="primary" icon="el-icon-search" @click="handleFilter">搜索
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-transition>
    <el-row class="padding-row">
      <el-button type="primary" icon="el-icon-add" @click="handleCreate">新增</el-button>
    </el-row>
    <el-table
        class="good-table"
        :data="list"
        v-loading.body="listLoading"
        element-loading-text="拼命加载中"
        border
        fit
        highlight-current-row>
      <el-table-column label="订单id" prop="id">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="" prop="userId">
        <template slot-scope="scope">
          <span>{{ scope.row.userId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商户id" prop="merchantId">
        <template slot-scope="scope">
          <span>{{ scope.row.merchantId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单编号" prop="orderSn">
        <template slot-scope="scope">
          <span>{{ scope.row.orderSn }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单总金额" prop="totalAmount">
        <template slot-scope="scope">
          <span>{{ scope.row.totalAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="应付金额（实际支付金额）" prop="payAmount">
        <template slot-scope="scope">
          <span>{{ scope.row.payAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="管理员后台调整订单使用的折扣金额" prop="discountAmount">
        <template slot-scope="scope">
          <span>{{ scope.row.discountAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付方式：0->现金；1->支付宝；2->微信；3->银联; 4->动态聚合码" prop="payType">
        <template slot-scope="scope">
          <span>{{ scope.row.payType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="动态聚合码url（支付方式为动态聚合码时返回）" prop="payQrCode">
        <template slot-scope="scope">
          <span>{{ scope.row.payQrCode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单来源：0->电子秤" prop="sourceType">
        <template slot-scope="scope">
          <span>{{ scope.row.sourceType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单状态：0->待付款；2->已支付；3->已关闭；4->无效订单" prop="status">
        <template slot-scope="scope">
          <span>{{ scope.row.status }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付到账通知是否发送，0：未发送，1：已发送" prop="isNoticed">
        <template slot-scope="scope">
          <span>{{ scope.row.isNoticed }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单备注" prop="note">
        <template slot-scope="scope">
          <span>{{ scope.row.note }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付时间" prop="paymentTime">
        <template slot-scope="scope">
          <span>{{ scope.row.paymentTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否同步" prop="syncFlag">
        <template slot-scope="scope">
          <span>{{ scope.row.syncFlag }}</span>
        </template>
      </el-table-column>
      <el-table-column label="抹零方式 1：直接抹零 2：四舍五入" prop="singleResetType">
        <template slot-scope="scope">
          <span>{{ scope.row.singleResetType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="清零单位 0:元 1:角 2:分" prop="singleResetUnit">
        <template slot-scope="scope">
          <span>{{ scope.row.singleResetUnit }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="handleUpdate(scope.row.id)">编辑</el-button>
          <el-button
              v-if="scope.row.status!='deleted'"
              size="small"
              type="danger"
              @click="handleDelete(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="!listLoading" class="end pagination-container">
      <el-pagination
          :current-page.sync="page.currentPage"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :page-sizes="[10,20,30, 50]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog title="订单表" :visible.sync="showDialog">
      <el-row :gutter="20">
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            label-position="left"
            label-width="120px"
            class="small-space"
            style='padding:20px 60px;'>
          <el-col :span="12">
            <el-form-item label="订单id：" prop="id">
              <el-input v-model="form.id" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="：" prop="userId">
              <el-input v-model="form.userId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商户id：" prop="merchantId">
              <el-input v-model="form.merchantId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订单编号：" prop="orderSn">
              <el-input v-model="form.orderSn"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订单总金额：" prop="totalAmount">
              <el-input v-model="form.totalAmount"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="应付金额（实际支付金额）：" prop="payAmount">
              <el-input v-model="form.payAmount"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管理员后台调整订单使用的折扣金额：" prop="discountAmount">
              <el-input v-model="form.discountAmount"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付方式：0->现金；1->支付宝；2->微信；3->银联; 4->动态聚合码：" prop="payType">
              <el-input v-model="form.payType"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="动态聚合码url（支付方式为动态聚合码时返回）：" prop="payQrCode">
              <el-input v-model="form.payQrCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订单来源：0->电子秤：" prop="sourceType">
              <el-input v-model="form.sourceType"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订单状态：0->待付款；2->已支付；3->已关闭；4->无效订单：" prop="status">
              <el-input v-model="form.status"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付到账通知是否发送，0：未发送，1：已发送：" prop="isNoticed">
              <el-input v-model="form.isNoticed"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订单备注：" prop="note">
              <el-input v-model="form.note"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付时间：" prop="paymentTime">
              <el-input v-model="form.paymentTime"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否同步：" prop="syncFlag">
              <el-input v-model="form.syncFlag"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="抹零方式 1：直接抹零 2：四舍五入：" prop="singleResetType">
              <el-input v-model="form.singleResetType"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="清零单位 0:元 1:角 2:分：" prop="singleResetUnit">
              <el-input v-model="form.singleResetUnit"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import { getDetail, getList, remove, submit } from '@/api/mall/order'

export default {
  data () {
    return {
      total: null,
      list: null,
      listLoading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      showDialog: false,
      form: {
        id: undefined,
        userId: undefined,
        merchantId: undefined,
        orderSn: undefined,
        totalAmount: undefined,
        payAmount: undefined,
        discountAmount: undefined,
        payType: undefined,
        payQrCode: undefined,
        sourceType: undefined,
        status: undefined,
        isNoticed: undefined,
        note: undefined,
        paymentTime: undefined,
        syncFlag: undefined,
        singleResetType: undefined,
        singleResetUnit: undefined
      },
      rules: {
        userId: [{ required: true, trigger: 'blur', message: '请输入 ' }],
        merchantId: [{ required: true, trigger: 'blur', message: '请输入 商户id' }],
        orderSn: [{ required: true, trigger: 'blur', message: '请输入 订单编号' }],
        totalAmount: [{ required: true, trigger: 'blur', message: '请输入 订单总金额' }],
        payAmount: [{ required: true, trigger: 'blur', message: '请输入 应付金额（实际支付金额）' }],
        discountAmount: [{ required: true, trigger: 'blur', message: '请输入 管理员后台调整订单使用的折扣金额' }],
        payType: [{ required: true, trigger: 'blur', message: '请输入 支付方式：0->现金；1->支付宝；2->微信；3->银联; 4->动态聚合码' }],
        payQrCode: [{ required: true, trigger: 'blur', message: '请输入 动态聚合码url（支付方式为动态聚合码时返回）' }],
        sourceType: [{ required: true, trigger: 'blur', message: '请输入 订单来源：0->电子秤' }],
        status: [{ required: true, trigger: 'blur', message: '请输入 订单状态：0->待付款；2->已支付；3->已关闭；4->无效订单' }],
        isNoticed: [{ required: true, trigger: 'blur', message: '请输入 支付到账通知是否发送，0：未发送，1：已发送' }],
        note: [{ required: true, trigger: 'blur', message: '请输入 订单备注' }],
        paymentTime: [{ required: true, trigger: 'blur', message: '请输入 支付时间' }],
        syncFlag: [{ required: true, trigger: 'blur', message: '请输入 是否同步' }],
        singleResetType: [{ required: true, trigger: 'blur', message: '请输入 抹零方式 1：直接抹零 2：四舍五入' }],
        singleResetUnit: [{ required: true, trigger: 'blur', message: '请输入 清零单位 0:元 1:角 2:分' }]
      }
    }
  },
  created () {
    this.onLoad(this.page)
  },

  methods: {
    onLoad (page, params = {}) {
      this.listLoading = true
      getList(page.currentPage, page.pageSize, params).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
      })
    },
    handleFilter () {
      this.onLoad(this.page)
    },
    handleCreate () {
      this.showDialog = true
    },
    handleUpdate (id) {
      getDetail(id).then(res => {
        this.form = res.data
        this.showDialog = true
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '提示', {}).then(() => {
        remove(row.id).then(res => {
          if (res.success) {
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.onLoad(this.page)
          } else {
            this.$notify({
              title: '删除失败',
              message: res.data.message,
              type: 'error',
              duration: 2000
            })
          }
        })
      })
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保存
      this.$refs.form.validate(valid => {
        if (valid) {
          submit(this.form).then(res => {
            if (res.success) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.showDialog = false
              this.onLoad(this.page)
            } else {
              this.$notify({
                title: '操作失败',
                message: res.data.message,
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      })
    }
  }

}
</script>
