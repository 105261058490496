<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        :span-method="spanMethod"
        ref="crud"
        v-model="form"
        @on-load="onLoad"
        @row-click="handleRowClick">
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toDistrict(row)">分配
        </el-button>
      </template>
      <template slot="search">
        <el-form align="left" :inline="true">
          <el-form-item label="关键字:">
            <el-input placeholder="名称、姓名、手机等" v-model="search.query"></el-input>
          </el-form-item>
          <el-form-item v-if="ownerType == 'COMPANY'" label="所属公司：" label-width="100px" prop="companyId">
            <good-select v-model="form.companyId" url="/crm/company/dict"/>
          </el-form-item>
        </el-form>
      </template>
    </avue-crud>
    <el-dialog
        title="分配"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose">
      <el-transfer
          filterable
          :filter-method="filterMethod"
          :titles="['待分配', '已分配']"
          @change="changeTrans()"
          v-model="value"
          :data="transData"
          class="transfer-box">
      </el-transfer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false;confirmTrans()">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>

<script>
import { getAuth, submitRelations } from '@/api/base/linkman'
import { getDict, getCurrent } from '@/api/crm/market'
import GoodSelect from '@/components/good-select'

export default {
  components: { GoodSelect },
  data () {
    return {
      module: 'base/linkman',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      ownerType: undefined,
      id: '',
      data: [],
      markets: [],
      current: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: false,
        searchIndex: 1,
        searchIcon: true,
        searchShowBtn: false,
        align: 'center',
        column: []
      },
      dialogVisible: false,
      value: [],
      transData: [],
      filterMethod (query, item) {
        return item.label.indexOf(query) > -1
      },
      allValue: []
    }
  },
  watch: {
    '$route' (to, from) {
      this.onLoad()
    }
  },
  created () {
    if (this.$route.query.type) {
      this.ownerType = this.$route.query.type.toUpperCase()
    }
    this.listMarkets()
    // this.getCurrent()
  },
  methods: {
    onLoad () {
      if (this.$route.query.type) {
        this.ownerType = this.$route.query.type.toUpperCase()
      }
      getAuth({ ownerType: this.ownerType }).then(res => {
        if (res.success) {
          this.data = res.data
          // 初始化表格表头
          switch (this.ownerType) {
            case 'SYS':
              this.option.column = [
                {
                  label: '姓名',
                  prop: 'name'
                },
                {
                  label: '账号',
                  prop: 'userName'
                },
                {
                  label: '手机号',
                  prop: 'mobile'
                }
              ]
              break
            case 'COMPANY':
              this.option.column = [
                {
                  label: '公司',
                  prop: 'ownerName',
                  type: 'select'
                },
                {
                  label: '姓名',
                  prop: 'name'
                },
                {
                  label: '账号',
                  prop: 'userName'
                },
                {
                  label: '手机号',
                  prop: 'mobile'
                }
              ]
              break
          }
          this.rowspan()
        } else {
          this.$message({
            type: 'error',
            message: '管理员初始化失败',
            duration: 2000
          })
        }
      })
    },
    listMarkets () {
      getDict().then(res => {
        if (res.success) {
          this.markets = res.data
        } else {
          this.$message({
            type: 'error',
            message: '市场初始化失败',
            duration: 2000
          })
        }
      })
    },
    getCurrent () {
      getCurrent(this.id).then(res => {
        if (res.success) {
          // 处理待分配和已分配数据
          this.generateData(res.data)
        } else {
          this.$message({
            type: 'error',
            message: '权限初始化失败',
            duration: 2000
          })
        }
      })
    },
    generateData (data) {
      const tempTransData = []
      let tempValue = []
      this.allValue = data
      data.forEach((item, index) => {
        // 注释1
        tempTransData.push({
          label: item.label,
          key: index
        })
        if (item.sort === 1) {
          tempValue = tempValue.concat(index)
        }
      })
      this.transData = tempTransData
      this.value = tempValue
    },
    rowspan () {
      this.spanArr = []
      this.position = 0
      this.data.forEach((item, index) => {
        if (index === 0) {
          this.spanArr.push(1)
          this.position = 0
        } else {
          if (this.data[index][this.key] === this.data[index - 1][this.key]) {
            this.spanArr[this.position] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.position = index
          }
        }
      })
    },
    spanMethod ({ row, column, rowIndex, columnIndex }) {
      if (column.property === [this.key]) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    toDistrict (row) {
      this.id = row.id
      this.getCurrent()
      this.dialogVisible = true
    },
    changeTrans () {
      // console.log(this.value)
    },
    confirmTrans () {
      // 更新
      const data = []
      this.value.forEach((item) => {
        data.push({
          id: this.id,
          ownerId: this.allValue[item].value
        })
      })
      submitRelations(data).then(res => {
        if (res.success) {
          this.dialogVisible = false
        } else {
          this.$message({
            type: 'error',
            message: '权限分配失败',
            duration: 2000
          })
        }
      })
    },
    handleClose () {
      this.dialogVisible = false
    },
    handleRowClick (row, event, column) {
      this.id = row.id
      this.getCurrent()
      this.dialogVisible = true
    }
  }
}
</script>
<style lang="scss">
.transfer-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
