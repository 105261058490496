<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toDetail(row)">明细
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/order',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        searchMenuSpan: 5,
        labelWidth: 200,
        column: [
          {
            label: '所属市场',
            prop: 'marketId',
            width: 200,
            type: 'select',
            dicUrl: '/crm/market/dict?type=1',
            search: true
          },
          {
            label: '手机号码',
            prop: 'mobile',
            search: true
          },
          {
            label: '商户名称',
            prop: 'name',
            search: true
          },
          {
            label: '摊主姓名',
            prop: 'stallName',
            search: true
          },
          {
            label: '订单编号',
            prop: 'orderSn'
          },
          {
            label: '订单总金额',
            prop: 'totalAmount'
          },
          {
            label: '实际支付金额',
            prop: 'payAmount'
          },
          {
            label: '支付方式',
            prop: 'payType',
            type: 'select',
            dicUrl: '/sys/dict/code/order_pay_type'
          },
          {
            label: '订单状态',
            prop: 'status',
            type: 'select',
            dicUrl: '/sys/dict/code/order_status'
          },
          {
            label: '支付时间',
            prop: 'paymentTime'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_order_add,
        viewBtn: this.permission.mall_order_view,
        delBtn: this.permission.mall_order_delete,
        editBtn: this.permission.mall_order_edit
      }
    }
  },
  methods: {

    toDetail (row) {
      this.$router.push({
        path: '/mall/orderitem',
        query: { orderId: row.id }
      })
    }
  }
}
</script>
