<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            size="small"
            v-if="permission.crm_devicesn_add"
            @click="handleBatch">批量新增
        </el-button>
        <el-button type="success" @click.stop="handleExport()">导出</el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            size="small"
            icon="el-icon-delete"
            plain
            @click.stop="unbind(row)">解绑
        </el-button>
      </template>
    </avue-crud>
    <el-dialog title="批量新增" :visible.sync="showDialog">
      <el-row :gutter="20">
        <el-form
            :model="batchForm"
            ref="batchForm"
            :rules="batchRules"
            label-position="left"
            label-width="120px"
            class="small-space"
            style='padding:20px 60px;'>
          <el-col :span="24">
            <el-form-item label="所属市场：" prop="marketId" >
              <good-select v-model="batchForm.marketId" url="/crm/market/dict?type=1"></good-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="初始化数量：" prop="number">
              <el-input-number :min="1" v-model="batchForm.number" :step="10"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备品牌：" prop="brand">
              <good-select v-model="batchForm.brand" url="/sys/dict/code/scale_type"></good-select>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select/index'
import { batch } from '../../api/crm/devicesn'
import { unbind } from '@/api/crm/devicesn'

export default {
  mixins: [mixin],
  components: { GoodSelect },
  data () {
    return {
      module: 'crm/devicesn',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        viewBtn: true,
        searchMenuSpan: 6,
        menuWidth: 100,
        selection: true,
        column: [
          {
            label: '批次',
            prop: 'stockId',
            type: 'select',
            select: true,
            search: true,
            dicUrl: '/erp/stockout/getDict'
          },
          {
            label: '所属市场',
            prop: 'marketId',
            search: true,
            width: 200,
            type: 'select',
            dicUrl: '/crm/market/dict?type=1'
          },
          {
            label: '编号名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入编号名称'
            }]
          },
          {
            label: '设备类型',
            prop: 'type',
            search: true,
            type: 'select',
            select: true,
            dicUrl: '/sys/dict/code/device_type'
          },
          {
            label: '设备品牌',
            prop: 'brand',
            search: true,
            type: 'select',
            select: true,
            dicUrl: '/sys/dict/code/scale_type'
          },
          {
            label: '验证码',
            prop: 'secret'
          },
          {
            label: '序列号',
            prop: 'deviceSn',
            rules: [{
              required: true,
              message: '请输入序列号'
            }]
          },
          {
            label: '绑定商户',
            prop: 'merchantName'
          },
          {
            label: '绑定账号',
            prop: 'username'
          },
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            search: true,
            dicUrl: '/sys/dict/code/sn_status'
          },
          {
            label: '创建时间',
            prop: 'createTime',
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          }
        ]
      },
      data: [],
      showDialog: false,
      batchForm: {
        marketId: '',
        number: 0,
        brand: 1
      },
      batchRules: {
        marketId: [{ required: true, trigger: 'blur', message: '请选择所属市场' }],
        number: [{ required: true, trigger: 'blur', message: '请输入新增数量' }],
        brand: [{ required: true, trigger: 'blur', message: '请选择品牌' }]
      }
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_devicesn_add,
        viewBtn: this.permission.crm_devicesn_view,
        delBtn: this.permission.crm_devicesn_delete,
        editBtn: this.permission.crm_devicesn_edit
      }
    }
  },
  methods: {
    handleBatch () {
      this.showDialog = true
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保存
      this.$refs.batchForm.validate(valid => {
        if (valid) {
          batch(this.batchForm).then(res => {
            if (res.success) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.showDialog = false
              this.onLoad(this.page)
            } else {
              this.$notify({
                title: '失败',
                message: res.msg,
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/crm/devicesnEdit',
        query: { id: row.id }
      })
    },
    unbind (row) {
      this.$confirm('确定将商户与秤解绑?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          unbind(this.module, row.id).then(res => {
            if (res.success) {
              this.onLoad(this.page, this.search)
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        })
    }
  }
}
</script>
