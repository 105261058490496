<template>
  <div class="edit-box">
    <el-form
        :model="form"
        ref="owner"
        :inline-message="true"
        label-position="right"
        label-width="100px"
        class="small-space"
        :rules="rules">
      <linkman-edit
          ref="LinkmanEdit"
          v-if="linkmanVisible"
          :show-submit="false"
          :need-check="isLegalPerson"
          link-type="户主"
          v-bind:info="form"
          @reset="reset"
          @set-linkman="setLegalPerson">
        <template v-slot:linkProp>
          <el-checkbox @change="setNeedCheck" v-model="isLegalPerson" label="法人" style="margin-left: 10px"/>
        </template>
        <template v-slot:otherLicense>
          <el-col :span="8">
            <el-form-item label="健康证：" label-width="160px">
              <good-cropper
                  v-model="form.healthLicenseAnnex.url"
                  :showFileList="false"
                  :private="true"
                  :bucket="'zhsn-scale-private'"
                  :prefix="'linkman'"
                  :initData="form.healthLicenseAnnex.url"
                  :uploadClass="'upload-pic'"></good-cropper>
            </el-form-item>
          </el-col>
        </template>
        <template v-slot:foreignKey>
          <el-col v-if="marketVisible||merchantVisible" :span="24">
            <el-col v-if="marketVisible" :span="8">
              <el-form-item label="市场：" label-width="160px" required>
                <good-select :disabled="marketDisabled" v-model="marketId" url="/crm/market/dict"/>
              </el-form-item>
            </el-col>
            <el-col v-if="merchantVisible" :span="8">
              <el-form-item label="商户：" label-width="160px" required>
                <good-select v-model="form.merchantId" url="/crm/merchant/dict"/>
              </el-form-item>
            </el-col>
          </el-col>
        </template>
        <template v-slot:moreProps>
          <el-col :span="24">
            <el-col :span="8">
              <el-form-item label="健康证编号：" label-width="160px">
                <el-input v-model="form.healthLicense"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="健康证有效起：" label-width="160px" prop="healthLicensevalidFrom">
                <el-date-picker
                    @input="changeDate()"
                    v-model="form.healthLicenseAnnex.validFrom"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="健康证有效止：" label-width="160px">
                <el-date-picker
                    @input="changeDate()"
                    v-model="form.healthLicenseAnnex.validTo"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-col>
        </template>
      </linkman-edit>
      <el-col :span="24">
        <el-footer align="center" v-if="showSubmit">
          <el-button size="small" type="primary" @click="save()">保存信息</el-button>
          <el-button size="small" @click="handleCancel()">取消</el-button>
        </el-footer>
      </el-col>
    </el-form>
  </div>
</template>

<script>
import GoodSelect from '@/components/good-select'
import GoodCropper from '@/components/good-cropper'
import LinkmanEdit from '@/views/base/linkmanedit'
import { submit, getDetail } from '@/api/base/linkman'

export default {
  name: 'MerchantUserEdit',
  components: { GoodSelect, GoodCropper, LinkmanEdit },
  props: {
    marketVisible: {
      default: false,
      type: Boolean
    },
    marketDisabled: {
      default: false,
      type: Boolean
    },
    merchantVisible: {
      default: true,
      type: Boolean
    },
    user: {
      default: null,
      type: Object
    },
    back: {
      default: true,
      type: Boolean
    },
    showSubmit: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      isLegalPerson: true,
      listLoading: true,
      linkmanVisible: true,
      showDialog: false,
      marketId: undefined,
      form: {
        healthLicenseAnnex: {}
      },
      rules: {
        merchantId: [{ required: true, trigger: 'blur', message: '请选择所属商户' }]
      }
    }
  },
  created () {
    if (this.back) {
      this.isLegalPerson = false
    }
    if (this.user) {
      this.form = this.user
      this.linkmanVisible = true
      this.reset()
    } else if (this.merchantId) {
      this.form.merchantId = this.merchantId
      this.linkmanVisible = true
      this.reset()
    } else if (this.$route.query.id) {
      this.form.id = this.$route.query.id
      this.loadData()
    }
  },
  watch: {
    form: {
      handler (newVal, oldVal) {
        if (newVal) {
          if (this.isLegalPerson) {
            this.$emit('set-linkman', newVal)
          } else {
            this.$emit('set-owner', newVal)
          }
          this.reset()
        }
      },
      immediate: false,
      deep: true
    },
    info: {
      handler (newVal, oldVal) {
        this.form = newVal
        this.reset()
      },
      deep: true,
      immediate: false
    }
  },
  methods: {
    loadData () {
      this.listLoading = true
      getDetail(this.form.id).then(res => {
        this.form = res.data
        if (!res.data.idCardFace) {
          this.form.idCardFace = {}
        }
        if (!res.data.idCardBack) {
          this.form.idCardBack = {}
        }
        if (!res.data.healthLicenseAnnex) {
          this.form.healthLicenseAnnex = {}
        }
      })
    },
    setNeedCheck (isLegalPerson) {
      this.$emit('need-check', isLegalPerson)
    },
    setLegalPerson (legalPerson) {
      this.form = legalPerson
      this.reset()
      if (this.isLegalPerson) {
        this.$emit('set-linkman', legalPerson)
      } else {
        this.$emit('set-owner', legalPerson)
      }
    },
    handleUpdate (id) {
      getDetail(id).then(res => {
        this.form = res.data
        this.showDialog = true
      })
    },
    handleCancel () {
      if (this.back) {
        this.$router.push('/base/linkman?owner=MERCHANT')
      } else {
        this.showDialog = false
      }
    },
    save () {
      const that = this
      return new Promise((resolve, reject) => {
        return that.handleSubmit()
      }).then(() => {
        if (this.back) {
          this.$router.push('/base/linkman?owner=MERCHANT')
        }
      }).catch(() => {
        if (this.back) {
          that.locateToErr()
        }
      })
    },
    handleSubmit () {
      // 保存
      return new Promise((resolve, reject) => {
        this.$refs.owner.validate(valid => {
          if (valid) {
            submit(this.form).then(res => {
              if (res.success) {
                if (this.back) {
                  this.$notify({
                    title: '成功',
                    message: '操作成功',
                    type: 'success',
                    duration: 2000
                  })
                }
                this.showDialog = false
                this.$emit('submit-merchant')
                resolve(true)
              } else {
                reject(new Error())
              }
            })
          } else {
            reject(new Error())
          }
        })
      })
    },
    reset () {
      if (!this.form) {
        this.form = {}
      }
      if (!this.form.healthLicenseAnnex) {
        this.form.healthLicenseAnnex = {}
      }
    },
    locateToErr () {
      setTimeout(() => {
        const errorDiv = document.getElementsByClassName('is-error')
        if (errorDiv.length) {
          errorDiv[0].scrollIntoView()
        }
      }, 0)
    },
    changeDate () {
      this.$forceUpdate()
    }
  }

}
</script>

<style scoped lang="scss">
.edit-box {
  height: calc(100%);
  padding: 20px 20px;
  border-radius: 10px;
  box-sizing: border-box;
  background: #FFFFFF;

  .el-card {
    height: 100%;
    overflow: auto;
  }

  //&:first-child {
  //  padding-top: 0;
  //}
}
</style>
