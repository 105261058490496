<template>
  <div>
    <el-card class="good-card">
      <div>
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            :inline-message="true"
            label-position="right"
            label-width="150px"
            class="small-space form-one">
          <el-form-item label="市场类型：" prop="marketId">
            <good-select v-model="form.marketId" url="/crm/market/dict?type=1"></good-select>
          </el-form-item>
          <el-form-item label="设备类型：" prop="type">
            <good-select v-model="form.type" url="/sys/dict/code/device_type"></good-select>
          </el-form-item>
          <el-form-item label="入库批次：" prop="stockIds">
            <good-select multiple v-model="form.stockIds" url="/erp/stockin/getDict"></good-select>
          </el-form-item>
          <el-form-item label="批次信息：" prop="remark">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
          <el-form-item label="品牌：" prop="brand">
            <good-select v-model="form.brand" url="/sys/dict/code/scale_type"></good-select>
          </el-form-item>
          <el-form-item label="型号：" prop="model">
            <good-select v-model="form.model" url="/sys/dict/code/scale_type"></good-select>
          </el-form-item>
          <el-form-item label="出库数量：" prop="stock">
            <el-input-number :min="1" v-model="form.stock" :step="1"></el-input-number>
          </el-form-item>
          <el-form-item label="出库时间：" prop="buyTime">
            <el-date-picker
                v-model="form.buyTime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="设备图片：" prop="pic">
            <good-cropper
                v-model="form.pic"
                :showFileList="false"
                :bucket="'zhsn-scale'"
                :initData="form.pic"
                :uploadClass="'upload-pic'"></good-cropper>
          </el-form-item>
          <el-form-item label="出库批次信息：" prop="remark">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="save(true)" :loading="loading.addAg">保存并提交</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select/index'
import GoodCropper from '@/components/good-cropper/index'
import { getDetail, submit } from '@/api/erp/stockout'

export default {
  mixins: [mixin],
  components: { GoodSelect, GoodCropper },
  data () {
    return {
      loading: {
        form: false
      },
      form: {
        marketId: undefined,
        type: undefined,
        model: undefined,
        stockIds: undefined,
        attachmentFlag: undefined,
        brand: undefined,
        retailPrice: undefined,
        stock: undefined,
        pic: undefined,
        buyTime: undefined,
        remark: undefined,
        status: undefined
      },
      rules: {
        marketId: [{ required: true, trigger: 'blur', message: '请选择市场' }],
        type: [{ required: true, trigger: 'blur', message: '请选择设备类型' }],
        stockIds: [{ required: true, trigger: 'blur', message: '请选择入库批次' }],
        remark: [{ required: false, trigger: 'blur', message: '请填写批次信息' }],
        brand: [{ required: false, trigger: 'blur', message: '请选择品牌' }],
        pic: [{ required: false, trigger: 'blur', message: '设备图片' }],
        stock: [{ required: true, trigger: 'blur', message: '请填写出库数量' }],
        buyTime: [{ required: false, trigger: 'blur', message: '请填写购买时间' }]
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  watch: {
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.erp_stockout_add,
        viewBtn: this.permission.erp_stockout_view,
        delBtn: this.permission.erp_stockout_delete,
        editBtn: this.permission.erp_stockout_edit
      }
    }
  },
  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
        if (this.form.stockIds) {
          this.form.stockIds = this.form.stockIds.split(',')
        }
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.stockIds) {
            this.form.stockIds = this.form.stockIds.join(',')
          }
          submit(this.form).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            } else {
              this.$message({
                type: 'false',
                message: res.msg
              })
            }
            this.loading.add = false
            if (res.code === 200) {
              this.form = {
                status: 1
              }
              this.$router.push({ path: '/erp/stockout' })
            }
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/erp/stockout' })
    }
  }
}
</script>
