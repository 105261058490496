<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.user_appuserdetail_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'user/appuserdetail',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '公众号appid',
            prop: 'appid',
            rules: [{
              required: true,
              message: '请输入公众号appid'
            }]
          },
          {
            label: 'appuserid',
            prop: 'appUserId',
            rules: [{
              required: true,
              message: '请输入appuserid'
            }]
          },
          {
            label: 'openid',
            prop: 'openId',
            rules: [{
              required: true,
              message: '请输入openid'
            }]
          },
          {
            label: '昵称',
            prop: 'nickname',
            rules: [{
              required: true,
              message: '请输入昵称'
            }]
          },
          {
            label: '性别',
            prop: 'sex',
            rules: [{
              required: true,
              message: '请输入性别'
            }]
          },
          {
            label: '语言',
            prop: 'language',
            rules: [{
              required: true,
              message: '请输入语言'
            }]
          },
          {
            label: '城市',
            prop: 'city',
            rules: [{
              required: true,
              message: '请输入城市'
            }]
          },
          {
            label: '省份',
            prop: 'province',
            rules: [{
              required: true,
              message: '请输入省份'
            }]
          },
          {
            label: '国家地区',
            prop: 'country',
            rules: [{
              required: true,
              message: '请输入国家地区'
            }]
          },
          {
            label: '微信头像',
            prop: 'headImgUrl',
            rules: [{
              required: true,
              message: '请输入微信头像'
            }]
          },
          {
            label: '订阅状态，0未关注，1已关注',
            prop: 'subscribe',
            rules: [{
              required: true,
              message: '请输入订阅状态，0未关注，1已关注'
            }]
          },
          {
            label: '订阅时间',
            prop: 'subscribeTime',
            rules: [{
              required: true,
              message: '请输入订阅时间'
            }]
          },
          {
            label: 'unionid',
            prop: 'subscribeScene',
            rules: [{
              required: true,
              message: '请输入unionid'
            }]
          },
          {
            label: '二维码扫码场景',
            prop: 'qrScene',
            rules: [{
              required: true,
              message: '请输入二维码扫码场景'
            }]
          },
          {
            label: 'unionid',
            prop: 'unionId',
            rules: [{
              required: true,
              message: '请输入unionid'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: true,
              message: '请输入备注'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            rules: [{
              required: true,
              message: '请输入修改时间'
            }]
          },
          {
            label: '乐观锁',
            prop: 'revision',
            rules: [{
              required: true,
              message: '请输入乐观锁'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.user_appuserdetail_add,
        viewBtn: this.permission.user_appuserdetail_view,
        delBtn: this.permission.user_appuserdetail_delete,
        editBtn: this.permission.user_appuserdetail_edit
      }
    }
  },
  methods: {
  }
}
</script>
