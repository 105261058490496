<template>
  <div>
    <el-card class="good-card">
      <div>
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            :inline-message="true"
            label-position="right"
            label-width="150px"
            class="small-space form-one">
          <el-form-item label="所属商户：" prop="ownerId">
            <good-select v-model="form.ownerId" url="/crm/merchant/dict?marketId="></good-select>
          </el-form-item>
          <el-form-item label="开户行：" prop="deposit">
            <el-input v-model="form.deposit"></el-input>
          </el-form-item>
          <el-form-item label="账户：" prop="account">
            <el-input v-model="form.account"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="save(true)" :loading="loading.addAg">保存并提交</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select/index'
import { getDetail, submit } from '@/api/crm/merchantBank'

export default {
  mixins: [mixin],
  components: { GoodSelect },
  data () {
    return {
      loading: {
        form: false
      },
      form: {
        ownerId: undefined,
        deposit: undefined,
        account: undefined,
        currency: 0,
        qrCode: undefined,
        status: undefined
      },
      rules: {
        ownerId: [{ required: true, trigger: 'blur', message: '请选择所属市场' }],
        deposit: [{ required: true, trigger: 'blur', message: '请输入开户行' }],
        account: [{ required: false, trigger: 'blur', message: '请输入账号' }],
        currency: [{ required: true, trigger: 'blur', message: '请选择币别' }],
        qrCode: [{ required: false, trigger: 'blur', message: '请上传二维码' }]
      }
    }
  },
  created () {
    this.form.ownerId = this.$route.query.ownerId
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_merchantBank_add,
        viewBtn: this.permission.crm_merchantBank_view,
        delBtn: this.permission.crm_merchantBank_delete,
        editBtn: this.permission.crm_merchantBank_edit
      }
    }
  },
  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          submit(this.form).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            } else {
              this.$message({
                type: 'false',
                message: res.msg
              })
            }
            this.loading.add = false
            if (res.code === 200) {
              this.form = {
                status: 1
              }
              this.$router.push({ path: '/crm/merchantBank' })
            }
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/crm/merchantBank' })
    }
  }
}
</script>
