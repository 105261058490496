<template>
  <div>
    <el-card class="good-card">
      <div>
        <el-form
                :model="form"
                ref="form"
                :rules="rules"
                :inline-message="true"
                label-position="right"
                label-width="150px"
                class="small-space form-one">
          <el-form-item label="公众号ID" prop="appid">
            <el-input v-model="form.appid"></el-input>
          </el-form-item>
          <el-form-item label="模板代码" prop="templateId">
            <el-input v-model="form.templateId"></el-input>
          </el-form-item>
          <el-form-item label="模板标题" prop="title">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="模板所属行业的一级行业" prop="primaryIndustry">
            <el-input v-model="form.primaryIndustry"></el-input>
          </el-form-item>
          <el-form-item label="模板所属行业的二级行业" prop="deputyIndustry">
            <el-input v-model="form.deputyIndustry"></el-input>
          </el-form-item>
          <el-form-item label="模板内容" prop="content">
            <el-input v-model="form.content"></el-input>
          </el-form-item>
          <el-form-item label="示例内容" prop="example">
            <el-input v-model="form.example"></el-input>
          </el-form-item>
          <el-form-item label="跳转小程序ID" prop="minaAppid">
            <el-input v-model="form.minaAppid"></el-input>
          </el-form-item>
          <el-form-item label="跳转小程序页面" prop="pagePath">
            <el-input v-model="form.pagePath"></el-input>
          </el-form-item>
          <el-form-item label="跳转url" prop="url">
            <el-input v-model="form.url"></el-input>
          </el-form-item>
          <el-form-item label="模板标签" prop="tag">
            <el-input v-model="form.tag"></el-input>
          </el-form-item>
          <el-form-item label="模板颜色" prop="color">
            <el-input v-model="form.color"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="save(true)">保存并提交</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import { submit, getDetail } from '@/api/mp/template'

export default {
  data () {
    return {
      total: null,
      list: null,
      listLoading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      showDialog: false,
      form: {
        id: undefined,
        appid: undefined,
        templateId: undefined,
        title: undefined,
        primaryIndustry: undefined,
        deputyIndustry: undefined,
        content: undefined,
        example: undefined,
        minaAppid: undefined,
        pagePath: undefined,
        url: undefined,
        tag: undefined,
        color: undefined,
        isDeleted: undefined
      },
      rules: {
        appid: [{ required: true, trigger: 'blur', message: '请输入 公众号ID' }],
        templateId: [{ required: true, trigger: 'blur', message: '请输入 模板代码' }],
        title: [{ required: true, trigger: 'blur', message: '请输入 模板标题' }],
        content: [{ required: true, trigger: 'blur', message: '请输入 模板内容' }],
        example: [{ required: true, trigger: 'blur', message: '请输入 内容示例' }]
      }
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },

  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          submit(this.form).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            } else {
              this.$message({
                type: 'false',
                message: res.msg
              })
            }
            if (res.code === 200) {
              this.$router.push({ path: '/mp/template' })
            }
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/mp/template' })
    }
  }

}
</script>
