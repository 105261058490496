<template>
  <!-- https://blog.csdn.net/baidu_27769027/article/details/102906967
  https://yujinpan.github.io/el-select-tree/ -->
  <ElSelectTree
      v-model="name"
      :data="options"
      check-strictly
  ></ElSelectTree>
</template>

<script>
import { getDicDataByUrl } from '@/api/sys/dict'
import ElSelectTree from 'el-select-tree'
import Vue from 'vue'
Vue.use(ElSelectTree)

export default {
  name: 'GoodSelectTree',
  props: {
    url: {
      default: '',
      type: String
    },
    popperClass: {
      default: '',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    multiple: {
      default: false,
      type: Boolean
    },
    clearable: {
      default: true,
      type: Boolean
    },
    value: {
      default: null,
      type: [String, Number, Array]
    },
    allowAdd: {
      default: false,
      type: Boolean
    },
    itemAdd: {
      default: null,
      type: Function
    },
    itemAdded: {
      default: null,
      type: Function
    },
    needRefresh: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      name: '',
      options: []
    }
  },
  created () {
    this.refresh()
  },
  watch: {
    needRefresh: {
      handler (val) {
        if (val) {
          this.refresh()
        }
      }
    }
  },
  methods: {
    change: function (val) {
      if (val === '+' || (this.multiple && val[val.length - 1] === '+')) {
        this.itemAdd()
      } else {
        this.$emit('input', val)
      }
    },
    refresh: function () {
      if (this.url) {
        getDicDataByUrl(this.url).then(res => {
          this.options = res.data
          console.log('good-select-tree')
          console.log(this.options)
          this.options = [{
            label: '苏州市',
            value: '1',
            children: [
              { label: '虎丘区', value: '11' },
              { label: '吴中区', value: '12' },
              { label: '相城区', value: '13' }
            ]
          }, {
            label: '南京',
            value: '2',
            children: [
              { label: '玄武区', value: '21' },
              { label: '建邺区', value: '22' },
              { label: '秦淮区', value: '23' }
            ]
          }, {
            label: '无锡',
            value: '3'
          }]
        })
      }
      this.$on('handleOptionClick', this.handleOptionSelect)
    }
  }
}
</script>
