export default [
  {
    label: 'eleme图标',
    list: [
      'el-icon-platform-eleme',
      ',el-icon-eleme',
      'el-icon-delete-solid',
      'el-icon-delete',
      'el-icon-s-tools',
      'el-icon-setting',
      'el-icon-user-solid',
      'el-icon-user',
      'el-icon-phone',
      'el-icon-phone-outline',
      'el-icon-more',
      'el-icon-more-outline',
      'el-icon-star-on',
      'el-icon-star-off',
      'el-icon-s-goods',
      'el-icon-goods',
      'el-icon-warning',
      'el-icon-warning-outline',
      'el-icon-question',
      'el-icon-info',
      'el-icon-remove',
      'el-icon-circle-plus',
      'el-icon-success',
      'el-icon-error',
      'el-icon-zoom-in',
      'el-icon-zoom-out',
      'el-icon-remove-outline',
      'el-icon-circle-plus-outline',
      'el-icon-circle-check',
      'el-icon-circle-close',
      'el-icon-s-help',
      'el-icon-help',
      'el-icon-minus',
      'el-icon-plus',
      'el-icon-check',
      'el-icon-close',
      'el-icon-picture',
      'el-icon-picture-outline',
      'el-icon-picture-outline-round',
      'el-icon-upload',
      'el-icon-upload2',
      'el-icon-download',
      'el-icon-camera-solid',
      'el-icon-camera',
      'el-icon-video-camera-solid',
      'el-icon-video-camera',
      'el-icon-message-solid',
      'el-icon-bell',
      'el-icon-s-cooperation',
      'el-icon-s-order',
      'el-icon-s-platform',
      'el-icon-s-fold',
      'el-icon-s-unfold',
      'el-icon-s-operation',
      'el-icon-s-promotion',
      'el-icon-s-home',
      'el-icon-s-release',
      'el-icon-s-ticket',
      'el-icon-s-management',
      'el-icon-s-open',
      'el-icon-s-shop',
      'el-icon-s-marketing',
      'el-icon-s-flag',
      'el-icon-s-comment',
      'el-icon-s-finance',
      'el-icon-s-claim',
      'el-icon-s-custom',
      'el-icon-s-opportunity',
      'el-icon-s-data',
      'el-icon-s-check',
      'el-icon-s-grid',
      'el-icon-menu',
      'el-icon-share',
      'el-icon-d-caret',
      'el-icon-caret-left',
      'el-icon-caret-right',
      'el-icon-caret-bottom',
      'el-icon-caret-top',
      'el-icon-bottom-left',
      'el-icon-bottom-right',
      'el-icon-back',
      'el-icon-right',
      'el-icon-bottom',
      'el-icon-top',
      'el-icon-top-left',
      'el-icon-top-right',
      'el-icon-arrow-left',
      'el-icon-arrow-right',
      'el-icon-arrow-down',
      'el-icon-arrow-up',
      'el-icon-d-arrow-left',
      'el-icon-d-arrow-right',
      'el-icon-video-pause',
      'el-icon-video-play',
      'el-icon-refresh',
      'el-icon-refresh-right',
      'el-icon-refresh-left',
      'el-icon-finished',
      'el-icon-sort',
      'el-icon-sort-up',
      'el-icon-sort-down',
      'el-icon-rank',
      'el-icon-loading',
      'el-icon-view',
      'el-icon-c-scale-to-original',
      'el-icon-date',
      'el-icon-edit',
      'el-icon-edit-outline',
      'el-icon-folder',
      'el-icon-folder-opened',
      'el-icon-folder-add',
      'el-icon-folder-remove',
      'el-icon-folder-delete',
      'el-icon-folder-checked',
      'el-icon-tickets',
      'el-icon-document-remove',
      'el-icon-document-delete',
      'el-icon-document-copy',
      'el-icon-document-checked',
      'el-icon-document',
      'el-icon-document-add',
      'el-icon-printer',
      'el-icon-paperclip',
      'el-icon-takeaway-box',
      'el-icon-search',
      'el-icon-monitor',
      'el-icon-attract',
      'el-icon-mobile',
      'el-icon-scissors',
      'el-icon-umbrella',
      'el-icon-headset',
      'el-icon-brush',
      'el-icon-mouse',
      'el-icon-coordinate',
      'el-icon-magic-stick',
      'el-icon-reading',
      'el-icon-data-line',
      'el-icon-data-board',
      'el-icon-pie-chart',
      'el-icon-data-analysis',
      'el-icon-collection-tag',
      'el-icon-film',
      'el-icon-suitcase',
      'el-icon-suitcase-1',
      'el-icon-receiving',
      'el-icon-collection',
      'el-icon-files',
      'el-icon-notebook-1',
      'el-icon-notebook-2',
      'el-icon-toilet-paper',
      'el-icon-office-building',
      'el-icon-school',
      'el-icon-table-lamp',
      'el-icon-house',
      'el-icon-no-smoking',
      'el-icon-smoking',
      'el-icon-shopping-cart-full',
      'el-icon-shopping-cart-1',
      'el-icon-shopping-cart-2',
      'el-icon-shopping-bag-1',
      'el-icon-shopping-bag-2',
      'el-icon-sold-out',
      'el-icon-sell',
      'el-icon-present',
      'el-icon-box',
      'el-icon-bank-card',
      'el-icon-money',
      'el-icon-coin',
      'el-icon-wallet',
      'el-icon-discount',
      'el-icon-price-tag',
      'el-icon-news',
      'el-icon-guide',
      'el-icon-male',
      'el-icon-female',
      'el-icon-thumb',
      'el-icon-cpu',
      'el-icon-link',
      'el-icon-connection',
      'el-icon-open',
      'el-icon-turn-off',
      'el-icon-set-up',
      'el-icon-chat-round',
      'el-icon-chat-line-round',
      'el-icon-chat-square',
      'el-icon-chat-dot-round',
      'el-icon-chat-dot-square',
      'el-icon-chat-line-square',
      'el-icon-message',
      'el-icon-postcard',
      'el-icon-position',
      'el-icon-turn-off-microphone',
      'el-icon-microphone',
      'el-icon-close-notification',
      'el-icon-bangzhu',
      'el-icon-time',
      'el-icon-odometer',
      'el-icon-crop',
      'el-icon-aim',
      'el-icon-switch-button',
      'el-icon-full-screen',
      'el-icon-copy-document',
      'el-icon-mic',
      'el-icon-stopwatch',
      'el-icon-medal-1',
      'el-icon-medal',
      'el-icon-trophy',
      'el-icon-trophy-1',
      'el-icon-first-aid-kit',
      'el-icon-discover',
      'el-icon-place',
      'el-icon-location',
      'el-icon-location-outline',
      'el-icon-location-information',
      'el-icon-add-location',
      'el-icon-delete-location',
      'el-icon-map-location',
      'el-icon-alarm-clock',
      'el-icon-timer',
      'el-icon-watch-1',
      'el-icon-watch',
      'el-icon-lock',
      'el-icon-unlock',
      'el-icon-key',
      'el-icon-service',
      'el-icon-mobile-phone',
      'el-icon-bicycle',
      'el-icon-truck',
      'el-icon-ship',
      'el-icon-basketball',
      'el-icon-football',
      'el-icon-soccer',
      'el-icon-baseball',
      'el-icon-wind-power',
      'el-icon-light-rain',
      'el-icon-lightning',
      'el-icon-heavy-rain',
      'el-icon-sunrise',
      'el-icon-sunrise-1',
      'el-icon-sunset',
      'el-icon-sunny',
      'el-icon-cloudy',
      'el-icon-partly-cloudy',
      'el-icon-cloudy-and-sunny',
      'el-icon-moon',
      'el-icon-moon-night',
      'el-icon-dish',
      'el-icon-dish-1',
      'el-icon-food',
      'el-icon-chicken',
      'el-icon-fork-spoon',
      'el-icon-knife-fork',
      'el-icon-burger',
      'el-icon-tableware',
      'el-icon-sugar',
      'el-icon-dessert',
      'el-icon-ice-cream',
      'el-icon-hot-water',
      'el-icon-water-cup',
      'el-icon-coffee-cup',
      'el-icon-cold-drink',
      'el-icon-goblet',
      'el-icon-goblet-full',
      'el-icon-goblet-square',
      'el-icon-goblet-square-full',
      'el-icon-refrigerator',
      'el-icon-grape',
      'el-icon-watermelon',
      'el-icon-cherry',
      'el-icon-apple',
      'el-icon-pear',
      'el-icon-orange',
      'el-icon-coffee',
      'el-icon-ice-tea',
      'el-icon-ice-drink',
      'el-icon-milk-tea',
      'el-icon-potato-strips',
      'el-icon-lollipop',
      'el-icon-ice-cream-square',
      'el-icon-ice-cream-round'
    ]
  },
  {
    label: '通用图标',
    list: [
      'iconfont iconicon_roundadd',
      'iconfont iconicon_compile',
      'iconfont iconicon_glass',
      'iconfont iconicon_roundclose',
      'iconfont iconicon_roundreduce',
      'iconfont iconicon_delete',
      'iconfont iconicon_shakehands',
      'iconfont iconicon_task_done',
      'iconfont iconicon_voipphone',
      'iconfont iconicon_safety',
      'iconfont iconicon_work',
      'iconfont iconicon_study',
      'iconfont iconicon_task',
      'iconfont iconicon_subordinate',
      'iconfont iconicon_star',
      'iconfont iconicon_setting',
      'iconfont iconicon_sms',
      'iconfont iconicon_share',
      'iconfont iconicon_secret',
      'iconfont iconicon_scan_namecard',
      'iconfont iconicon_principal',
      'iconfont iconicon_group',
      'iconfont iconicon_send',
      'iconfont iconicon_scan',
      'iconfont iconicon_search',
      'iconfont iconicon_refresh',
      'iconfont iconicon_savememo',
      'iconfont iconicon_QRcode',
      'iconfont iconicon_im_keyboard',
      'iconfont iconicon_redpacket',
      'iconfont iconicon_photo',
      'iconfont iconicon_qq',
      'iconfont iconicon_wechat',
      'iconfont iconicon_phone',
      'iconfont iconicon_namecard',
      'iconfont iconicon_notice',
      'iconfont iconicon_next_arrow',
      'iconfont iconicon_left',
      'iconfont iconicon_more',
      'iconfont iconicon_details',
      'iconfont iconicon_message',
      'iconfont iconicon_mobilephone',
      'iconfont iconicon_im_voice',
      'iconfont iconicon_GPS',
      'iconfont iconicon_ding',
      'iconfont iconicon_exchange',
      'iconfont iconicon_cspace',
      'iconfont iconicon_doc',
      'iconfont iconicon_dispose',
      'iconfont iconicon_discovery',
      'iconfont iconicon_community_line',
      'iconfont iconicon_cloud_history',
      'iconfont iconicon_coinpurse_line',
      'iconfont iconicon_airplay',
      'iconfont iconicon_at',
      'iconfont iconicon_addressbook',
      'iconfont iconicon_boss',
      'iconfont iconicon_addperson',
      'iconfont iconicon_affiliations_li',
      'iconfont iconicon_addmessage',
      'iconfont iconicon_addresslist',
      'iconfont iconicon_add'
    ]
  },
  {
    label: '系统图标',
    list: [
      'iconfont icon-zhongyingwen',
      'iconfont icon-caidan',
      'iconfont icon-rizhi1',
      'iconfont icon-zhuti',
      'iconfont icon-suoping',
      'iconfont icon-bug',
      'iconfont icon-qq1',
      'iconfont icon-weixin1',
      'iconfont icon-shouji',
      'iconfont icon-mima',
      'iconfont icon-yonghu',
      'iconfont icon-yanzhengma',
      'iconfont icon-canshu',
      'iconfont icon-dongtai',
      'iconfont icon-iconset0265',
      'iconfont icon-shujuzhanshi2',
      'iconfont icon-tuichuquanping',
      'iconfont icon-rizhi',
      'iconfont icon-cuowutishitubiao',
      'iconfont icon-debug',
      'iconfont icon-iconset0216',
      'iconfont icon-quanxian',
      'iconfont icon-quanxian',
      'iconfont icon-shuaxin',
      'iconfont icon-bofangqi-suoping',
      'iconfont icon-quanping',
      'iconfont icon-navicon',
      'iconfont icon-biaodan',
      'iconfont icon-liuliangyunpingtaitubiao08',
      'iconfont icon-caidanguanli',
      'iconfont icon-cuowu',
      'iconfont icon-wxbgongju',
      'iconfont icon-tuichu',
      'iconfont icon-daohanglanmoshi02',
      'iconfont icon-changyonglogo27',
      'iconfont icon-biaoge',
      'iconfont icon-baidu1',
      'iconfont icon-tubiao',
      'iconfont icon-souhu',
      'iconfont icon-msnui-360',
      'iconfont icon-iframe',
      'iconfont icon-huanyingye'
    ]
  },
  {
    label: 'good图标',
    list: [
      'iconfont icon-menu1',
      'iconfont icon-menu',
      'iconfont icon-shebeiyichang',
      'iconfont icon-ruzhangjineyuan',
      'iconfont icon-average',
      'iconfont icon-bk-login-exception',
      'iconfont icon-icon-test1',
      'iconfont icon-icon-test',
      'iconfont icon-zhtn',
      'iconfont icon-commodity',
      'iconfont icon-int',
      'iconfont icon-tools',
      'iconfont icon-supply',
      'iconfont icon-questions',
      'iconfont icon-qianniudaidise',
      'iconfont icon-budaidise',
      'iconfont icon-decoration_fill',
      'iconfont icon-shake',
      'iconfont icon-marketing_fill',
      'iconfont icon-financial_fill',
      'iconfont icon-wangwang',
      'iconfont icon-unfold',
      'iconfont icon-packup',
      'iconfont icon-transaction_fill',
      'iconfont icon-shop_fill',
      'iconfont icon-publishgoods_fill',
      'iconfont icon-qianniu',
      'iconfont icon-searchfill',
      'iconfont icon-search',
      'iconfont icon-workbench',
      'iconfont icon-workbench_fill',
      'iconfont icon-warning',
      'iconfont icon-warning_fill',
      'iconfont icon-video_fill',
      'iconfont icon-video',
      'iconfont icon-unlock',
      'iconfont icon-unlock_fill',
      'iconfont icon-undo',
      'iconfont icon-trash_fill',
      'iconfont icon-trash',
      'iconfont icon-translation',
      'iconfont icon-translation_fill',
      'iconfont icon-time',
      'iconfont icon-time_fill',
      'iconfont icon-text',
      'iconfont icon-tasklist',
      'iconfont icon-tasklist_fill',
      'iconfont icon-task_fill',
      'iconfont icon-task',
      'iconfont icon-tailor',
      'iconfont icon-systemprompt',
      'iconfont icon-systemprompt_fill',
      'iconfont icon-switch',
      'iconfont icon-suspend',
      'iconfont icon-success',
      'iconfont icon-success_fill',
      'iconfont icon-stealth',
      'iconfont icon-stealth_fill',
      'iconfont icon-smallscreen',
      'iconfont icon-smallscreen_fill',
      'iconfont icon-shielding',
      'iconfont icon-shielding_fill',
      'iconfont icon-share',
      'iconfont icon-share_fill',
      'iconfont icon-setup',
      'iconfont icon-setup_fill',
      'iconfont icon-service',
      'iconfont icon-service_fill',
      'iconfont icon-send',
      'iconfont icon-select',
      'iconfont icon-select_fill',
      'iconfont icon-scan',
      'iconfont icon-right',
      'iconfont icon-return',
      'iconfont icon-remind',
      'iconfont icon-remind_fill',
      'iconfont icon-refresh',
      'iconfont icon-redpacket',
      'iconfont icon-redpacket_fill',
      'iconfont icon-qrcode',
      'iconfont icon-qrcode_fill',
      'iconfont icon-prompt',
      'iconfont icon-prompt_fill',
      'iconfont icon-praise',
      'iconfont icon-praise_fill',
      'iconfont icon-playon',
      'iconfont icon-playon_fill',
      'iconfont icon-play_fill',
      'iconfont icon-play',
      'iconfont icon-picture',
      'iconfont icon-picture_fill',
      'iconfont icon-people',
      'iconfont icon-people_fill',
      'iconfont icon-other',
      'iconfont icon-order',
      'iconfont icon-order_fill',
      'iconfont icon-offline',
      'iconfont icon-offline_fill',
      'iconfont icon-narrow',
      'iconfont icon-more',
      'iconfont icon-mobilephone',
      'iconfont icon-mobilephone_fill',
      'iconfont icon-mine_fill',
      'iconfont icon-mine',
      'iconfont icon-message_fill',
      'iconfont icon-message',
      'iconfont icon-manage',
      'iconfont icon-manage_fill',
      'iconfont icon-mail_fill',
      'iconfont icon-mail',
      'iconfont icon-lock1',
      'iconfont icon-lock_fill',
      'iconfont icon-live',
      'iconfont icon-live_fill',
      'iconfont icon-like',
      'iconfont icon-like_fill',
      'iconfont icon-label_fill',
      'iconfont icon-label',
      'iconfont icon-keyboard',
      'iconfont icon-interactive',
      'iconfont icon-interactive_fill',
      'iconfont icon-integral',
      'iconfont icon-integral_fill',
      'iconfont icon-homepage',
      'iconfont icon-homepage_fill',
      'iconfont icon-headlines',
      'iconfont icon-headlines_fill',
      'iconfont icon-group_fill',
      'iconfont icon-group',
      'iconfont icon-fullscreen',
      'iconfont icon-flip_fill',
      'iconfont icon-flip',
      'iconfont icon-flashlight_fill',
      'iconfont icon-flashlight',
      'iconfont icon-flag',
      'iconfont icon-flag_fill',
      'iconfont icon-feedback',
      'iconfont icon-feedback_fill',
      'iconfont icon-enterinto_fill',
      'iconfont icon-enterinto',
      'iconfont icon-enter',
      'iconfont icon-empty_fill',
      'iconfont icon-empty',
      'iconfont icon-emoji',
      'iconfont icon-emoji_fill',
      'iconfont icon-eit',
      'iconfont icon-editor',
      'iconfont icon-dynamic',
      'iconfont icon-dynamic_fill',
      'iconfont icon-document_fill',
      'iconfont icon-document',
      'iconfont icon-delete',
      'iconfont icon-delete_fill',
      'iconfont icon-customerservice',
      'iconfont icon-customerservice_fill',
      'iconfont icon-createtask',
      'iconfont icon-createtask_fill',
      'iconfont icon-coupons',
      'iconfont icon-coupons_fill',
      'iconfont icon-coordinates',
      'iconfont icon-coordinates_fill',
      'iconfont icon-fanganguihua',
      'iconfont icon-jiaoseguanli',
      'iconfont icon-yonghuguanli',
      'iconfont icon-institute',
      'iconfont icon-caidanguanli',
      'iconfont icon-zidian',
      'iconfont icon-liucheng',
      'iconfont icon-fuwuquyu',
      'iconfont icon-quanxianguanli',
      'iconfont icon-peizhiconfiguration15',
      'iconfont icon-weibiaoti13',
      'iconfont icon-xitongcanshu',
      'iconfont icon-yuan',
      'iconfont icon-quanping',
      'iconfont icon-tuichuquanping',
      'iconfont icon-jiesuo',
      'iconfont icon-tuichu',
      'iconfont icon-shishishujuzhanshi',
      'iconfont icon-shujuzhanshi',
      'iconfont icon-shujuzhanshi2',
      'iconfont icon-lock'
    ]
  }
]
