<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.crm_market_delete"
                @click="handleDelete">删 除
        </el-button>
        <el-button type="success" @click.stop="handleMarketExport()">导出</el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-view"
            size="small"
            @click.stop="toMerchant(row)">商户
        </el-button>
        <el-button
            type="text"
            icon="el-icon-view"
            size="small"
            @click.stop="toMarketPerson(row)">人员
        </el-button>
        <el-button
            type="text"
            icon="el-icon-view"
            size="small"
            @click.stop="toBank(row)">开票
        </el-button>
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import FileSaver from 'file-saver'
import { formatDate } from '@/util/date'
import { exportExcel } from '@/api/common'
export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/marketCraft',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        searchMenuSpan: 5,
        align: 'center',
        column: [
          {
            label: '所属公司',
            prop: 'parentId',
            search: true,
            type: 'select',
            dicUrl: '/crm/market/dict?type=0'
          },
          {
            label: '市场全称',
            prop: 'fullName',
            search: true,
            width: 200
          },
          {
            label: '统一编号',
            prop: 'businessLicense',
            width: 150
          },
          {
            label: '注册时间',
            prop: 'registrationTime',
            width: 90
          },
          {
            label: '市场地址',
            prop: 'address',
            width: 200
          },
          {
            label: '区域',
            prop: 'area',
            width: 200,
            type: 'select',
            dicUrl: '/sys/dict/code/area_type'
          },
          {
            label: '市场性质',
            prop: 'companyType',
            type: 'select',
            dicUrl: '/sys/dict/code/market_type'
          },
          {
            label: '法人',
            prop: 'legalPerson',
            search: true
          },
          {
            label: '电话',
            prop: 'legalPhone',
            width: 95
          },
          {
            label: '备案时间',
            prop: 'referenceTime',
            width: 90
          },
          {
            label: '营业执照',
            prop: 'registrationUrl',
            type: 'img',
            width: 100
          },
          {
            label: '法人身份证正面',
            prop: 'legalPersonCdCardUrl',
            type: 'img',
            width: 120
          },
          {
            label: '法人身份证反面',
            prop: 'legalPersonCdCard2Url',
            type: 'img',
            width: 120
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_marketCraft_add,
        viewBtn: this.permission.crm_marketCraft_view,
        delBtn: this.permission.crm_marketCraft_delete,
        editBtn: this.permission.crm_marketCraft_edit
      }
    }
  },
  methods: {
    toAdd () {
      this.$router.push({ path: '/crm/marketEdit' })
    },
    toEdit (row) {
      this.$router.push({
        path: '/crm/marketEdit',
        query: { id: row.id }
      })
    },
    handleMarketExport () {
      exportExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '市场在途信息' + formatDate(new Date(), 'yyyyMMddhhmmss') + '.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    },
    toMerchant (row) {
      this.$router.push({
        path: '/crm/merchant',
        query: { marketId: row.id }
      })
    },
    toMarketPerson (row) {
      this.$router.push({
        path: '/crm/marketPerson',
        query: { merchantId: row.id }
      })
    },
    toBank (row) {
      this.$router.push({
        path: '/crm/marketBank',
        query: { ownerId: row.id }
      })
    }
  }
}
</script>
