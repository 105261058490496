<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <div class="start">
          <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              v-if="permission.crm_company_add"
              @click.stop="toAdd()"
          >新增
          </el-button>
        </div>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            v-if="permission.crm_company_edit"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            v-if="permission.crm_company_delete"
            @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
      <template slot="search">
        <el-form align="left" :inline="true">
          <el-form-item label="关键字:">
            <el-input placeholder="名称、姓名、手机等" v-model="search.query"></el-input>
          </el-form-item>
        </el-form>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { bindColumns } from '@/api/base/enterprise'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'crm/company',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        align: 'center',
        column: bindColumns([
          {
            label: '简称',
            prop: 'name'
          }], [
          {
            label: '经营地址',
            width: 200,
            prop: 'address'
          },
          {
            label: '备案时间',
            prop: 'applyTime',
            type: 'date',
            width: 150
          },
          {
            label: '状态',
            prop: 'status',
            type: 'radio',
            dicUrl: 'sys/dict/code/apply_status'
          }
        ])
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_company_add,
        viewBtn: this.permission.crm_company_view,
        delBtn: this.permission.crm_company_delete,
        editBtn: this.permission.crm_company_edit
      }
    }
  },
  methods: {
    toAdd () {
      this.$router.push({
        path: '/crm/companyEdit'
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/crm/companyEdit',
        query: { id: row.id }
      })
    }
  }
}
</script>
