import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/crm/market/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDict = (params) => {
  return request({
    url: '/crm/market/dict',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const getCurrent = (linkmanId) => {
  return request({
    url: '/crm/market/current?linkmanId=' + linkmanId,
    method: 'get'
  })
}

export const getDetail = (id) => {
  return request({
    url: '/crm/market/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/crm/market/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/crm/market/submit',
    method: 'post',
    data: row
  })
}

