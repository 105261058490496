<template>
  <basic-container>
    <el-form ref="form" :model="form" label-width="80px">
      <el-row type="flex" class="row-bg" justify="end">
        <el-form-item>
          <el-button type="primary" @click="handleAgree">同意</el-button>
          <el-button type="danger" @click="handleDisagree">退回</el-button>
          <el-button @click="handleCancel">关闭</el-button>
        </el-form-item>
      </el-row>
      <el-card shadow="hover">
        <div slot="header">
          <span>审批信息</span>
        </div>
        <el-form-item label="申请人">
          <el-input :disabled="true" v-model="form.flow.assigneeName" />
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开始时间">
              <el-input :disabled="true" v-model="form.startTime" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间">
              <el-input :disabled="true" v-model="form.endTime" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="请假理由">
          <el-input :disabled="true" type="textarea" v-model="form.reason" />
        </el-form-item>
        <el-form-item label="审核意见">
          <el-input type="textarea" v-model="form.comment" />
        </el-form-item>
      </el-card>
      <el-card shadow="hover">
        <div slot="header">
          <span>流程信息</span>
        </div>
        <el-row type="flex" class="row-bg">
          <el-timeline>
            <el-timeline-item :key="flow.id" :timestamp="flow.createTime" v-for="flow in flowList" placement="top">
              <el-card shadow="hover">
                <p>{{flow.assigneeName}} 在 [{{flow.createTime}}] 开始处理 [{{flow.historyActivityName}}] 环节</p>
                <p v-if="flow.historyActivityDurationTime!==''">任务历时 [{{flow.historyActivityDurationTime}}]</p>
                <p v-if="flow.comment!==''">审核意见: [{{flow.comment}}]</p>
                <p v-if="flow.endTime!==''">结束时间: [{{flow.endTime}}]</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </el-row>
      </el-card>
      <el-card shadow="hover">
        <div slot="header">
          <span>流程跟踪</span>
        </div>
        <el-row type="flex" class="row-bg">
          <img :src="src" alt="design">
        </el-row>
      </el-card>
    </el-form>
  </basic-container>
</template>

<script>
import { historyFlowList } from '@/api/work/process'
import { completeTask } from '@/api/work/work'
import { getDetail } from '@/api/process/leave'

import { flowUrl } from '@/config'
export default {
  data () {
    return {
      taskId: '',
      businessId: '',
      processInstanceId: '',
      src: '',
      flowList: [],
      form: {
        flow: {
          assigneeName: ''
        },
        startTime: '',
        endTime: '',
        reason: '',
        comment: ''

      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.taskId = this.$route.params.taskId
      this.processInstanceId = this.$route.params.processInstanceId
      this.businessId = this.$route.params.businessId
      this.src = `${flowUrl}${this.$route.params.processInstanceId}&v=` + new Date().getTime()
      historyFlowList(this.processInstanceId).then(res => {
        if (res.success) {
          this.flowList = res.data
        }
      })
      getDetail(this.businessId).then(res => {
        if (res.success) {
          this.form = res.data
        }
      })
    },
    handleAgree () {
      if (!this.form.comment) {
        this.$message.warning('请先填写审核意见')
        return
      }
      const params = {
        taskId: this.taskId,
        processInstanceId: this.processInstanceId,
        flag: 'ok',
        comment: this.form.comment
      }
      completeTask(params).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.$router.push({ path: `/work/start` })
        } else {
          this.$message.error(res.msg || '提交失败')
        }
      })
    },
    handleDisagree () {
      if (!this.form.comment) {
        this.$message.warning('请先填写审核意见')
        return
      }
      const params = {
        taskId: this.taskId,
        processInstanceId: this.processInstanceId,
        comment: this.form.comment
      }
      completeTask(params).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.$router.push({ path: `/work/start` })
        } else {
          this.$message.error(res.msg || '提交失败')
        }
      })
    },
    handleCancel () {
      this.$router.push({ path: `/work/start` })
    }
  }
}
</script>
