import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/base/enterprise/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/base/enterprise/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const getByCreditCode = (module, creditCode) => {
  return request({
    url: module + '/detail/creditCode',
    method: 'get',
    params: {
      creditCode
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/base/enterprise/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/base/enterprise/submit',
    method: 'post',
    data: row
  })
}

export const recognize = (path, bucket) => {
  return request({
    url: '/base/enterprise/ocr',
    method: 'post',
    data: { path, bucket }
  })
}

export const getColumns = () => {
  return [
    {
      label: '全称',
      prop: 'fullName',
      width: 180
    },
    {
      label: '统一社会信用代码',
      width: 160,
      prop: 'creditCode'
    },
    {
      label: '营业执照',
      width: 160,
      prop: 'creditUrl',
      type: 'img'
    },
    {
      label: '法定代表人姓名',
      width: 150,
      prop: 'legalPersonName'
    },
    {
      label: '成立时间',
      prop: 'beginAt',
      width: 100,
      type: 'date'
    },
    {
      label: '是否长期',
      prop: 'isLongTerm',
      type: 'radio',
      dicUrl: '/sys/dict/code/yes_no'
    },
    {
      label: '有效期止',
      prop: 'operatingTo',
      width: 100,
      type: 'date'
    },
    {
      label: '注册地址',
      width: 250,
      prop: 'address'
    },
    {
      label: '联系电话',
      width: 120,
      prop: 'phone'
    },
    {
      label: '性质',
      prop: 'type',
      type: 'select',
      dicUrl: '/sys/dict/code/enterprise_type'
    },
    {
      label: '注册资本',
      prop: 'registeredCapital',
      type: 'number'
    },
    {
      label: '状态',
      prop: 'status',
      type: 'select',
      dicUrl: '/sys/dict/code/present_status'
    },
    {
      label: '排序',
      prop: 'sort',
      type: 'number'
    }
  ]
}

export const bindColumns = (columns1, columns2) => {
  const enterprises = getColumns()
  enterprises.forEach(item => {
    const prop = item.prop
    item.bind = 'enterprise.' + prop
  })
  if (!columns1) {
    columns1 = []
  }
  columns1 = columns1.concat(enterprises)
  if (columns2) {
    columns1 = columns1.concat(columns2)
  }
  return columns1
}
