import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/crm/devicesn/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/crm/devicesn/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/crm/devicesn/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const unbind = (module, ids) => {
  return request({
    url: '/crm/devicesn/unbind',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/crm/devicesn/submit',
    method: 'post',
    data: row
  })
}

export const batch = (row) => {
  return request({
    url: '/crm/devicesn/batch',
    method: 'post',
    data: row
  })
}

