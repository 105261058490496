<template>
  <basic-container>
    <el-collapse-transition>
      <el-form :inline="true" class="search-form">
        <el-form-item>
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="类型"
              v-model="page.type"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item search" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-collapse-transition>
    <el-row class="padding-row">
      <el-button type="primary" icon="el-icon-add" @click="handleCreate">新增</el-button>
    </el-row>
    <el-table
        class="good-table"
        :data="list"
        v-loading.body="listLoading"
        element-loading-text="拼命加载中"
        border
        fit
        highlight-current-row>
          <el-table-column label="主键" prop="id">
            <template slot-scope="scope">
              <span>{{scope.row.id}}</span>
            </template>
          </el-table-column>
          <el-table-column label="开户行编号" prop="bankId">
            <template slot-scope="scope">
              <span>{{scope.row.bankId}}</span>
            </template>
          </el-table-column>
          <el-table-column label="开户营业网点编号" prop="siteId">
            <template slot-scope="scope">
              <span>{{scope.row.siteId}}</span>
            </template>
          </el-table-column>
          <el-table-column label="账号" prop="account">
            <template slot-scope="scope">
              <span>{{scope.row.account}}</span>
            </template>
          </el-table-column>
          <el-table-column label="币别，参见字典表currency_type" prop="currency">
            <template slot-scope="scope">
              <span>{{scope.row.currency}}</span>
            </template>
          </el-table-column>
          <el-table-column label="所属方id" prop="ownerId">
            <template slot-scope="scope">
              <span>{{scope.row.ownerId}}</span>
            </template>
          </el-table-column>
          <el-table-column label="所属方类型，参见字典表owner_type" prop="ownerType">
            <template slot-scope="scope">
              <span>{{scope.row.ownerType}}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="status">
            <template slot-scope="scope">
              <span>{{scope.row.status}}</span>
            </template>
          </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="handleUpdate(scope.row.id)">编辑</el-button>
          <el-button
v-if="scope.row.status!='deleted'"
size="small"
type="danger"
                     @click="handleDelete(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="!listLoading" class="end pagination-container">
      <el-pagination
:current-page.sync="page.currentPage"
background
                     @current-change="handleCurrentChange"
                     @size-change="handleSizeChange"
                     :page-sizes="[10,20,30, 50]"
                     :page-size="page.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
:total="total">
      </el-pagination>
    </div>
    <el-dialog title="银行开户信息" :visible.sync="showDialog">
      <el-row :gutter="20">
        <el-form
:model="form"
ref="form"
:rules="rules"
label-position="left"
                 label-width="120px"
class="small-space"
style='padding:20px 60px;'>
              <el-col :span="12">
                <el-form-item label="主键：" prop="id">
                  <el-input v-model="form.id"  disabled ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="开户行编号：" prop="bankId">
                  <el-input v-model="form.bankId" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="开户营业网点编号：" prop="siteId">
                  <el-input v-model="form.siteId" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="账号：" prop="account">
                  <el-input v-model="form.account" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="币别，参见字典表currency_type：" prop="currency">
                  <el-input v-model="form.currency" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属方id：" prop="ownerId">
                  <el-input v-model="form.ownerId" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属方类型，参见字典表owner_type：" prop="ownerType">
                  <el-input v-model="form.ownerType" ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="状态：" prop="status">
                  <el-input v-model="form.status" ></el-input>
                </el-form-item>
              </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import { getList, remove, submit, getDetail } from '@/api/bank/account'

export default {
  data () {
    return {
      total: null,
      list: null,
      listLoading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      showDialog: false,
      form: {
        id: undefined,
        bankId: undefined,
        siteId: undefined,
        account: undefined,
        currency: undefined,
        ownerId: undefined,
        ownerType: undefined,
        status: undefined
      },
      rules: {
        bankId: [{ required: true, trigger: 'blur', message: '请输入 开户行编号' }],
        siteId: [{ required: true, trigger: 'blur', message: '请输入 开户营业网点编号' }],
        account: [{ required: true, trigger: 'blur', message: '请输入 账号' }],
        currency: [{ required: true, trigger: 'blur', message: '请输入 币别，参见字典表currency_type' }],
        ownerId: [{ required: true, trigger: 'blur', message: '请输入 所属方id' }],
        ownerType: [{ required: true, trigger: 'blur', message: '请输入 所属方类型，参见字典表owner_type' }],
        status: [{ required: true, trigger: 'blur', message: '请输入 状态' }]
      }
    }
  },
  created () {
    this.onLoad(this.page)
  },

  methods: {
    onLoad (page, params = {}) {
      this.listLoading = true
      getList(page.currentPage, page.pageSize, params).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
      })
    },
    handleFilter () {
      this.onLoad(this.page)
    },
    handleCreate () {
      this.showDialog = true
    },
    handleUpdate (id) {
      getDetail(id).then(res => {
        this.form = res.data
        this.showDialog = true
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '提示', {}).then(() => {
        remove(row.id).then(res => {
          if (res.success) {
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.onLoad(this.page)
          } else {
            this.$notify({
              title: '删除失败',
              message: res.data.message,
              type: 'error',
              duration: 2000
            })
          }
        })
      })
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保存
      this.$refs.form.validate(valid => {
        if (valid) {
          submit(this.form).then(res => {
            if (res.success) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.showDialog = false
              this.onLoad(this.page)
            } else {
              this.$notify({
                title: '操作失败',
                message: res.data.message,
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      })
    }
  }

}
</script>
