import { request } from '@/util/http'

export const startList = (current, size, params) => {
  return request({
    url: '/flow/work/start-list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const claimList = (current, size, params) => {
  return request({
    url: '/flow/work/claim-list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const todoList = (current, size, params) => {
  return request({
    url: '/flow/work/todo-list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const todoClaimList = (current, size, params) => {
  return request({
    url: '/flow/work/todo-claim-list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const sendList = (current, size, params) => {
  return request({
    url: '/flow/work/send-list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const doneList = (current, size, params) => {
  return request({
    url: '/flow/work/done-list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
export const searchDoneList = (current, size, params) => {
  return request({
    url: '/flow/work/searchDone-list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getExcelList = (params) => {
  return request({
    url: '/flow/work/getExcelList',
    method: 'get',
    params,
    responseType: 'blob'
  })
}

export const claimTask = (taskId) => {
  return request({
    url: '/flow/work/claim-task',
    method: 'post',
    params: {
      taskId
    }
  })
}

export const completeTask = (data) => {
  return request({
    url: '/flow/work/complete-task',
    method: 'post',
    data
  })
}
