<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.crm_contract_delete"
            @click="batchUnbind">批量解绑
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">绑定
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            @click.stop="unbind(row)">解绑
        </el-button>
      </template>
      <template slot="search">
        <el-form label-width="85px" align="left" :inline="true">
          <el-form-item label="所属商户:">
            <good-select v-model="search.ownerId" url="/crm/merchant/dict?marketId="></good-select>
          </el-form-item>
        </el-form>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select/index'
import { remove } from '@/api/common'

export default {
  mixins: [mixin],
  components: { GoodSelect },
  data () {
    return {
      module: 'crm/bankAccountParam',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      ownerId: null,
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        searchMenuSpan: 4,
        align: 'center',
        column: [
          {
            label: '所属商户',
            prop: 'ownerId',
            width: 200,
            type: 'select',
            dicUrl: '/crm/merchant/dict?marketId={{marketId}}'
          },
          {
            label: '分账规则',
            prop: 'accountRuleId',
            width: 200,
            type: 'select',
            dicUrl: '/crm/bankAccountAssign/getDict'
          },
          {
            label: '所属银行',
            prop: 'bankAccountId',
            width: 200,
            type: 'select',
            dicUrl: '/crm/bank/getDict?ownerType=2'
          },
          {
            label: '建行商家编号',
            prop: 'mktMrchId',
            width: 100
          },
          {
            label: '建行商家',
            prop: 'mktMrchNm',
            width: 150,
            search: true
          },
          {
            label: '商家柜台代码',
            prop: 'mrchCnterCd',
            width: 150
          },
          {
            label: '商家证件',
            prop: 'mrchCrdtTp',
            width: 100,
            type: 'select',
            dicUrl: '/sys/dict/code/mrch_crdt_tp'
          },
          {
            label: 'pos机编号',
            prop: 'posNo',
            width: 150
          },
          {
            label: '删除标记',
            prop: 'delIdr',
            width: 100
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.ownerId = this.$route.query.ownerId
    this.search.ownerId = this.$route.query.ownerId
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_bankAccountParam_add,
        viewBtn: this.permission.crm_bankAccountParam_view,
        delBtn: this.permission.crm_bankAccountParam_delete,
        editBtn: this.permission.crm_bankAccountParam_edit
      }
    }
  },
  methods: {
    toEdit (row) {
      this.$router.push({
        path: '/crm/bankAccountParamEdit',
        query: { id: row.id }
      })
    },
    unbind (row) {
      this.$confirm('确定将签约用户解绑?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          remove(this.module, row.id).then(res => {
            if (res.success) {
              this.onLoad(this.page, this.search)
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        })
    },
    batchUnbind () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择的签约用户解绑?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.module, this.ids).then(res => {
            if (res.success) {
              this.onLoad(this.page, this.search)
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.$refs.crud.toggleSelection()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
    }
  }
}
</script>
