import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/base/linkman/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getAuth = (params) => {
  return request({
    url: '/base/linkman/auth',
    method: 'get',
    params: {
      ...params
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/base/linkman/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/base/linkman/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/base/linkman/submit',
    method: 'post',
    data: row
  })
}

export const submitRelations = (linkmen) => {
  return request({
    url: '/base/linkman/submit/relations',
    method: 'post',
    data: linkmen
  })
}

export const recognize = (url, side, bucket) => {
  side = side.substr(0, 1).toUpperCase() + side.substr(1)
  const path = '/base/linkman/ocr' + side
  return request({
    url: path,
    method: 'post',
    data: { path: url, bucket: bucket }
  })
}

