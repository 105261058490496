<template>
  <el-radio-group :value="value" :disabled="disabled" placeholder="请选择" @input="change($event)">
    <el-radio-button
        v-for="item in options"
        :key="item.value"
        :label="item.value"
    >{{ item.label }}
    </el-radio-button>
  </el-radio-group>
</template>

<script>
import { getDicDataByUrl } from '@/api/sys/dict'

export default {
  name: 'GoodRadio',
  props: {
    url: {
      default: '',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    value: {
      default: undefined,
      type: Number || String
    }
  },
  data () {
    return {
      options: []
    }
  },
  created () {
    getDicDataByUrl(this.url).then(res => {
      this.options = res.data
    })
  },
  methods: {
    change: function (val) {
      this.$emit('input', val)
    }
  }
}
</script>
