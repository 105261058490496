<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="primary"
                icon="el-icon-plus"
                size="small"
                @click.stop="toAdd()"
        >新增
        </el-button>
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.erp_devicebrand_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
                type="text"
                icon="el-icon-del"
                size="small"
                @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { tree } from '@/api/erp/devicebrand'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'erp/devicebrand',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        column: [
          {
            label: '品牌名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入品牌名称'
            }]
          },
          {
            label: '所属分类',
            prop: 'typeId',
            type: 'tree',
            rules: [{
              required: true,
              message: '请选择设备分类'
            }],
            props: {
              label: 'name',
              value: 'id'
            },
            dicUrl: '/erp/devicetype/getDict'
          },
          {
            label: '标志图片',
            prop: 'icon',
            type: 'img'
          },
          {
            label: '说明',
            prop: 'description',
            rules: [{
              required: false,
              message: '请输入说明'
            }]
          },
          {
            label: '排序',
            prop: 'sort',
            rules: [{
              required: false,
              message: '请输入排序'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: false,
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.erp_devicebrand_add,
        viewBtn: this.permission.erp_devicebrand_view,
        delBtn: this.permission.erp_devicebrand_delete,
        editBtn: this.permission.erp_devicebrand_edit
      }
    }
  },
  methods: {
    onLoad (page, params = {}) {
      tree(page.currentPage, page.pageSize, params).then(res => {
        if (res.success) {
          this.data = res.data.records
          this.page.total = res.data.total
        } else {
          this.data = []
          this.page.total = 0
        }
      })
    },
    toAdd () {
      this.$router.push({
        path: '/erp/deviceBrandEdit'
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/erp/deviceBrandEdit',
        query: { id: row.id }
      })
    }
  }
}
</script>
