<template>
  <el-form
      :model="form"
      ref="enterprise"
      :inline-message="true"
      label-position="right"
      label-width="100px"
      class="small-space"
      :rules="rules">
    <el-row :gutter="20">
      <el-col :span="24">
        <span class="title"><i class="el-icon-s-flag"></i>基本信息</span>
        <el-divider></el-divider>
      </el-col>
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="营业执照：" required>
            <good-cropper
                v-model="form.creditUrl"
                :showFileList="false"
                :private="true"
                :bucket="'zhsn-scale-private'"
                prefix="enterprise"
                :initData="form.creditUrl"
                :uploadClass="'upload-pic'"
                @handleSuccess="handleRecognize"></good-cropper>
          </el-form-item>
        </el-col>
        <slot></slot>
      </el-col>
      <slot name="foreignKey"/>
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="简称：" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="全称：" prop="fullName">
            <el-input v-model="form.fullName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="注册号：" prop="creditCode">
            <el-input v-model="form.creditCode" ></el-input>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="法人姓名：" prop="legalPersonName">
            <el-input v-model="form.legalPersonName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="固定电话：" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="企业性质：" prop="type">
            <good-select v-model="form.type" :need-refresh="typeRefresh" url="sys/dict/code/enterprise_type"/>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="24">
        <el-col :span="8">
          <el-col :span="24">
            <el-form-item label="注册区域：" prop="areaId">
              <el-cascader v-model="form.areaId" :options="areas" :props="areaProps" :show-all-levels="false"/>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :span="8">
          <el-form-item label="注册地址：" prop="address">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="注册资本：" prop="registeredCapital">
            <el-input v-model="form.registeredCapital"></el-input>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="24">
        <slot name="manageArea"/>
        <slot name="manageAddress"/>
      </el-col>
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="成立时间：" prop="beginAt">
            <el-date-picker
                v-model="form.beginAt"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否长期：" prop="isLongTerm">
            <el-radio-group v-model="form.isLongTerm">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="有效期止：" prop="operatingTo">
            <el-date-picker
                v-model="form.operatingTo"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-col>
      <el-col :span="24">
        <el-col :span="8">
          <el-form-item label="注册状态：" prop="status">
            <good-select v-model="form.status" url="sys/dict/code/present_status"></good-select>
          </el-form-item>
        </el-col>
        <slot name="manageStatus"/>
        <el-col :span="8">
          <el-form-item label="排序：" prop="sort">
            <el-input-number :min="1" v-model="form.sort" :step="1"></el-input-number>
          </el-form-item>
        </el-col>
      </el-col>
      <slot name="moreProps"/>
      <el-col :span="24">
        <span class="title"><i class="el-icon-s-flag"></i>{{ picTitle }}：</span>
        <el-divider></el-divider>
      </el-col>
      <el-col :span="8">
        <el-form-item :label="picLabel + '：'" prop="otherLicenseUrl">
          <good-cropper
              v-model="annex.url"
              :showFileList="false"
              :bucket="'zhsn-scale'"
              :initData="annex.url"
              :uploadClass="'upload-pic'"></good-cropper>
        </el-form-item>
      </el-col>
      <el-col :span="4" v-for="annex in form.annexes" :key="annex.id">
        <good-cropper
            v-model="annex.url"
            :bucket="'zhsn-scale'"
            :initData="annex.url"
            :uploadClass="'upload-pic'"></good-cropper>
      </el-col>
      <el-col :span="24">
        <el-footer align="center">
          <el-button v-if="showSubmit" type="primary" @click="handleSubmit()" :loading="listLoading">提交</el-button>
          <slot @click="handleSubmit()" name="submitBtn"/>
        </el-footer>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { submit, getDetail, recognize } from '@/api/base/enterprise'
import { getTree } from '@/api/sys/area'
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select'
import GoodCropper from '@/components/good-cropper'

export default {
  name: 'EnterpriseEdit',
  mixins: [mixin],
  components: { GoodSelect, GoodCropper },
  props: {
    enterprise: {
      default: null,
      type: Object
    },
    picTitle: {
      default: '企业风采',
      type: String
    },
    picLabel: {
      default: '照片',
      type: String
    },
    showSubmit: {
      default: true,
      type: Boolean
    },
    needRecognize: {
      default: true,
      type: Boolean
    },
    companyId: {
      default: '',
      type: String
    },
    marketId: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      total: null,
      list: null,
      listLoading: false,
      showDialog: false,
      typeRefresh: false,
      form: {
        id: undefined,
        name: undefined,
        fullName: undefined,
        creditCode: undefined,
        legalPersonName: undefined,
        beginAt: undefined,
        isLongTerm: undefined,
        operatingTo: undefined,
        areaId: undefined,
        address: undefined,
        phone: undefined,
        type: undefined,
        registeredCapital: undefined,
        sort: undefined,
        status: undefined,
        creditAnnex: {},
        legalPerson: {},
        companyId: undefined,
        marketId: undefined
      },
      creditUrl: undefined,
      annex: {},
      areas: [],
      areaProps: {
        lazy: true,
        value: 'id',
        label: 'name',
        lazyLoad (node, resolve) {
          const { level } = node
          if (level === 0) {
            setTimeout(() => {
              getTree({ parentId: level }).then(res => {
                if (res.success) {
                  const nodes = res.data
                  // eslint-disable-next-line no-return-assign
                  nodes.forEach(item => item.leaf = level >= 2)
                  resolve(nodes)
                }
              })
            }, 1000)
          } else {
            setTimeout(() => {
              getTree({ parentId: node.data.codeNum }).then(res => {
                if (res.success) {
                  const nodes = res.data
                  // eslint-disable-next-line no-return-assign
                  nodes.forEach(item => item.leaf = level >= 2)
                  resolve(nodes)
                }
              })
            }, 1000)
          }
        }
      },
      marketIds: '',
      rules: {
        marketIds: [{ required: true, trigger: 'blur', message: '请选择所属市场' }],
        fullName: [{ required: true, trigger: 'blur', message: '请输入企业全称' }],
        creditCode: [{ required: true, trigger: 'blur', message: '请输入统一社会信用代码' }],
        legalPersonName: [{ required: true, trigger: 'blur', message: '请输入法定代表人姓名' }]
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  watch: {
    enterprise: {
      handler (newVal, oldVal) {
        this.form = newVal
      },
      deep: true,
      immediate: false
    },
    form: {
      handler (val) {
        this.$emit('set-enterprise', val)
      },
      deep: true
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.enterprise) {
      this.form = this.enterprise
      this.reset()
    } else if (this.form && this.form.id) {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      this.listLoading = true
      getDetail(this.form.id).then(res => {
        this.listLoading = false
        this.form = res.data
        this.reset()
      })
    },
    handleRecognize (imageUrl, bucket) {
      if (this.needRecognize) {
        this.creditUrl = imageUrl
        recognize(imageUrl, bucket).then((res) => {
          if (res.success) {
            this.form = res.data
            this.form.creditUrl = this.creditUrl
            this.typeRefresh = true
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
              duration: 2000
            })
          }
        }).catch((e) => {
          this.$message({
            type: 'error',
            message: e
          })
        })
      }
    },
    handleSubmit () {
      this.form.companyId = this.companyId
      // 用于rules校验（marketId为一串数字会导致校验不通过）
      this.marketIds = this.marketId + ''
      this.form.marketId = this.marketId
      // 保存
      return new Promise((resolve, reject) => {
        this.$refs.enterprise.validate(valid => {
          if (valid) {
            const areaId = this.form.areaId
            if (areaId instanceof Array) {
              this.form.areaId = areaId.pop()
            }
            const legalPerson = this.form.legalPerson
            delete this.form.legalPerson
            submit(this.form).then(res => {
              if (res.success) {
                this.showDialog = false
                this.form.id = res.data
                this.form.areaId = areaId
                this.form.legalPerson = legalPerson
                this.$emit('set-enterprise', this.form)
                resolve(true)
              } else {
                this.$notify({
                  title: '操作失败',
                  message: res.data.msg,
                  type: 'error',
                  duration: 2000
                })
                return false
              }
            })
          } else {
            reject(new Error())
          }
        })
      })
    },
    reset () {
      if (!this.form) {
        this.form = {}
      }
    },
    // 滚动条定位到第一个校验失败的div的位置
    locateToErr () {
      setTimeout(() => {
        const errorDiv = document.getElementsByClassName('is-error')
        errorDiv[0].scrollIntoView()
      }, 0)
    }
  }

}
</script>

<style lang="scss" scoped>
.title {
  i {
    margin-right: 15px;
  }

  font-size: 24px;
}
</style>
