<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.mall_goodspricechangelog_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/goodspricechangelog',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '商品id',
            prop: 'goodsId',
            rules: [{
              required: true,
              message: '请输入商品id'
            }]
          },
          {
            label: '商品sku.id',
            prop: 'skuId',
            rules: [{
              required: true,
              message: '请输入商品sku.id'
            }]
          },
          {
            label: '新零售价',
            prop: 'newRetailPrice',
            rules: [{
              required: true,
              message: '请输入新零售价'
            }]
          },
          {
            label: '旧零售价',
            prop: 'oldRetailPrice',
            rules: [{
              required: true,
              message: '请输入旧零售价'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_goodspricechangelog_add,
        viewBtn: this.permission.mall_goodspricechangelog_view,
        delBtn: this.permission.mall_goodspricechangelog_delete,
        editBtn: this.permission.mall_goodspricechangelog_edit
      }
    }
  },
  methods: {
  }
}
</script>
