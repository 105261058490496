<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        :row-class-name="setAlarm"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            icon="el-icon-plus"
            size="small"
            @click.stop="toAdd()"
        >新增
        </el-button>
        <el-button
            type="primary"
            icon="el-icon-view"
            size="small"
            @click.stop="handleZipDownload"
            v-if="permission.crm_contract_view"
        >合同下载
        </el-button>
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.crm_contract_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
      <template slot="search" v-if="!contracts">
        <el-form label-width="85px" align="left" :inline="true">
          <el-row :span="24">
            <el-col :span="24">
              <el-form-item label="关键字:">
                <el-input v-model="search.query" placeholder="名称/甲方/乙方/市场..." />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="甲方类型:">
                <good-select v-model="search.ownerType" url="/sys/dict/code/owner_type"></good-select>
              </el-form-item>
              <el-form-item label="甲方:">
                <good-select v-model="search.ownerId" url="/crm/merchant/dict?marketId="></good-select>
              </el-form-item>
              <el-form-item label="类型:">
                <good-select v-model="search.type" url="/sys/dict/code/contract_type"></good-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
            <el-form-item label="乙方类型:">
              <good-select v-model="search.oppositeType" url="/sys/dict/code/owner_type"></good-select>
            </el-form-item>
            <el-form-item label="乙方:">
              <good-select v-model="search.oppositeId" url="/crm/merchant/dict?marketId="></good-select>
            </el-form-item>
              <el-form-item label="有效状态:">
                <good-select v-model="search.status" url="/sys/dict/code/contract_status"></good-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select'
import FileSaver from 'file-saver'
import { getList, zipDownload } from '@/api/base/contract'

export default {
  name: 'Contract',
  mixins: [mixin],
  components: { GoodSelect },
  props: {
    ownerType: {
      default: 'MERCHANT',
      type: String
    },
    ownerId: {
      default: '',
      type: String
    },
    deleted: {
      default: false,
      type: Boolean
    },
    contracts: {
      default: null,
      type: Array
    }
  },
  data () {
    return {
      module: 'base/contract',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        align: 'center',
        column: [
          {
            label: '甲方',
            prop: 'ownerName',
            width: 200
          },
          {
            label: '乙方',
            prop: 'oppositeName',
            width: 200
          },
          {
            label: '标的物',
            prop: 'objectName'
          },
          {
            label: '名称',
            prop: 'name',
            width: 150
          },
          {
            label: '编号',
            prop: 'sn',
            width: 150
          },
          {
            label: '合同类型',
            prop: 'type',
            type: 'select',
            dicUrl: '/sys/dict/code/contract_type',
            width: 100
          },
          {
            label: '起始日期',
            prop: 'validFrom',
            type: 'date',
            width: 100
          },
          {
            label: '截止日期',
            prop: 'validTo',
            type: 'date',
            width: 100
          },
          {
            label: '合同到期天数',
            prop: 'expiredDays',
            width: 120
          },
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            dicUrl: '/sys/dict/code/yes_no',
            width: 100
          },
          {
            label: '金额',
            prop: 'rent',
            type: 'number',
            width: 100
          },
          {
            label: '支付方式',
            prop: 'payType',
            type: 'select',
            dicUrl: '/sys/dict/code/pay_type',
            width: 100,
            rules: [{
              required: true,
              message: '请输入支付方式'
            }]
          },
          {
            label: '支付周期',
            prop: 'payPeriod',
            type: 'select',
            dicUrl: '/sys/dict/code/pay_period_type',
            width: 100,
            rules: [{
              required: true,
              message: '请输入支付周期'
            }]
          },
          {
            label: '备注信息',
            prop: 'remark',
            width: 150,
            rules: [{
              message: '请输入备注信息'
            }]
          }
        ]
      },
      data: []
    }
  },
  created () {
    if (this.ownerType) {
      this.form.ownerType = this.ownerType
    } else if (this.$route.query.ownerType) {
      this.form.ownerType = this.$route.query.ownerType
    }
    if (this.ownerId) {
      this.form.ownerId = this.ownerId
    } else if (this.$route.query.ownerId) {
      this.form.ownerId = this.$route.query.ownerId
    }
    this.onLoad({ ownerType: this.ownerType, ownerId: this.ownerId })
  },
  computed: {
    permissionList () {
      return {
        viewBtn: this.permission.crm_contract_view,
        editBtn: this.permission.crm_contract_edit
      }
    }
  },
  methods: {
    onLoad (param) {
      if (this.contracts) {
        this.data = this.contracts
      } else {
        getList(this.page.currentPage, this.page.pageSize, { param, ...this.search }).then(res => {
          const data = res.data
          this.page.total = data.total
          this.data = data.records
        })
      }
    },
    toAdd () {
      this.$router.push({
        path: '/crm/contractEdit',
        query: {
          ownerType: this.form.ownerType,
          ownerId: this.form.ownerId
        }
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/crm/contractEdit',
        query: { id: row.id }
      })
    },
    handleZipDownload () {
      if (this.ids.length) {
        zipDownload(this.ids).then(res => {
          FileSaver.saveAs(res, '合同附件.zip')
          this.$message({
            type: 'success',
            message: '下载成功!'
          })
        })
      } else {
        this.$confirm('未选择需要导出的合同，确定导出所有合同？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          zipDownload(this.ids).then((res) => {
            FileSaver.saveAs(res, '合同附件.zip')
            this.$message({
              type: 'success',
              message: '下载成功!'
            })
          })
        })
      }
    },
    setAlarm (row) {
      if (row.row.alarmLevel === 1) {
        return 'alarm'
      } else {
        return 'normal'
      }
    }
  }
}
</script>
<style>
tr.alarm {
  background-color: #fcf0f0;
  color: #e6807d;
}

tr.normal {
  background-color: #599ef8;
  color: white;
}

.el-table__body tr.hover-row {
  color: black;
}
</style>
