<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <div class="start">
          <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              @click.stop="toAdd()"
          >新增
          </el-button>
          <el-button type="success" @click.stop="handleBoothExport()">导出</el-button>
          <el-button type="primary" @click.stop="handleBoothModel()">模板下载</el-button>
          <el-upload
              action="#"
              :auto-upload="false"
              :show-file-list="false"
              :on-change='handleChange'>
            <el-button size="small" type="primary">导入</el-button>
          </el-upload>
        </div>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toSign(row)">签约
        </el-button>
        <el-button
            type="text"
            icon="el-icon-del"
            size="small"
            @click.stop="$refs.crud.rowDel(row,index)">删除
        </el-button>
      </template>
      <template slot="search">
        <el-form :inline="true">
          <el-form-item label="关键字:">
            <el-input placeholder="编号、名称等" v-model="search.query"/>
          </el-form-item>
          <el-form-item label="楼宇:">
            <good-select v-model="search.buildingId" url="/base/building/dict"/>
          </el-form-item>
          <el-form-item label="状态:">
            <good-select v-model="search.leaseStatus" url="/sys/dict/code/lease_status"/>
          </el-form-item>
        </el-form>
      </template>
    </avue-crud>
    <el-dialog :visible.sync="contractVisible" :fullscreen="true">
      <contract-edit :disabled="true" :show-btn="true" :back="false" :contract="contractForm" @submit="handleUpdate" @cancel="closeContract">
        <template v-slot:foreignName>
          <el-form-item label="摊位名称:">
            <el-input placeholder="请设置摊位名称" v-model="form.boothName"/>
          </el-form-item>
        </template>
      </contract-edit>
    </el-dialog>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import FileSaver from 'file-saver'
import GoodSelect from '@/components/good-select/index'
import ContractEdit from '../base/contractEdit'
import { exportExcel, importExcel, modelExcel } from '@/api/common'
import { formatDate } from '@/util/date'
import { getDetail as getMarket } from '@/api/crm/market'
import { getList as listMerchants } from '@/api/crm/merchant'
import { getOne as getContract } from '@/api/base/contract'
import { submit as sign } from '@/api/crm/merchantbooth'

export default {
  mixins: [mixin],
  components: { GoodSelect, ContractEdit },
  data () {
    return {
      module: 'base/booth',
      form: {},
      contractForm: {},
      contractVisible: false,
      companyId: null,
      marketId: null,
      booth: null,
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        viewBtn: false,
        // menuWidth: 100,
        selection: true,
        align: 'center',
        column: [
          {
            label: '楼宇',
            prop: 'buildingId',
            type: 'select',
            dicUrl: '/base/building/dict'
          },
          {
            label: '楼层',
            prop: 'floorId',
            type: 'select',
            dicUrl: '/base/floor/dict'
          },
          {
            label: '区域',
            prop: 'regionId',
            type: 'select',
            dicUrl: '/base/region/dict'
          },
          {
            label: '公司',
            prop: 'companyId',
            type: 'select',
            dicUrl: '/crm/company/dict'
          },
          {
            label: '市场',
            prop: 'marketId',
            type: 'select',
            dicUrl: '/crm/market/dict'
          },
          {
            label: '摊位编号',
            prop: 'sn'
          },
          {
            label: '摊位名称',
            prop: 'name'
          },
          {
            label: '楼宇状态',
            prop: 'buildingStatus',
            type: 'select',
            dicUrl: '/sys/dict/code/building_status'
          },
          {
            label: '租赁状态',
            prop: 'leaseStatus',
            type: 'select',
            dicUrl: '/sys/dict/code/lease_status'
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.form.companyId = this.userInfo.companyId
    this.form.marketId = this.userInfo.marketId
    this.getMarket()
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.base_booth_add,
        viewBtn: this.permission.base_booth_view,
        delBtn: this.permission.base_booth_delete,
        editBtn: this.permission.base_booth_edit
      }
    }
  },
  methods: {
    getMarket () {
      if (!this.form.marketId) {
        return
      }
      if (this.form.companyId) {
        return
      }
      getMarket(this.form.marketId).then(res => {
        if (res.success && res.data) {
          this.form.companyId = res.data.companyId
        }
      })
    },
    toAdd () {
      this.$router.push({
        path: '/base/boothEdit'
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/base/boothEdit',
        query: { id: row.id }
      })
    },
    toSign (row) {
      listMerchants().then(res => {
        if (res.success) {
          if (!res.total) {
            this.$message({
              type: 'warning',
              message: '暂无商户可以签约，请先创建商户'
            })
          } else {
            this.booth = row
            const data = {
              objectId: row.id,
              type: 1,
              objectType: 'BOOTH',
              ownerType: 'MARKET',
              ownerId: this.form.marketId,
              oppositeType: 'MERCHANT'
            }
            getContract(data).then(res => {
              if (res.success && res.data) {
                this.contractForm = res.data
              } else {
                data.objectType = 101
                data.ownerType = 4
                data.oppositeType = 5
                this.contractForm = data
              }
              this.contractVisible = true
            })
          }
        }
      })
    },
    handleSign () {
      this.$refs.contract.handleSubmit()
    },
    handleUpdate (contractForm) {
      console.log(contractForm.boothName)
      this.contractVisible = false
      this.form.companyId = contractForm.companyId
      this.form.marketId = contractForm.ownerId
      this.form.merchantId = contractForm.oppositeId
      this.form.boothId = this.booth.id
      this.form.sn = this.booth.sn
      this.form.contractId = contractForm.id
      sign(this.form).then(res => {
        if (res.success) {
          this.onLoad(this.page)
        }
      })
    },
    closeContract () {
      this.contractVisible = false
    },
    handleBoothExport () {
      exportExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '摊位信息' + formatDate(new Date(), 'yyyyMMddhhmmss') + '.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(err => {
        this.$message({
          type: 'error',
          message: err.msg
        })
      })
    },
    handleBoothModel () {
      modelExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '摊位信息模板.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    },
    handleChange (file) {
      importExcel('base/booth', file).then(res => {
        if (res.size) {
          FileSaver.saveAs(res, '导入失败摊位数据.xlsx')
        } else {
          this.$message({
            type: 'success',
            message: '导入完成!'
          })
          this.onLoad(this.page)
          this.$refs.crud.updateDic('buildingId')
          this.$refs.crud.updateDic('floorId')
          this.$refs.crud.updateDic('regionId')
        }
      })
    }
  }
}
</script>
