<template>
  <basic-container>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'report/salestatistics',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '统计记录',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入统计记录'
            }]
          },
          {
            label: '统计期间',
            prop: 'cycle',
            rules: [{
              required: true,
              message: '请输入统计期间'
            }]
          },
          {
            label: '统计周期类型，参见字典表cycle_type',
            prop: 'cycleType',
            rules: [{
              required: true,
              message: '请输入统计周期类型，参见字典表cycle_type'
            }]
          },
          {
            label: '统计报表类型，参见字典表report_type',
            prop: 'reportType',
            rules: [{
              required: true,
              message: '请输入统计报表类型，参见字典表report_type'
            }]
          },
          {
            label: '统计依据',
            prop: 'basis',
            rules: [{
              required: true,
              message: '请输入统计依据'
            }]
          },
          {
            label: '统计数量',
            prop: 'amount',
            rules: [{
              required: true,
              message: '请输入统计数量'
            }]
          },
          {
            label: '占比',
            prop: 'percent',
            rules: [{
              required: true,
              message: '请输入占比'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.report_salestatistics_add,
        viewBtn: this.permission.report_salestatistics_view,
        delBtn: this.permission.report_salestatistics_delete,
        editBtn: this.permission.report_salestatistics_edit
      }
    }
  },
  methods: {}
}
</script>
