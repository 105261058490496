import { get, request } from '@/util/http'

export const policy = (bucket, prefix) => get('/oss/policy', { bucket, prefix })

export const getLinkPrivate = (path, bucket) => {
  return request({
    url: '/oss/signed',
    method: 'post',
    data: {
      path,
      bucket
    }
  })
}
