<template>
  <div>
    <el-card class="good-card">
      <div>
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            :inline-message="true"
            label-position="right"
            label-width="150px"
            class="small-space form-one">
          <el-form-item label="秤类型：" prop="scaleType">
            <good-select v-model="form.scaleType" url="/sys/dict/code/scale_type"></good-select>
          </el-form-item>
          <el-form-item label="是否自动累计：" prop="cumulativeFlag">
            <good-select v-model="form.cumulativeFlag" url="/sys/dict/code/cumulative_flag"></good-select>
          </el-form-item>
          <el-form-item label="是否锁屏：" prop="lockFlag">
            <good-select v-model="form.lockFlag" url="/sys/dict/code/lock_flag"></good-select>
          </el-form-item>
          <el-form-item label="是否ai识别：" prop="aiFlag">
            <good-select v-model="form.aiFlag" url="/sys/dict/code/ai_flag"></good-select>
          </el-form-item>
          <el-form-item label="是否显示ai按钮：" prop="showAiUpload">
            <good-select v-model="form.showAiUpload" url="/sys/dict/code/show_ai_upload"></good-select>
          </el-form-item>
          <el-form-item label="是否拍照版本：" prop="showCameraFlag">
            <good-select v-model="form.showCameraFlag" url="/sys/dict/code/show_camera_flag"></good-select>
          </el-form-item>
          <el-form-item label="订单抹零方式：" prop="resetOrderType">
            <good-select v-model="form.resetOrderType" url="/sys/dict/code/reset_order_type"></good-select>
          </el-form-item>
          <el-form-item label="单品抹零方式：" prop="singleResetType">
            <good-select :disabled="singleUnitDisable" v-model="form.singleResetType" url="/sys/dict/code/single_reset_type"></good-select>
          </el-form-item>
          <el-form-item label="单品抹零单位：" prop="singleResetUnit">
            <good-select :disabled="singleUnitDisable" v-model="form.singleResetUnit" url="/sys/dict/code/single_reset_unit"></good-select>
          </el-form-item>
          <el-form-item label="是否自动抹零：" prop="resetFlag">
            <good-select :disabled="unitDisable" v-model="form.resetFlag" url="/sys/dict/code/reset_flag"></good-select>
          </el-form-item>
          <el-form-item label="抹零方式：" prop="resetType">
            <good-select :disabled="unitDisable" v-model="form.resetType" url="/sys/dict/code/reset_type"></good-select>
          </el-form-item>
          <el-form-item label="抹零单位：" prop="resetUnit">
            <good-select :disabled="unitDisable" v-model="form.resetUnit" url="/sys/dict/code/reset_unit"></good-select>
          </el-form-item>
          <el-form-item label="是否显示价格：" prop="showPrice">
            <good-select v-model="form.showPrice" url="/sys/dict/code/show_price"></good-select>
          </el-form-item>
          <el-form-item label="是否语音播报：" prop="voice">
            <good-select v-model="form.voice" url="/sys/dict/code/voice"></good-select>
          </el-form-item>
          <el-form-item label="备注信息：" prop="remark">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="save(true)" :loading="loading.addAg">保存并提交</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select/index'
import { getDetail, submit } from '@/api/crm/devicesn'

export default {
  mixins: [mixin],
  components: { GoodSelect },
  data () {
    return {
      loading: {
        form: false
      },
      form: {
        scaleType: 1,
        cumulativeFlag: 0,
        resetOrderType: 0,
        resetFlag: 1,
        resetType: 1,
        resetUnit: 2,
        singleResetType: 1,
        singleResetUnit: 2,
        showPrice: 0,
        voice: 1,
        lockFlag: 0,
        aiFlag: 0,
        showAiUpload: 0,
        showCameraFlag: 0,
        remark: undefined,
        status: undefined
      },
      rules: {
        scaleType: [{ required: true, trigger: 'blur', message: '请选择秤类型' }],
        cumulativeFlag: [{ required: true, trigger: 'blur', message: '请选择是否自动累计' }],
        resetOrderType: [{ required: true, trigger: 'blur', message: '请选择订单抹零方式' }],
        showPrice: [{ required: true, trigger: 'blur', message: '请选择是否显示价格' }],
        voice: [{ required: true, trigger: 'blur', message: '请选择是否语音播报' }],
        lockFlag: [{ required: true, trigger: 'blur', message: '请选择是否锁屏' }],
        aiFlag: [{ required: true, trigger: 'blur', message: '请选择是否ai识别' }],
        showAiUpload: [{ required: true, trigger: 'blur', message: '请选择是否显示ai开关' }],
        showCameraFlag: [{ required: true, trigger: 'blur', message: '请选择是否拍照版本' }],
        remark: [{ required: false, trigger: 'blur', message: '请填写备注信息' }]
      },
      unitDisable: true,
      singleUnitDisable: true
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
    if (this.form.resetOrderType === 0) {
      this.unitDisable = true
      this.singleUnitDisable = false
    } else {
      this.unitDisable = false
      this.singleUnitDisable = true
    }
  },
  watch: {
    'form.resetOrderType' (val) {
      if (val === 0) {
        this.unitDisable = true
        this.singleUnitDisable = false
      } else {
        this.unitDisable = false
        this.singleUnitDisable = true
      }
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.crm_devicesn_add,
        viewBtn: this.permission.crm_devicesn_view,
        delBtn: this.permission.crm_devicesn_delete,
        editBtn: this.permission.crm_devicesn_edit
      }
    }
  },
  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          submit(this.form).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            } else {
              this.$message({
                type: 'false',
                message: res.msg
              })
            }
            this.loading.add = false
            if (res.code === 200) {
              this.form = {
                status: 1
              }
              this.$router.push({ path: '/crm/devicesn' })
            }
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/crm/devicesn' })
    }
  }
}
</script>
