<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.iot_iotbasesync_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'iot/iotbasesync',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '底库id',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入底库id'
            }]
          },
          {
            label: '品名',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入品名'
            }]
          },
          {
            label: 'skuId',
            prop: 'skuId',
            rules: [{
              required: true,
              message: '请输入skuId'
            }]
          },
          {
            label: '所属底库',
            prop: 'baseId',
            rules: [{
              required: true,
              message: '请输入所属底库'
            }]
          },
          {
            label: '新增图片地址',
            prop: 'images',
            rules: [{
              required: true,
              message: '请输入新增图片地址'
            }]
          },
          {
            label: '新增图片数量',
            prop: 'imageSize',
            rules: [{
              required: true,
              message: '请输入新增图片数量'
            }]
          },
          {
            label: '0：新增 1：更新',
            prop: 'skuStatus',
            rules: [{
              required: true,
              message: '请输入0：新增 1：更新'
            }]
          },
          {
            label: '错误信息',
            prop: 'msg',
            rules: [{
              required: true,
              message: '请输入错误信息'
            }]
          },
          {
            label: '1：成功 2： 失败',
            prop: 'suc',
            rules: [{
              required: true,
              message: '请输入1：成功 2： 失败'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.iot_iotbasesync_add,
        viewBtn: this.permission.iot_iotbasesync_view,
        delBtn: this.permission.iot_iotbasesync_delete,
        editBtn: this.permission.iot_iotbasesync_edit
      }
    }
  },
  methods: {
  }
}
</script>
