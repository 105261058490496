<template>
  <basic-container>
    <el-collapse-transition>
      <el-form :inline="true" class="search-form">
        <el-form-item>
          <el-input
              @keyup.enter.native="handleFilter"
              class="filter-item"
              placeholder="类型"
              v-model="page.type"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item search" type="primary" icon="el-icon-search" @click="handleFilter">搜索
          </el-button>
        </el-form-item>
      </el-form>
    </el-collapse-transition>
    <el-row class="padding-row">
      <el-button type="primary" icon="el-icon-add" @click="handleCreate">新增</el-button>
    </el-row>
    <el-table
        class="good-table"
        :data="list"
        v-loading.body="listLoading"
        element-loading-text="拼命加载中"
        border
        fit
        highlight-current-row>
      <el-table-column label="" prop="id">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单id" prop="orderId">
        <template slot-scope="scope">
          <span>{{ scope.row.orderId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单编号" prop="orderSn">
        <template slot-scope="scope">
          <span>{{ scope.row.orderSn }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商家ID" prop="userId">
        <template slot-scope="scope">
          <span>{{ scope.row.userId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="数量" prop="quantity">
        <template slot-scope="scope">
          <span>{{ scope.row.quantity }}</span>
        </template>
      </el-table-column>
      <el-table-column label="重量" prop="weight">
        <template slot-scope="scope">
          <span>{{ scope.row.weight }}</span>
        </template>
      </el-table-column>
      <el-table-column label="价格" prop="price">
        <template slot-scope="scope">
          <span>{{ scope.row.price }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品" prop="goodsId">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="销售类型" prop="goodsSellType">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsSellType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="时价商品" prop="goodsCurrentPrice">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsCurrentPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="sku" prop="goodsSkuId">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsSkuId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品主图" prop="goodsPic">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsPic }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品名称" prop="goodsName">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品sku条码" prop="goodsSkuCode">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsSkuCode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品分类" prop="goodsTypeId">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsTypeId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品品牌" prop="goodsBrandId">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsBrandId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品sn" prop="goodsSn">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsSn }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="handleUpdate(scope.row.id)">编辑</el-button>
          <el-button
v-if="scope.row.status!='deleted'"
size="small"
type="danger"
                     @click="handleDelete(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="!listLoading" class="end pagination-container">
      <el-pagination
:current-page.sync="page.currentPage"
background
                     @current-change="handleCurrentChange"
                     @size-change="handleSizeChange"
                     :page-sizes="[10,20,30, 50]"
                     :page-size="page.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
:total="total">
      </el-pagination>
    </div>
    <el-dialog title="订单中所包含的商品" :visible.sync="showDialog">
      <el-row :gutter="20">
        <el-form
:model="form"
ref="form"
:rules="rules"
label-position="left"
                 label-width="120px"
class="small-space"
style='padding:20px 60px;'>
          <el-col :span="12">
            <el-form-item label="：" prop="id">
              <el-input v-model="form.id" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订单id：" prop="orderId">
              <el-input v-model="form.orderId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订单编号：" prop="orderSn">
              <el-input v-model="form.orderSn"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商家ID：" prop="userId">
              <el-input v-model="form.userId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量：" prop="quantity">
              <el-input v-model="form.quantity"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="重量：" prop="weight">
              <el-input v-model="form.weight"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="价格：" prop="price">
              <el-input v-model="form.price"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品：" prop="goodsId">
              <el-input v-model="form.goodsId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="销售类型：" prop="goodsSellType">
              <el-input v-model="form.goodsSellType"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="时价商品：" prop="goodsCurrentPrice">
              <el-input v-model="form.goodsCurrentPrice"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="sku：" prop="goodsSkuId">
              <el-input v-model="form.goodsSkuId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品主图：" prop="goodsPic">
              <el-input v-model="form.goodsPic"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品名称：" prop="goodsName">
              <el-input v-model="form.goodsName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品sku条码：" prop="goodsSkuCode">
              <el-input v-model="form.goodsSkuCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品分类：" prop="goodsTypeId">
              <el-input v-model="form.goodsTypeId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品品牌：" prop="goodsBrandId">
              <el-input v-model="form.goodsBrandId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品sn：" prop="goodsSn">
              <el-input v-model="form.goodsSn"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import { getDetail, getList, remove, submit } from '@/api/mall/orderitem'

export default {
  data () {
    return {
      total: null,
      list: null,
      listLoading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      showDialog: false,
      form: {
        id: undefined,
        orderId: undefined,
        orderSn: undefined,
        userId: undefined,
        quantity: undefined,
        weight: undefined,
        price: undefined,
        goodsId: undefined,
        goodsSellType: undefined,
        goodsCurrentPrice: undefined,
        goodsSkuId: undefined,
        goodsPic: undefined,
        goodsName: undefined,
        goodsSkuCode: undefined,
        goodsTypeId: undefined,
        goodsBrandId: undefined,
        goodsSn: undefined
      },
      rules: {
        orderId: [{ required: true, trigger: 'blur', message: '请输入 订单id' }],
        orderSn: [{ required: true, trigger: 'blur', message: '请输入 订单编号' }],
        userId: [{ required: true, trigger: 'blur', message: '请输入 商家ID' }],
        quantity: [{ required: true, trigger: 'blur', message: '请输入 数量' }],
        weight: [{ required: true, trigger: 'blur', message: '请输入 重量' }],
        price: [{ required: true, trigger: 'blur', message: '请输入 价格' }],
        goodsId: [{ required: true, trigger: 'blur', message: '请输入 商品' }],
        goodsSellType: [{ required: true, trigger: 'blur', message: '请输入 销售类型' }],
        goodsCurrentPrice: [{ required: true, trigger: 'blur', message: '请输入 时价商品' }],
        goodsSkuId: [{ required: true, trigger: 'blur', message: '请输入 sku' }],
        goodsPic: [{ required: true, trigger: 'blur', message: '请输入 商品主图' }],
        goodsName: [{ required: true, trigger: 'blur', message: '请输入 商品名称' }],
        goodsSkuCode: [{ required: true, trigger: 'blur', message: '请输入 商品sku条码' }],
        goodsTypeId: [{ required: true, trigger: 'blur', message: '请输入 商品分类' }],
        goodsBrandId: [{ required: true, trigger: 'blur', message: '请输入 商品品牌' }],
        goodsSn: [{ required: true, trigger: 'blur', message: '请输入 商品sn' }]
      }
    }
  },
  created () {
    this.onLoad(this.page)
  },

  methods: {
    onLoad (page, params = {}) {
      this.listLoading = true
      getList(page.currentPage, page.pageSize, params).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
      })
    },
    handleFilter () {
      this.onLoad(this.page)
    },
    handleCreate () {
      this.showDialog = true
    },
    handleUpdate (id) {
      getDetail(id).then(res => {
        this.form = res.data
        this.showDialog = true
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '提示', {}).then(() => {
        remove(row.id).then(res => {
          if (res.success) {
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.onLoad(this.page)
          } else {
            this.$notify({
              title: '删除失败',
              message: res.data.message,
              type: 'error',
              duration: 2000
            })
          }
        })
      })
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保存
      this.$refs.form.validate(valid => {
        if (valid) {
          submit(this.form).then(res => {
            if (res.success) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.showDialog = false
              this.onLoad(this.page)
            } else {
              this.$notify({
                title: '操作失败',
                message: res.data.message,
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      })
    }
  }

}
</script>
