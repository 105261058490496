<template>
  <div>
    <el-card class="good-card">
      <div>
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            :inline-message="true"
            label-position="right"
            label-width="150px"
            class="small-space form-one">
          <el-form-item label="市场类型：" prop="marketId">
            <good-select v-model="form.marketId" url="/crm/market/dict?type=1"></good-select>
          </el-form-item>
          <el-form-item label="批次：" prop="stockId">
            <good-select v-model="form.stockId" url="/erp/stockin/getDict"></good-select>
          </el-form-item>
          <el-form-item label="设备编号：" prop="code">
            <el-input v-model="form.code"></el-input>
          </el-form-item>
          <el-form-item label="设备类型：" prop="type">
            <good-select v-model="form.type" url="/sys/dict/code/device_type"></good-select>
          </el-form-item>
          <el-form-item label="在库状态：" prop="stockStatus">
            <good-select v-model="form.stockStatus" url="/sys/dict/code/stock_status"></good-select>
          </el-form-item>
          <el-form-item label="绑定状态：" prop="bindStatus">
            <good-select v-model="form.bindStatus" url="/sys/dict/code/yes_no"></good-select>
          </el-form-item>
          <el-form-item label="备注信息：" prop="remark">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="save(true)" :loading="loading.addAg">保存并提交</el-button>
            <el-button @click="cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select/index'
import { getDetail, submit } from '@/api/erp/attachment'

export default {
  mixins: [mixin],
  components: { GoodSelect },
  data () {
    return {
      loading: {
        form: false
      },
      form: {
        marketId: undefined,
        stockId: undefined,
        name: undefined,
        code: undefined,
        type: 1,
        stockStatus: 1,
        bindStatus: 0,
        remark: undefined
      },
      rules: {
        marketId: [{ required: true, trigger: 'blur', message: '请选择市场' }],
        stockId: [{ required: true, trigger: 'blur', message: '请选择库存批次号' }],
        name: [{ required: false, trigger: 'blur', message: '请填写设备名称' }],
        code: [{ required: false, trigger: 'blur', message: '请填写设备编号' }],
        type: [{ required: true, trigger: 'blur', message: '请选择设备类型' }],
        stockStatus: [{ required: true, trigger: 'blur', message: '请选择在库状态' }],
        bindStatus: [{ required: true, trigger: 'blur', message: '请选择绑定状态' }],
        remark: [{ required: false, trigger: 'blur', message: '请填写备注信息' }]
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  watch: {
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.erp_device_add,
        viewBtn: this.permission.erp_device_view,
        delBtn: this.permission.erp_device_delete,
        editBtn: this.permission.erp_device_edit
      }
    }
  },
  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          submit(this.form).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
            } else {
              this.$message({
                type: 'false',
                message: res.msg
              })
            }
            this.loading.add = false
            if (res.code === 200) {
              this.form = {
                status: 1
              }
              this.$router.push({ path: '/erp/device' })
            }
          }).catch(() => {
          })
        }
      })
    },
    cancel () {
      this.$router.push({ path: '/erp/device' })
    }
  }
}
</script>
