<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.mall_carthistoryitem_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'mall/carthistoryitem',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '商家ID',
            prop: 'userId',
            rules: [{
              required: true,
              message: '请输入商家ID'
            }]
          },
          {
            label: '所属历史清单',
            prop: 'historyId',
            rules: [{
              required: true,
              message: '请输入所属历史清单'
            }]
          },
          {
            label: '数量',
            prop: 'quantity',
            rules: [{
              required: true,
              message: '请输入数量'
            }]
          },
          {
            label: '重量',
            prop: 'weight',
            rules: [{
              required: true,
              message: '请输入重量'
            }]
          },
          {
            label: '价格',
            prop: 'price',
            rules: [{
              required: true,
              message: '请输入价格'
            }]
          },
          {
            label: '商品',
            prop: 'goodsId',
            rules: [{
              required: true,
              message: '请输入商品'
            }]
          },
          {
            label: '销售方式',
            prop: 'goodsSellType',
            rules: [{
              required: true,
              message: '请输入销售方式'
            }]
          },
          {
            label: '时价商品',
            prop: 'goodsCurrentPrice',
            rules: [{
              required: true,
              message: '请输入时价商品'
            }]
          },
          {
            label: 'sku',
            prop: 'goodsSkuId',
            rules: [{
              required: true,
              message: '请输入sku'
            }]
          },
          {
            label: '商品主图',
            prop: 'goodsPic',
            rules: [{
              required: true,
              message: '请输入商品主图'
            }]
          },
          {
            label: '商品名称',
            prop: 'goodsName',
            rules: [{
              required: true,
              message: '请输入商品名称'
            }]
          },
          {
            label: '商品sku条码',
            prop: 'goodsSkuCode',
            rules: [{
              required: true,
              message: '请输入商品sku条码'
            }]
          },
          {
            label: '商品分类',
            prop: 'goodsTypeId',
            rules: [{
              required: true,
              message: '请输入商品分类'
            }]
          },
          {
            label: '商品品牌',
            prop: 'goodsBrandId',
            rules: [{
              required: true,
              message: '请输入商品品牌'
            }]
          },
          {
            label: '商品sn',
            prop: 'goodsSn',
            rules: [{
              required: true,
              message: '请输入商品sn'
            }]
          },
          {
            label: '修改时间',
            prop: 'modifyDate',
            rules: [{
              required: true,
              message: '请输入修改时间'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.mall_carthistoryitem_add,
        viewBtn: this.permission.mall_carthistoryitem_view,
        delBtn: this.permission.mall_carthistoryitem_delete,
        editBtn: this.permission.mall_carthistoryitem_edit
      }
    }
  },
  methods: {
  }
}
</script>
